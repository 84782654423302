import React, { useState, useEffect } from "react"
import { useNotify, SelectInput, FormWithRedirect, useTranslate } from "react-admin"
import { useSelector } from "react-redux"
import { observer, inject } from "mobx-react"
import Button from "@material-ui/core/Button"
import LibraryAddIcon from "@material-ui/icons/LibraryAdd"
import OrgFormFactory from "./orgFormFactory"
import { makeStyles } from "@material-ui/core/styles"
import strings from "tools/strings"
import styled from "styled-components"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 50px;
  max-width: 600px;
  margin: 0 auto;
`

const StyledCreateButton = styled(Button)`
  margin-right: auto;
`

const useStyles = makeStyles({
  width: {
    width: 400
  }
})

const DefinitionList = ({ userStore, permissions, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const [definitions, setDefinitions] = useState(null)
  const [singleDef, setSingleDef] = useState({})
  const viewVersion = useSelector(state => state.admin.ui.viewVersion)

  const fetchDef = async event => {
    try {
      const def = await userStore.getSingleDef(event.target.value)
      if (def) {
        setSingleDef(def)
      } else notify("customRoot.general.error", "warning")
    } catch (error) {
      notify("customRoot.general.error", "warning")
    }
  }
  const handleSave = async values => {
    const { key, code } = singleDef
    let value = values.value === undefined ? values : values.value 
    if(key === strings.definitionsNames.block_run_charge_flow ){
      value = value === false ? 0 : 1
    }
    const updateDef = await userStore.updateOrgDefinition(key, code, value)
    if (updateDef) {
      notify("customRoot.general.success", "success")
    } else if (!updateDef) {
      notify("customRoot.general.error", "warning")
    }
  }

  const handleDelete = async () => {
    const { key, code } = singleDef
    const delDef = await userStore.deleteOrgDefinition(code, key)
    if (delDef) {
      notify("customRoot.general.delete_success", "success")
      await userStore.getOrgDefinitions()
      setSingleDef({})
    } else if (!delDef) {
      notify("customRoot.general.error", "warning")
    }
  }

  const handleAddDefinition = async () => {
    const { key } = singleDef
    const thisTypeArray = definitions.filter(def => def.def.key === key)
    const lastElem = thisTypeArray[thisTypeArray.length - 1]
    const codeStarts = lastElem.def.code.split('')
    const newCode = `${codeStarts[0]}${codeStarts[1]}${parseInt(lastElem.def.code) + 1}`
    const addDef = await userStore.updateOrgDefinition(key, newCode, lastElem.def.value)
    if (addDef) {
      notify("customRoot.general.success", "success")
      await userStore.getOrgDefinitions()
    } else if (!addDef) {
      notify("customRoot.general.error", "warning")
    }
  }

  useEffect(() => {
    userStore.getOrgDefinitions()
  }, [userStore, viewVersion])

  useEffect(() => {
    setDefinitions(userStore.defList)
    const definition = userStore.defList?.find(el => el.id === `${singleDef.key} # ${singleDef.code}`)
    if (definition?.def) {
      setSingleDef({ key: definition.def.key, record: definition.def.value, code: definition.def.code })
    }
  }, [userStore, userStore.defList])
  
  if (!userStore.org) {
    return null
  }
  return (
    <FormContainer>
      {singleDef && (singleDef.record || singleDef.record === 0) && (
        <StyledCreateButton variant='contained' color='primary' startIcon={<LibraryAddIcon />} onClick={handleAddDefinition}>
          {translate("customRoot.definitions.addDefinition")}
        </StyledCreateButton>
      )}
      <FormWithRedirect {...props} render={() => <form>{definitions && <SelectInput className={classes.width} source='sys_id' label='customRoot.definitions.def' choices={definitions} onChange={fetchDef} />}</form>} />
      {singleDef && (singleDef.record || singleDef.record === 0) && <OrgFormFactory {...props} onDelete={handleDelete} onSave={handleSave} def={singleDef.record} type={singleDef.key} initialValue={singleDef.initialValue} />}
    </FormContainer>
  )
}

export default inject("userStore")(observer(DefinitionList))
