import { inject, observer } from "mobx-react"
import React from "react"
import { List, Datagrid, TextField } from "react-admin"
import styled from "styled-components"
import ArchiveIcon from '@material-ui/icons/Archive';
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Button from "@material-ui/core/Button"
import { useTranslate, useNotify } from "react-admin"
import { TASK } from 'clearing-types';
import { PeriodDialog } from '../../../src/components/common/period_dialog'

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 35px;
`
const StyledButton = styled(Button)`
 margin-bottom: -20px;
` 

const PosList = ({ userStore, tasksStore, ...props }) => {
  const translate = useTranslate()
  const notify = useNotify()

  if (!userStore.org) {
    return null
  }

  const getPosFetch = () => {
    const tasks = tasksStore.tasksList
    const posFetch = tasks.find(v => v.name === TASK.POS_FETCH)
    return posFetch
  }

  const sendRequest = async () => {
    const runResult = await userStore.runProcess(tasksStore.periodData, getPosFetch())
    if (runResult.success) notify("customRoot.requestResult.success", "success")
    else notify("customRoot.requestResult.error", "warning")
  }

  return (
    <FormContainer>
      <StyledButton onClick={() => tasksStore.setOpenPeriodDialog(true)}>
      <ArchiveIcon />
      <ListItem><ListItemText primary={translate(`customRoot.scheduleTasks.pos_fetch`)} /></ListItem>
      </StyledButton>
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick='edit'>
          <TextField source='name.he' label='customRoot.pos.name' sortable={false} />
          <TextField source='system_name' label='customRoot.pos.system_name' sortable={false} />
        </Datagrid>
      </List>
      <PeriodDialog
        open={tasksStore.isOpenPeriodDialog}
        setOpenDialog={() => tasksStore.setOpenPeriodDialog(!tasksStore.isOpenPeriodDialog)}
        setPeriod={(date) => tasksStore.setPeriod(date)}
        confirmDialog={sendRequest}
        dialogTitle={translate(`customRoot.scheduleTasks.pos_fetch`)}
        format={getPosFetch()?.periodFormat}
        defaultPeriod={getPosFetch()?.defaultPeriod}
      />
    </FormContainer>
  )
}

export default inject('userStore', 'tasksStore')(observer(PosList))
