

import React, { useEffect, useState } from "react"
import { observer, inject } from "mobx-react"
import { TextInput, useRedirect, useTranslate, CheckboxGroupInput, SelectInput, RadioButtonGroupInput, required } from "react-admin"
import styled from "styled-components"
import { Form, useFormState } from "react-final-form"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import WarningDialog from "../../utils/WarningDialog"
import arrayMutators from "final-form-arrays"
import { Add, RemoveCircle } from '@material-ui/icons'
import { ChargingLogics } from 'clearing-types'
import { FormControl, Grow, IconButton, InputLabel, MenuItem, Select, FormHelperText } from "@material-ui/core"

const StyledTitle = styled.div`
    font-weight:bold;
    padding:15px 0 15px 0;
`
const StyledFormControl = styled(FormControl)`
`
const StyledRemoveCircle = styled(RemoveCircle)`
  color:red;
`
const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  margin-right: 50px;
`
const StyledOkButton = styled(Button)`
  background-color: green;
`
const StyledDeleteButton = styled(StyledButton)`
  background-color: red;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const useStyles = makeStyles({
  width: {
    width: 400
  }
})

const FinancialBookSection = props => {
  const { handleSubmit, deleteWarning, financialBook, handleCancel, institutions = [], financialCompanies = [], creditCardGateways = [] } = props
  const { otherRelations, setOtherRelations } = props
  const translate = useTranslate()
  const availableRelations = [...institutions, ...creditCardGateways];
  const financialCompanyChoices = financialCompanies.map(el => ({ name: el.company_id, id: el.code }))
  const otherChoices = availableRelations.map(el => ({ id: el.data.code, name: el.data.name.he || el.data.name || '' }))
  const isEdit = Boolean(financialBook?.data?.code)
  const [invalidInstitution, setInvalidInstitution] = useState(false);
  const canAddRelation = otherRelations.length < availableRelations.length
  const remainingOptions = availableRelations.filter(el => !otherRelations.find(el2 => el2.code === el.data.code)).map(el => ({ id: el.data.code, name: el.data.name.he || el.data.name || '' }))
  const validateRequired = [required()];

  const addRelation = () => {
    setOtherRelations(prev => [...prev, { code: '', sub_type: null }])
  }

  const removeRelation = (index) => {
    setOtherRelations(prev => [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)])
  }

  const onChange = (code, index) => {
    const found = availableRelations.find(el => el.data.code === code)
    if (found) {
      setOtherRelations(prev => {
        prev[index] = { code: found.data.code, sub_type: found.sub_type }
        return [...prev]
      })
    }
    if (code)
    setInvalidInstitution(false)
  }
  const formState = useFormState()
  const validateCompaniesAndInstitutions = (values) => {
    const errors = {};
    if (!formState.values.financial_companies && otherRelations.filter(el => el.code).length < 2) {
      errors.message = 'ra.validation.minimumSelected';
      errors.args = { min: 2 }
      return errors
    }
    return undefined
  };

  const validateInstitution = () => {
    const institutionWithValue = otherRelations.find(el => !!el.code)
    if (!institutionWithValue) {
      setInvalidInstitution(true)
    }
  };

  const submitWithValidation = () => {
    validateInstitution()
    return handleSubmit
  };
  useEffect(() => {
    if (financialBook) {
      setOtherRelations(financialBook?.relations.filter(el => el.sub_type !== 'financial_company') || [])
    }
    if (otherRelations.length === 0 && !isEdit) {
      setOtherRelations([{ code: '', sub_type: null }])
    }
  }, [financialBook])

  const chargingLogicsChoices = (Object.values(ChargingLogics || {})).map(charge => {
    return ({ value: charge, id: charge, label: `customRoot.definitions.edit.financial_book.charging_logics.${charge}` })
  })

  return (
    <BodyContainer>
      <TextInput source='data.name.he' label='customRoot.definitions.edit.financial_book.name.he' validate={validateRequired} />
      <TextInput dir='ltr' source='data.name.en' label='customRoot.definitions.edit.financial_book.name.en' validate={validateRequired} />
      <CheckboxGroupInput source="data.currencies" label='customRoot.definitions.edit.currencies' choices={[
        { id: "ILS", name: "customRoot.definitions.edit.financial_book.currency.ILS" },
        { id: "USD", name: "customRoot.definitions.edit.financial_book.currency.USD" }
      ]} validate={validateRequired} />
      <RadioButtonGroupInput source="data.charging_logics" choices={chargingLogicsChoices} optionText="label" optionValue="value" label='customRoot.definitions.edit.financial_book.charging_logics.title' row={true} />

      <SelectInput
        validate={validateCompaniesAndInstitutions}
        source='financial_companies'
        label='customRoot.definitions.edit.financialCompanies.financial_system'
        choices={financialCompanyChoices}
      />
      {Boolean(otherRelations?.length) && otherRelations.map((el, index) => (
        <Grow in timeout={el.code === '' ? 500 : 0}>
          <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 8, alignItems: 'center' }}>
            {index === otherRelations.length - 1 && canAddRelation && (
              <ButtonsContainer>
                <Button onClick={addRelation} title={'Create institution'}><Add /></Button>
              </ButtonsContainer>
            )}
            <StyledFormControl variant="filled" fullWidth >
              <InputLabel error={invalidInstitution && index === 0} id="demo-simple-select-filled-label">{
                (() => {
                  switch (el.sub_type) {
                    case 'financial_company':
                      return translate('customRoot.definitions.edit.financialCompanies.financial_system')
                    case 'institution_debit':
                      return translate('customRoot.definitions.edit.institution.title')
                    case 'institution_credit':
                      return translate('customRoot.definitions.edit.institution.title')
                    case 'credit_card_gateway':
                      return translate('customRoot.definitions.edit.credit_card_gateways.title')
                    default:
                      return translate('customRoot.definitions.edit.financialCompanies.empty_debit_credit');
                  }
                })()}
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={el.code}
                error={invalidInstitution && index === 0}
                onChange={(e) => onChange(e.target.value, index)}
              >
                {(el.code === '' ? remainingOptions : otherChoices.filter(el2 => el2.id === el.code)).map(el => (
                  <MenuItem value={el.id}>{el.name}</MenuItem>
                ))}
              </Select>
              {index === 0 && <FormHelperText error={invalidInstitution} hidden={!invalidInstitution}>{translate("ra.validation.required")}</FormHelperText>}
            </StyledFormControl>
            {otherRelations.length > 1 && <IconButton onClick={() => removeRelation(index)}>
              <StyledRemoveCircle />
            </IconButton>}
          </div>
        </Grow>
      ))}
      <ButtonsContainer>
        <StyledOkButton variant='contained' color='primary' type='submit' onClick={() => submitWithValidation()}>
          {translate("customRoot.definitions.edit.submit_button")}
        </StyledOkButton>
        {isEdit && (
          <StyledDeleteButton variant='contained' color='secondary' onClick={deleteWarning}>
            {translate("customRoot.definitions.edit.delete_button")}
          </StyledDeleteButton>
        )}
        <StyledButton variant='contained' color='primary' type='cancel' onClick={handleCancel}>
          {translate("customRoot.definitions.edit.cancel_button")}
        </StyledButton>
      </ButtonsContainer>
    </BodyContainer>
  )
}
const WarningActions = { delete: "delete", skip: "skip" }

const FinancialBookCreateAndEdit = ({ corporationStore, ...props }) => {
  const { id, version } = props
  const [financialBook, setFinancialBook] = useState(null)
  const [otherRelations, setOtherRelations] = useState([])
  const classes = useStyles()
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningContentTexts, setWarningContentTexts] = useState({ content: "", title: "" });
  const [warningAction, setWarningAction] = useState(WarningActions.skip);
  const redirect = useRedirect();
  const translate = useTranslate()
  const corporationName = corporationStore.currentCorporation?.data?.name?.he || ''
  const defaultFinancialCompanyRelation = financialBook?.relations?.find(el => el.sub_type === 'financial_company')
  useEffect(() => {
    async function getCurrentFinancialBook() {
      let res = await corporationStore.getFinancialBookData(id)
      setFinancialBook(res)
    }
    if (corporationStore.currentCorporation) {
      if (id) {
        getCurrentFinancialBook()
      }
    } else {
      redirect('/corporations');
    }
  }, [])
  const onDelete = async () => {
    return await corporationStore.deleteFinancialBook(financialBook.data.code)
  }

  const onSave = async (res) => {
    const relations = otherRelations.filter(el => el.code !== '')
    if (res.financial_companies) {
      res.relations = [...relations, { code: res.financial_companies, sub_type: 'financial_company' }]
    } else {
      res.relations = [...relations]
    }
    if (!id) {
      return await corporationStore.createFinancialBook(res)
    }
    res.id = financialBook.data.code
    return await corporationStore.updateFinancialBook(res)
  }

  const onSuccess = async () => {
    setWarningOpen(false)
    redirect(`/corporations/${corporationStore.currentCorporation.data.code}`)
  }
  const handleCancel = () => {
    redirect(`/corporations/${corporationStore.currentCorporation.data.code}`)
  }
  const onFailed = async (error) => {
    setWarningContentTexts({ content: error, title: "customRoot.definitions.failureMessage" })
    setWarningOpen(true)
  }

  const handleCloseWarning = () => {
    setWarningOpen(false)
  }

  const deleteWarning = () => {
    setWarningContentTexts({ content: "customRoot.definitions.deleteWarningContent", title: "customRoot.definitions.deleteWarningTitle" })
    setWarningAction(WarningActions.delete)
    setWarningOpen(true)
  }

  const handleWarningConfirm = async () => {
    if (warningAction === WarningActions.delete) {
      const res = await onDelete()
      if (!res.success) {
        onFailed(res.error)
      }
      else {
        onSuccess()
      }
      setWarningAction(WarningActions.skip)
    } else {
      setWarningOpen(false)
    }
  }

  const checkFinancialBookDataIsNotEmpty = (financialBook) => {
    return financialBook && financialBook.data && financialBook.data.name && financialBook.data.name.he && financialBook.data.name.en && financialBook.data.currencies && financialBook.data.currencies.length > 0
  }

  const handleClick = async (results) => {
    const institutionWithValue = otherRelations.find(el => !!el.code)
    if (!institutionWithValue) {
      return
    }
    if (!checkFinancialBookDataIsNotEmpty(results)) {
      setWarningContentTexts({ content: "customRoot.definitions.missingData", title: "customRoot.definitions.failureMessage" })
      setWarningOpen(true)
      return
    }
    const res = await onSave(results)
    if (!res.success) {
      onFailed(res.error)
    }
    else {
      onSuccess()
    }
  }
  return (
    <Form
      onSubmit={handleClick}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      initialValues={{
        financial_companies: defaultFinancialCompanyRelation?.code,
        data: {
          name: financialBook?.data?.name,
          currencies: financialBook?.data?.currencies,
          charging_logics: financialBook?.data?.charging_logics
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <FormContainer>
          <StyledTitle>{translate('customRoot.definitions.edit.named_corporation', { field: corporationName })}: {translate(id ? 'customRoot.definitions.edit.financial_book.title_update' : 'customRoot.definitions.edit.financial_book.title_create')}</StyledTitle>
          <StyledForm onSubmit={handleSubmit} className={classes.width}>
            <FinancialBookSection
              financialBook={financialBook}
              otherRelations={otherRelations} setOtherRelations={setOtherRelations}
              handleSubmit={handleSubmit}
              deleteWarning={deleteWarning}
              handleCancel={handleCancel}
              institutions={corporationStore.currentInstitutions}
              creditCardGateways={corporationStore.currentCreditCardGateways}
              financialCompanies={corporationStore.financialCompanies}
            />
          </StyledForm>
          <WarningDialog open={warningOpen} handleClose={handleCloseWarning} handleConfirm={handleWarningConfirm} content={warningContentTexts.content} title={warningContentTexts.title} />
        </FormContainer>
      )}></Form>
  )
}
export default inject("corporationStore")(observer(FinancialBookCreateAndEdit))

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
}