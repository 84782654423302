import axios from 'axios'
import { DOMAIN } from './constants'

axios.defaults.withCredentials = true

const _axios = axios.create({
    baseURL: DOMAIN
})

const endpoints = {
    addCommunity: '/organizations/api/v1/filesystem/communities/community',
    getMappings: (extCommunityId) => `/organizations/api/v1/filesystem/mappings/listallsources/community/${extCommunityId}`,
    addMapping: '/organizations/api/v1/filesystem/mappings/mapping',
    updateMapping: (id) => `/organizations/api/v1/filesystem//mappings/mapping/${id}`,
    deleteMapping: (id) => `/organizations/api/v1/filesystem//mappings/mapping/${id}`,
    getMapping: (id) => `/organizations/api/v1/filesystem/mappings/mapping/${id}`,
    getCommunityByExtId: (extId) => `/organizations/api/v1/filesystem/communities/community/external/${extId}`,
    cancelTask: (taskId) => `/organizations/api/v1/job-list/${taskId}/cancel`
}

const request = async (method, url, data) => {
    try {
        const response = await _axios[method.toLowerCase()](url, data)
        return method == 'POST' || method == 'PUT' ? response : response.data
    } catch (err) {
      console.error(err)
      /*if (err.request.status !== STATUS_UNAUTHORIZED) {
        console.error(err)
        return
      }*/
    }
}

export default {
    request,
    endpoints
}
