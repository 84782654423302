export default {
  resources: {
    users: {
      name: "משתמשים"
    },
    organizations: {
      name: "ארגונים"
    },
    corporations: {
      name: "תאגידים"
    },
    definitions: {
      name: "הגדרות"
    },
    orders: {
      name: "הזמנות"
    },
    systems: {
      name: "מערכות פננסיות"
    },
    finance_gateways: {
      name: "הגדרות למע׳ פיננסיות"
    },
    creditCard_gateways: {
      name: "gateways לכרטיסי אשראי"
    },
    source_attachments: {
      name: 'מקורות קבצי תקציב'
    }

  },
  ra: {
    action: {
      add_filter: "הוסף סינון",
      add: "הוסף",
      back: "חזור",
      bulk_actions: "פריט אחד נבחר |||| %{smart_count} פריטים נבחרו",
      cancel: "ביטול",
      clear_input_value: "נקה ערך",
      clone: "שכפל",
      confirm: "אשר",
      create: "צור",
      edit: "ערוך",
      delete: "מחק",
      list: "רשימה",
      refresh: "רענן",
      remove_filter: "הסר סינון",
      remove: "מחק",
      save: "שמור",
      search: "חפש",
      show: "הצג",
      sort: "מיין",
      undo: "בטל",
      export: "יצא את כל הנתונים",
      exportFinance: "יצא את כל הנתונים הפיננסיים",
      exportChoosen: "יצא נתונים נבחרים",
      exportFinanceChoosen: "יצא נתונים פיננסיים נבחרים",
      expand: "הרחב",
      close: "סגור",
      unselect: "בטל סימון",
      add_to_registered: "הוסף לרשומים",
      clean_search: "ניקוי החיפוש",
      loadToStorage: "טען לאחסון הודעות",
    },
    boolean: {
      true: "כן",
      false: "לא"
    },
    page: {
      create: "צור %{name}",
      dashboard: "לוח המחוונים",
      edit: "%{name} #%{id}",
      error: "שגיאה התרחשה",
      list: "רשימת %{name}",
      loading: "טוען",
      not_found: "לא קיים",
      show: "%{name} #%{id}",
      auth: "אין לך הרשאות לראות דף זה",
      empty: "ריק",
      invite: "הזמנה"
    },
    input: {
      file: {
        upload_several: "גרור מספר קבצים להעלאה או לחץ לבחור אחד.",
        upload_single: "גרור קובץ להעלאה או לחץ לבחור אחד."
      },
      image: {
        upload_several: "גרור מספר תמונות להעלאה או לחץ לבחור אחת.",
        upload_single: "גרור תמונה להעלאה או לחץ לבחור אחת."
      },
      references: {
        all_missing: "ישויות הקשר לא נמצאו.",
        many_missing: "לפחות אחת מישויות הקשר אינה זמינה יותר.",
        single_missing: "ישות הקשר אינה זמינה יותר."
      },
      upload_label: "בחר קובץ"
    },
    message: {
      about: "אודות",
      are_you_sure: "האם את/ה בטוח/ה ?",
      bulk_delete_content: "האם אתה בטוח שברצונך למחוק %{name}? |||| האם אתה בטוח שברצונך למחוק %{smart_count} פריטים?",
      bulk_delete_title: "מחק %{name} |||| מחק %{smart_count} %{name} פריטים",
      delete_content: "האם אתה בטוח שברצונך למחוק את הפריט הזה?",
      delete_title: "מחק %{name} #%{id}",
      details: "פרטים",
      error: "קרתה שגיאת תקשורת והבקשה שלך לא הסתיימה.",
      invalid_form: "הטופס אינו תקין. אנא תקן את שגיאותיך",
      loading: "העמוד טוען, אנא המתן",
      no: "לא",
      yes: "כן",
      not_found: "הכנסת כתובת שגויה או לחצת על קישור לא תקין"
    },
    navigation: {
      no_results: "לא נמצאו תוצאות",
      no_more_results: "עמוד %{page} מחוץ לתחום. אנא נסה את העמוד הקודם.",
      page_out_of_boundaries: "עמוד %{page} מחוץ לתחום",
      page_out_from_end: "לא ניתן להמשיך מעבר לעמוד האחרון",
      page_out_from_begin: "לא ניתן לגשת לפני העמוד הראשון",
      page_range_info: "%{offsetBegin}-%{offsetEnd} מתוך %{total}",
      page_rows_per_page: "מספר שורות לעמוד:",
      next: "הבא",
      prev: "הקודם",
      skip_nav: "דלג על ניווט",
    },
    auth: {
      user_menu: "פרופיל",
      username: "שם משתמש",
      token: "טוקן",
      memberId: "מזהה משתמש",
      password: "סיסמא",
      sign_in: "כניסה",
      sign_in_error: "הכניסה נכשלה, אנא נסו שנית",
      no_permissions: "אין הרשאות גישה לאפליקציה",
      logout: "התנתקות",
      auth_check_error: "אין לך הרשאות לראות דף זה",
      twoFactorMessageMobile: "שלחנו קוד בן 6 ספרות למספר ",
      twoFactorField: "%{field}",
      twoFactorMessageMail: "שלחנו קוד בן 6 ספרות למייל ",
      smsCode: "הכנס את הקוד",
      send: "שלח",
      sms: "מסרון ל %{number}",
      email: "דואר אלקטרוני ל %{email}",
      chooseChannel: "בחר לאן ישלח הקוד",
      twoFactor: "אימות דו שלבי",
      cookiesTitle: "שגיאת cookies",
      cookiesError: "אנא אפשר cookies בדפדפן שלך בכדי שהאפליקציה תעבוד"
    },
    notification: {
      updated: "פריט עודכן בהצלחה",
      created: "פריט נוצר בהצלחה",
      deleted: "פריט נמחק בהצלחה",
      bad_item: "פריט לא תקין",
      item_doesnt_exist: "פריט לא קיים",
      http_error: "שגיאה בהתקשרות מול השרת",
      conflict: "שגיאה בשמירה למסד הנתונים, יתכן וההגדרה כבר קיימת",
      data_provider_error: "שגיאת dataProvider. בדוק את הקונסול לפרטים נוספים.",
      canceled: "הפעולה בוטלה",
      logged_out: "הנך מנותק, אנא התחבר מחדש."
    },
    validation: {
      required: "חובה",
      minLength: "חייב להכיל לפחות %{min} תווים",
      maxLength: "יכול להכיל לכל היותר %{max} תווים",
      minValue: "חייב להיות לפחות %{min}",
      maxValue: "חייב להיות %{max} או פחות",
      number: "חייב להיות מספר",
      email: 'כתובת דוא"ל לא תקנית',
      oneOf: "חייב להיות מאחת האפשרויות הבאות: %{options}",
      regex: "חייב להתאים לתבנית ספציפית (ביטוי רגולרי): %{pattern}",
      israeliID: "תעודת זהות אינה תקינה",
      israeliPhone: "מס׳ טלפון לא תקין",
      alphabetic: "חייב להכיל אותיות בלבד",
      minimumSelected: 'חובה לבחור לפחות %{min} אפשרויות',
      invalidRegex: 'ביטוי רגולרי לא תקין'
    },
    select_financial_book: "בחר כרטסת נושא",
    upload_failed_message: "טעינת הקובץ נכשלה",
    upload_success_message: "הקובץ נטען בהצלחה",
    missing_financial_book: "יש להגדיר כרטסות נושא לפני העלאת קובץ זיכויים"
  },
  customRoot: {
    schedule_type:{
      monthly:'חודשי',
      daily: 'יומי'
    },
    myMenu: {
      users: "משתמשים",
      uploadItems: "העלאת קבצי תפריט",
      organizations: "ארגונים",
      corporations: "תאגידים",
      global_definitions: "הגדרות כלליות",
      definitions: "הגדרות ארגון",
      reports: "דוחות",
      tasks: "משימות",
      roles: "תפקידים",
      systems: "מערכות פננסיות",
      creditCard_gateways: "gateways לכרטיסי אשראי",
      manualRun: "הרצות ידניות",
      flows: "תהליכים",
      services: "סרביסים",
      pos: 'נקודות מכירה',
      manage_pos: 'ניהול נקודות מכירה',
      upload_incomes: 'העלאת קובץ זיכויים',
      source_attachments: 'מקורות קבצי תקציב',
      slika_processes: 'תהליכי סליקה (זיכוי וחיוב)',
      charge_permissions: 'ניהול הרשאות לחיוב',
      job_manager: 'מנהל המשימות'
    },
    dashboard: {
      title: "מערכת ניהול מקומי סליקה",
      usersRegTitle: "סטטוס רישום",
      numberOfUsers: "מספר משתמשים בקיבוץ",
      paymentMethods: "אמצעי תשלום"
    },
    validation: {
      gateway_exists: "קיימת הגדרה למע׳ בסביבה זו למחייב הנ״ל"
    },
    services: {
      title: 'סרביסים לאתחול',
      alertTitle: 'אתה עומד לאתחל את הסרביס הזה',
      alertDescription: 'אתה עומד לאתחל את הסרביס הזה. האם אתה בטוח בכך?',
      notAvailable: 'לא ניתן לאתחל סרביבים בפרודקשן',
      killSuccess: 'הבקשה נשלחה בהצלחה. תהליך האתחול החל',
      killfailure: 'אירעה תקלה בשליחת הבקשה'
    },
    users: {
      list: {
        id: "מזהה",
        has_budget_role: "הרשאה לצפות בתקציב",
        first_name: "שם פרטי",
        last_name: "שם משפחה",
        teudat_zehut: "תעודת זהות",
        entity: "Entity ID",
        entity_type: "סוג תקציב",
        member_type: "סוג חבר",
        registration_status: "סטטוס הרשמה",
        is_admin: "אדמין",
        username: "שם משתמש",
        password: "סיסמא",
        role: "תפקיד",
        email: "דואר אלקטרוני",
        phone: "טלפון",
        filters: "פילטרים",
        tags: "תגיות",
        filterLogic: "לוגיקת פילטרים",
        usersStatus: "סטטוס (משתמשים פעילים)",
        cleanSelected: "נקה מסומנים",
        selectAllPage: "סמן את כל העמוד",
        actions: {
          registration: "רישום",
          bank_order_status: " עדכון סטטוס הרשאות לחיוב",
          registration_status: "עדכון סטטוס רישום",
          choose_status: "בחר סטטוס",
          approved_status: "אושר",
          declined_status: "נדחה",
          canceled_status: "בוטל",
          register_status: "רישום ידני",
          unregister_status: "ביטול רישום",
        }
      },
      edit: {
        add_role: "הוספת תפקיד",
        remove_role: "הסרת תפקיד",
        roles_header: "ניהול תפקידים למשתמש"
      },
      create: {
        first_name: "שם פרטי",
        last_name: "שם משפחה",
        admin_: "מנהל ארגון",
        admin_treasurer_: "מנהל פיננסי",
        admin_sys: "מנהל מערכת",
        admin_service_: "ניהול שירות",
        member: "חבר בארגון",
        member_: "חבר במשפחה",
        member_budget_: "נרשם לשירות",
        admin_operator: " מיישם",
        admin_service_organizations: "תפקיד לשירות ארגונים",
      },
      link: {
        button: "עבור ל%{link}",
        link: "שלח לינק לאפליקציית אדמין",
        subject: "לינק עבור אפליקציית ניהול מקומי סליקה",
        template: "הכנס ללינק בכדי לנהל את ארגונך במערכת הסליקה {admin_org_link}",
        error: "שליחת הלינק נכשלה",
        success: "הלינק נשלח בהצלחה"
      }
    },
    orders: {
      list: {
        order_number: "מספר הזמנה",
        budget_number: "מספר תקציב",
        order_date: "תאריך ההזמנה",
        org_name: "שם הארגון",
        system_name: "שם המערכת",
        items: "הפריטים בהזמנה",
        customer_name: "שם הלקוח",
        customer_address: "כתובת הלקוח",
        customer_email: 'דוא"ל הלקוח',
        phone: "טלפון",
        order_type: "סוג ההזמנה",
        status: "מצב ההזמנה",
        search: "חיפוש",
        get_orders_button: "הבא הזמנות"
      },
      items: {
        item_name: "שם המוצר",
        price: "מחיר",
        quantity: "כמות",
        category: "קטגוריה",
        remarks: "הערות"
      },
      modal: {
        print: "הדפס",
        update_status_button: "עדכן סטטוס להזמנה",
        confirm: "אישור",
        cancel: "ביטול"
      }
    },
    definitions: {
      org: "בחר ארגון",
      def: "בחר הגדרה",
      loading: "הקובץ עולה, אנא המתן",
      successMessage: "עודכן בהצלחה",
      failureMessage: "העדכון נכשל",
      missingData: "מידע חסר",
      invalidBusinessNumber: "מספר ח.פ. לא תקין",
      addDefinition: "שכפל הגדרה",
      deleteWarningTitle: "מחיקת הגדרה",
      deleteWarningContent: "פעולה זו היא בלתי הפיכה",
      edit: {
        currencies: "מטבעות",
        currency: "מטבע",
        display_name: {
          he: "שם תצוגה",
          en: "שם תצוגה באנגלית"
        },
        name: {
          he: "שם בעברית",
          en: "שם באנגלית"
        },
        system: {
          end_point: "כתובת API",
          credentials: {
            user: "משתמש",
            password: "סיסמא"
          }
        },
        org_type: "סוג המחייב",
        contact_for_enquiries: "תבנית ליצירת קשר",
        start_of_charging_cycle: {
          day: "יום להתחלת מחזור חיוב",
          last_day_of_month: "יום אחרון של החודש"
        },
        charging_dates_helper: "אפשר להכניס כל יום בין 1 ל28 כאשר הכנסה של 0 פירושה עבודה ידנית",
        printed_notifications_distributers_helper: "הכנס יום בשבוע בין 0 ל 6 כאשר 0 הוא יום א'",
        charging_date: {
          day: "יום לביצוע הסליקה"
        },
        charging_permission_receivers: {
          emails: "רשימת מיילים לניהול הוראות קבע",
          month: "רשימת תאריך לשליחה עדכונים",
          day: "יום",
          email: "כתובת מייל"
        },
        charging_summary_receivers: {
          emails: "רשימת מיילים לשאלות וברורים"
        },
        contact_appeals_recipients: {
          emails: "מייל לשאלות ובירורים"
        },
        zooz: {
          app_id: "מזהה אפליקציה zooz",
          public_key: "מפתח ציבורי",
          private_key: "מפתח פרטי"
        },
        finance_system_name: {
          title: "מיפוי מערכת פיננסית",
          name: "שם מערכת פיננסית",
          code: "מזהה מערכת פיננסית"
        },
        masav: {
          chargee: "מזהה מחייב",
          charge_code: 'קוד חיוב במס"ב',
          mosad: 'קוד הארגון במס"ב',
          income_code: 'קוד זיכוי במס"ב',
          primary: "ראשי"
        },
        finance_cloud_storage: {
          provider: "ספק",
          username: "שם משתמש לספק הקבצים",
          password: "סיסמא לספק הקבצים",
          folder_path: "נתיב הקבצים",
          url: "כתובת אינטרנט של הספק (URL)",
          proxy_token: "טוקן לשירות הפרוקסי של סליקה"
        },
        org_name: {
          he: "שם ארגון - עברית",
          en: "שם ארגון - אנגלית"
        },
        corporation_name: {
          he: "שם תאגיד - עברית",
          en: "שם תאגיד - אנגלית"
        },
        corporation_members_type: "סוג חברים",
        corporation_id: "מספר ח.פ",
        named_corporation: "תאגיד %{field}",
        financialCompanies: {
          title: "חברות פיננסיות",
          title_create: "יצירת חברות פיננסיות",
          title_update: "עריכת חברות פיננסיות",
          financial_system: "קוד חברה פיננסית",
          empty_debit_credit: "בחירת מחייב/מזכה",
          company_id: "קוד חברה",
          username: "שם משתמש",
          password: "סיסמה",
          endpoint: "נתיב גישה",
          receipts: "קבלות",
          payments: "תשלומים"
        },
        institution: {
          name: {
            en: "שם מוסד אנגלית",
            he: "שם מוסד עברית",
          },
          title: "מחייב/מזכה בנק",
          title_create: "יצירת מחייב/מזכה בנק",
          title_update: "עריכת מחייב/מזכה בנק",
          institution_code: "קוד מוסד",
          transaction_code: "קוד לשכת שירות",
          bank_code: "מספר בנק",
          branch_code: "מספר סניף",
          account_number: "מספר חשבון",
          type: "סוג מוסד",
          institution_credit: "מזכה",
          credit_card_gateway: "מזכה אשראי",
          institution_debit: "מחייב",
          financial_company: "חברה פיננסית",
          company_id: "מספר חברה פיננסית",
          institutions_credit_and_debit: "מוסדות מחייבים ומזכים (בנק)"
        },
        credit_card_gateways: {
          title: "מחייב אשראי",
          title_update: "עריכת מחייב אשראי",
          title_create: "יצירת מחייב אשראי",
          code: "קוד",
          app_id: "App ID",
          private_key: "Private API Key",
          public_key: "Public API Key",
          business_number: "מס' בית עסק",
          institutions: "מוסדות מחייבים (אשראי)",
          name: {
            he: "שם מחייב אשראי בעברית",
            en: "שם מחייב אשראי באנגלית"
          }
        },
        financial_book: {
          financial_books: "כרטסות נושא",
          title: "כרטסת נושא",
          title_update: "עריכת כרטסת נושא",
          title_create: "יצירת כרטסת נושא",
          name: {
            he: "שם כרטסת נושא - עברית",
            en: "שם כרטסת נושא - אנגלית"
          },
          currency: {
            title: "מטבע",
            ILS: "שקל חדש",
            USD: "דולר ארהב",
          },
          charging_logics: {
            title: "לוגיקת סליקה ",
            OMB: "ברוטו",
            OMN: "נטו",
          },
        },
        organization_address: "כתובת הארגון",
        default_noreply_email: "לא-להשיב מייל",
        submit_button: "שמור",
        cancel_button: "בטל",
        cancel_back_button: "בטל (חזרה למסך הקודם)",
        delete_button: "מחק הגדרה",
        create_button: "צור הגדרה חדשה",
        import_file_headers_size: "מספר עמודות בקובץ ייבוא משתמשים",
        organization_email: "כתובת דוא״ל של הארגון",
        organization_phone: "טלפון של הארגון",
        organization_sms_sender: "שם השולח במסרון לחברי הארגון",
        banks_orders_requests_start_time: "זמן תחילת תהליך בקשת שליחת הוראות חיוב",
        banks_orders_requests_end_time: "זמן סיום תהליך בקשת שליחת הוראות חיוב",
        banks_orders_fail_uncompleted_end_time: "זמן סיום תהליך הכשלת הוראות חיוב שלא הסתיימו",
        banks_orders_fail_uncompleted_start_time: "זמן תחילת תהליך הכשלת הוראות חיוב שלא הסתיימו",
        org_language: "שפת הארגון",
        receipt_at_status: "צור קבלה כאשר סטטוס החיוב הוא",
        receipt_at_status_helper: "לא לשנות אם אתם לא יודעים מה זה עושה :)",
        banks_orders_requests_pending_timeframe_in_days: "פרמטר זמן בימים להצגת הרשאות חיוב ממתינות",
        banks_orders_requests_pending_timeframe_in_days_helper: "יציג בדוחות, הרשאות לחיוב הממתינות מעל לפרמטר הזמן המוגדר",
        registration_payment_method: "שיטות חיוב אפשריות בתהליך הרשמה",
        registration_payment_method_helper: "001 = אשראי | 002 = בנק",
        registration_payment_method_active: "פעיל",
        send_budget_pages: "אפשר לשלוח דפי תקציב בדוא”ל",
        send_budget_pages_active: "פעיל",
        auto_pull_financial_data: "משיכת נתונים אוטומטית",
        auto_pull_financial_data_active: "פעיל",
        org_language_helper: "אחד מהשניים - he/en",
        banks_orders_results_timeframe_in_days: "פרמטר זמן בימים המגדיר טווח תהליך קבלת תוצאות הרשאות חיוב בנקים",
        payments_fetching_dates: {
          title: "תאריכי משיכת נתונים ממערכת פיננסית",
          field: "תאריך"
        },
        printed_notifications_distributers: {
          title: "ניהול חלוקה בדואר רגיל",
          emails: "רשימת כתובות מייל",
          send_updates_on: "שלח עדכונים בשבוע"
        },
        banks_orders_requests_emails: {
          title: "רשימת הגדרות לשליחת התראות מייל ביצירת קבצי מסב",
          emails: "רשימת כתובות מייל",
          subject: "נושא המייל",
          from: "הכתובת שממנה יגיע המייל",
          body: "גוף המייל"
        },
        run_charge_flow_emails: {
          title: "רשימת הגדרות לשליחת מייל לבקשה להרצת תהליך סליקה",
          emails: "רשימת כתובות מייל",
          subject: "נושא המייל",
          from: "הכתובת שממנה יגיע המייל",
          body: "גוף המייל"
        },
        run_tasks_restriction: {
          title: "חבילת הסליקה של הארגון",
          none: 'הצגת דפי תקציב בלבד',
          charge: 'חיובים',
          income:'זיכויים',
          charge_n_income:'חיובים וזיכויים'
        },
        block_run_charge_flow: {
          active: "פעיל"
        }
      }
    },
    jobs: {
      list: {
        id: "מזהה",
        type: 'סוג',
        status: 'סטטוס',
        scope: 'היקף',
        input_data: 'ארגון',
        created_at: "תאריך יצירה",
      }
    },
    organizations: {
      list: {
        org_name: "שם הארגון",
        display_name: "שם תצוגה",
        created_at: "תאריך יצירה",
        systems: "מערכות",
        active: "פעיל",
        org_id: "מזהה ארגון",
        sys_id: "מזהה מערכת"
      }
    },
    corporations: {
      list: {
        corporation_name: {
          he: "שם תאגיד - עברית",
          en: "שם תאגיד - אנגלית"
        }
      }
    },
    financeSystems: {
      list: {
        system_name: "שם מערכת",
        display_name: "שם תצוגה",
        created_at: "תאריך יצירה",
        active: "פעיל",
        sys_id: "מזהה מערכת"
      }
    },
    pos: {
      name: 'שם',
      name_he: 'שם בעברית',
      name_en: 'שם באנגלית',
      system_name: "שם מערכת",
      system: "מערכת",
      password: 'סיסמה',
      username: 'שם משתמש',
      fetch_by_third_party: 'משיכת נתונים ע״י צד שלישי',
      external_org_id:'קוד ארגון חיצוני',
      external_pos_id:'קוד pos חיצוני',
      created_success_message: 'נקודת מכירה נוצרה בהצלחה',
      created_failed_message: 'ארעה תקלה ביצירת נקודת מכירה',
      create_title:'יצירת נקודת מכירה',
      edit_title:'עריכת נקודת מכירה',
      edited_success_message: 'נקודת מכירה עודכנה בהצלחה',
      edited_failed_message: 'ארעה תקלה בעדכון נקודת מכירה',
      create_mock: 'יצירת MOCK',
      check_connection: 'בדוק את החיבור',
      exists_connection: 'יכול להתחבר - ',
      exists_path: 'נתיב קיים - ',
    },
    sourceAttachments: {
      name: 'שם',
      type: 'סוג',
      created_success_message: 'הוספת מקור עבור קבצי תקציב עברה בהצלחה',
      created_failed_message: 'ארעה תקלה ביצירת מקור לקבצי תקציב',
      edited_success_message: 'עדכון מקור עבור קבצי תקציב עבר בהצלחה',
      edited_failed_message: 'ארעה תקלה בעדכון מקור קבצי תקציב',
      create_title: 'יצירת מקור לקבצי תקציב',
      edit_title: 'עריכת מקור לקבצי תקציב',
      name_he: 'שם בעברית',
      name_en: 'שם באנגלית',
      entity_type: {
        family: "בית אב",
        user: 'חבר'
      },
      entity_type_title: 'שיוך',
      file_name_pattern: 'תבנית שם הקובץ',
      delimiter: 'תו מפריד',
      month: 'חודש',
      year: 'שנה',
      order: 'מיקום',
      source_entity_id: 'מספר מזהה של המשתמש',
      source_settings:'הגדרות מקור',
      status: {
        active: 'פעיל',
        'not active': 'לא פעיל'
      },
      status_title: 'סטטוס',
      emails: 'כתובות מייל',
      phones: 'מספרי טלפון',
      email: 'כתובת מייל',
      phone: 'מספר טלפון',
      ftp_details: 'פרטי שרת ftp',
      user_name: 'שם משתמש',
      password: 'סיסמה',
      host: 'שרת',
      regex: 'ביטוי רגולרי לשם הקובץ'
    },
    tasks: {
      delete_finance_explanation: "מחיקת נתונים פיננסים עבור תקופה",
      choose_year_month: "בחר חודש ושנה",
      choose_date: "בחר תאריך",
      danger: "שימו לב - פעולה זו בלתי הפיכה!",
      masav_transmitted: "סמן קבצי מס״ב חיובים כנשלחו",
      masav_transmitted_explanation: "סימון קובצי מס״ב עבור תקופה מסויימת כנשלחו",
      approve_charges_explanation: "סימון מסמכי תקציב עבור תקופה מסויימת כמאושרים",
      unregister_members: "ביטול רישום",
      unregister_members_explanation: "ביטול רישום למערכת הסליקה של משתמשים",
      choose_members: "בחר משתמשים",
      member_tz: "תעודת זהות",
      reload_microcopy: "טעינת שרות התרגומים מחדש",
      reload_microcopy_explanation: "טעינה מחדש של שרות התרגומים כאשר יש תרגום חדש",
      reload_microcopy_explanation_general: "לחיצה על אישור תטען את התרגומים עבור כלל הארגונים",
      recreate_bank_authorizations: "הרצת בקשת הרשאות מחדש",
      recreate_bank_authorizations_general: "הרצה זו תשלח מחדש את הבקשות של כל הארגון לטובת הרשאות מהבנק",
      masav_income_transmitted: `סמן קבצי מס"ב זיכויים כנשלחו`,
      masav_income_transmitted_explanation: "סימון קובצי מס״ב עבור תקופה מסויימת כנשלחו",
    },

    reports: {
      charges_amounts: "דוח חיובים",
      charges_amounts_explanation: "דוח חיובים לפי תקופה ומחייב ",
      payments_by_month: "דוח תשלומים לפי תקופה",
      payments_by_month_explanation: "דוח תשלומים לפי תקופה ומחייב ",
      banks_orders_pdfs: "הורדת הוראות חיוב",
      banks_orders_pdfs_explanation: "הורדת הוראות חיוב לפי תאריך",
      banks_orders_pending: "דוח הוראות חיוב ממתינות",
      banks_orders_pending_explanation: "דוח הוראות חיוב שלא קיבלו מענה (אושרו/נדחו) לאחר מס ימי עסקים ",
      banks_orders_rejected: 'דוח הוראות לחיוב שנדחו',
      banks_orders_rejected_explanation: 'דוח המציג נתוני הוראות לחיוב שנדחו',
      banks_orders_general: 'דוח הוראות לחיוב כללי',
      banks_orders_general_explanation: 'דוח המציג כל נתוני הוראות לחיוב',
      bank_charges_rejected: 'דוח חיובים שנידחו',
      bank_charges_rejected_explanation: 'פירוט החזרות ממס״ב על חיובים שנדחו',
      info_message: "הקובץ בהכנה, יחל בהורדה אוטומטית ברגע שיהיה מוכן",
      download_masav_charges: 'הורדת קבצי מס״ב לחיובים',
      download_masav_charges_explanation: 'הורדת קבצי מס״ב לחיובים לפי תקופה',
      download_masav_incomes: 'הורדת קבצי מס״ב לזיכויים',
      download_masav_incomes_explanation: 'הורדת קבצי מס״ב לזיכויים לפי תקופה',
      files_not_found: 'לא קיימים קבצים להורדה',
      columnTitle: {
        entity: "מס׳ יישות ",
        amount: "סכום לחיוב",
        chargee: "קוד מוסד",
        customerName: "שם לקוח",
        chargeeName: "שם מחייב",
        bank_code: "מס׳ בנק",
        branch_code: "מס׳ סניף",
        account_number: "מס׳ חשבון",
        first_name: "שם פרטי",
        last_name: "שם משפחה",
        teudat_zehut: "מס' זהות",
        mobile: "מס׳ טלפון",
        registration_date: "תאריך רישום",
        exported_date: "תאריך הנפקה",
        result: "מהות הרשומה",
        rejection_reason: "סיבת דחייה",
        mosad_code: "קוד מוסד מחייב",
        asmachta: "מספר מזהה ללקוח במוסד",
        date_of_mosad_charge_for_rejection: "תאריך חיוב המוסד בהחזרה",
        value_of_mosad_charge_for_rejection: "תאריך ערך חיוב המוסד בהחזרה",
        mosad_bank_code: "קוד בנק הלקוח",
        mosad_branch_code: "מספר סניף הלקוח",
        mosad_account_number: "מספר חשבון הלקוח",
        customer_name: "שם הלקוח",
        charge_amount: "סכום חיוב",
        rejection_commission_amount: "סכום חיוב בעמלת החזרה בגין ההחזרה",
        rejection_reason_code: "קוד סיבת החזרה",
        rejection_reason_text: "תאור סיבת החזרה",
        bank_approval_date: "תאריך פתיחת ההרשאה בבנק",
        chargingSum: "סכום",
        orgCode: "קוד ארגון",
        orgNameHe: "שם ארגון עברית",
        orgNameEn: "שם ארגון אנגלית",
        year: "שנה",
        month: "חודש",
        corpCode: "קוד תאגיד",
        corpNameEn: "שם תאגיד אנגלית",
        corpNameHe: "שם תאגיד עברית",
        debit: "חיוב (מקורי)",
        credit: "אשראי (מקורי)",
        debitDiv: "חיוב (מחולק)",
        creditDiv: "אשראי (מחולק)",
      },

      isAllDates: "כל התאריכים",
      date_from: "מתאריך",
      date_to: "עד תאריך",
      choose_date: "בחר חודש ושנה",
      no_data: "אין נתונים",
      success: "הורד בהצלחה",
      payments_by_month_success: "דוח תשלומים לפי תקופה מוכן ויורד כעת",
      payments_by_month_info_message: "הדוח בהכנה, נודיע לך בסיום התהליך"
    },
    roles: {
      name: "שם התפקיד",
      description: "תאור התפקיד",
      code: "שדה מזהה"
    },
    general: {
      error: "שגיאה בהבאת הנתונים",
      success: "עודכן בהצלחה",
      delete_success: "ההגדרה נמחקה בהצלחה",
      updated: "רשומות עודכנו בהצלחה",
      approve: "מסכים",
      decline: "בטל",
      create_success: 'נוצר בהצלחה',
      create_failed: 'ארעה שגיאה בעת היצירה',
      not_supported: 'לא נתמך'
    },
    requestResult: {
      error: "ארעה שגיאה בתהליך הבקשה",
      success: "בקשתך התקבלה בהצלחה"
    },
    scheduleTasks: {
      payments_fetch: 'משיכת נתונים ממערכת הנהלת חשבונות',
      payments_entities: 'העתקת הנתונים שהתקבלו ממערכת הנהלת חשבונות לתקציבים',
      charge_save: 'שמירת מסמכי חיוב',
      charge_jobs: 'יצירת חיובים בכרטיס אשראי',
      masav_gather_charges: 'יצירת מסמכי מסב לחיובים',
      masav_attachment_charges: 'יצירת קבצים בינאריים עבור מסב לחיובים',
      bank_order_entity_attachments: 'יצירת מסמכי הרשאות לחיוב לפי בנק',
      masav_gather_incomes: 'יצירת מסמכי מסב לזיכויים',
      masav_attachment_incomes: 'יצירת קבצים בינאריים עבור מסב לזיכויים',
      charge_receipts: 'שליחת אישור ביצוע חיוב/זיכוי למערכת הנהלת חשבונות',
      send_budget: 'שליחת תקציב',
      bank_order_results_fetch: 'משיכת קבצי תוצאות של בקשות להוראות קבע',
      bank_order_results_parse: 'פענוח קבצי תוצאות של בקשות להוראות קבע',
      bank_order_results_update: 'עדכון סטטוס בקשות להוראות קבע על פי התוצאה שהתקבלה',
      bank_order_pdfs_to_proxy: 'שליחת מסמכי הרשאות (pdfs)',
      bank_order_csv_to_proxy: 'שליחת קובץ פירוט מסמכי הרשאות (csv)',
      bank_charge_rejections_fetch: 'הבאת קובץ החזרות',
      bank_charge_rejections_parse: 'פענוח קובץ החזרות',
      bank_charge_rejections_update: 'עדכון סטטוס חיוב',
      bank_charge_rejections_policy_change: 'שינוי שיטת חיוב לידנית',
      masav_chargee_mosad_codes: 'מיפוי קודי מוסד לפי ארגון',
      income_save: 'שמירת מסמכי זיכוי',
      pos_fetch: 'משיכת נתונים מנקודות מכירה'
    },
    scheduleFlows: {
      charge_flow: 'ביצוע חיובים',
      banks_orders_flow: 'תהליך שליחת הרשאות לחיוב בנקים ',
      bank_charge_rejections_flow: 'תהליך קליטת החזרות חיוב ממס"ב',
      banks_order_results_flow: 'תהליך קליטת תוצאות הרשאות לחיוב',
      credit_flow: 'ביצוע זיכויים',
      credit_process: 'תהליך זיכויים',
      charge_process: 'תהליך חיובים',

    },
    isRunManualForAllOrgs: 'הרצה עבור כל הארגונים הפעילים',
    filters: {
      and: 'הראה משתמשים שכוללים את כל הפילטרים שנבחרו',
      not_in: 'הראה משתמשים שלא כוללים אף אחד מהפילטרים שנבחרו',
      or: 'הראה משתמשים שכוללים לפחות אחד מהפילטרים שנבחרו'
    }
  }
}

