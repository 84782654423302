import { inject, observer } from "mobx-react"
import React from 'react'
import styled from "styled-components"
import { useTranslate } from "react-admin"
import { FLOW } from 'clearing-types';
import strings from '../../tools/strings'
import FlowItem from '../slika_processes/common/flowItem'
import ReportItem from '../slika_processes/common/reportItem'
import Typography from '@material-ui/core/Typography'
import ArchiveIcon from '@material-ui/icons/Archive';

const StyledTitle = styled(Typography)`
  font-weight: 900;
  padding:15px;
`
const FormContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`

const ChargePermissions = ({ userStore, tasksStore, onMenuClick, ...props }) => {
    const translate = useTranslate()

    return (
        <FormContainer>
            <StyledTitle >{translate('customRoot.myMenu.charge_permissions')}</StyledTitle>
            <FlowItem flowName={FLOW.BANKS_ORDER_RESULTS_FLOW} flowIndex={1} />
            <ReportItem icon={<ArchiveIcon />} reportType={strings.reports.BANKS_ORDERS_PDFS} />
        </FormContainer>
    )
}

export default inject('userStore', 'tasksStore')(observer(ChargePermissions))
