import { stringify as qsStringify } from "query-string"

export const ENDPOINTS = {
  microcopy: {
    reload: `/microcopy/reload`,
  },
  user: {
    get: memberId => `/users/api/v1/members/${memberId}`,
    sendMessage: (org, lang) => `/messages/api/v1/messages/${org}/${lang}`,
    budget: (memberId, org, year, month) => `/users/api/v1/payments/${org}/${memberId}/[${year},${month}]`,
    userDetails: (org, memberId) => `/users/api/v1/members/details/${org}/${memberId}`,
    paymentDetails: (memberId, org) => `/users/api/v1/members/payments/${org}/${memberId}`,
    tagsAndFilters: (org, lang) => `/users/api/v1/labels/${org}/${lang}`,
    initApp: (memberId) => `/users/api/v1/init_app/bo/${memberId}`
  },
  schedule: {
    runFlow: (flow) => `/organizations/api/v1/flows/${flow}`,
    runOrgTask: (task, org) => `/organizations/api/v1/tasks/${task}/org/${org}`,
    runSystemTask: (task) => `/organizations/api/v1/tasks/system/${task}`,
    runSystemFlow: (flow) => `/organizations/api/v1/flows/${flow}/system`,
    getProcesses: `/organizations/api/v1/processes`
  },
  org: {
    getAll: '/organizations/api/v1/organizations',
    data: "/organizations/api/v1/definitions",
    globalDefinitions: `/organizations/api/v1/global_definitions/`,
    getOrgDefinitions: (org, query) => `/organizations/api/v1/definitions/${org}?${qsStringify(query)}`,
    getSingleDef: (org, query) => `/organizations/api/v1/definitions/${org}/1?${qsStringify(query)}`,
    delSingleDef: (org) => `/organizations/api/v1/definitions/${org}`,
    masavTransmitted: `/organizations/api/v1/masav_transmitted`,
    masavIncomesTransmitted: `/organizations/api/v1/masav_income_transmitted`,
    masavCharges: `/organizations/api/v1/masav_charges`,
    masavIncomes: `/organizations/api/v1/masav_incomes`,
    chargesAmounts: "/organizations/api/v1/charges/amounts",
    paymentsByMonth: (org, year, month) =>  `/organizations/api/v1/payment_report/${org}/[${year},${month}]`,
    getBankChargesRejected: (org, from, to) => `/organizations/api/v1/bank_charges/rejections/${org}${(from && to) ? `?from_date=${from}&to_date=${to}` : ``}`,
    roles: "/mekomi/api/v1/roles",
    allUsers: (org) => `/users/api/v1/stats/users/${org}`,
    setEntitiesRegistrationStatus: (org, status) => `/users/api/v1/entities/registrations/${org}/${status}`,
    registeredEntities: (org, full_details) => `/users/api/v1/entities/registered/${org}${full_details ? `?full_details=true` : ``}`,
    setBankOrderStatus: (org) => `/organizations/api/v1/banks_orders/status/${org}`,
    generateBanksOrdersPdfs: (org, fromDate, toDate) => `/users/api/v1/pdf_files/${org}${(fromDate && toDate) ? `?from_date=${fromDate}&to_date=${toDate}` : ``}`,
    getOrgInstitution: (org) => `​/organizations​/api​/v1​/${org}​/financial_structures​/institutions`,
    getMasavChargesFiles: (org, year, month) => `/organizations/api/v1/masav_charges_files/${org}/[${year},${month}]`,
    getMasavIncomesFiles: (org, year, month) => `/organizations/api/v1/masav_incomes_files/${org}/[${year},${month}]`,
    events: `/organizations/api/v1/events`,
    getAllFinancialBooks: (org) => `/organizations/api/v1/${org}/financial_structures/financial_books`

  },
  finance: {
    getPosSystems: '/finance/api/v1/pos_systems',
    createMock: (org) => `/finance/api/v1/mock/pos/${org}`,
    checkConnection: `/finance/api/v1/storage/connection/check`,
  },
}

export const DOMAIN = process.env.REACT_APP_API || '__RUNTIME_API__'

