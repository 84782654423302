// in src/Dashboard.js
import React, { useEffect } from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Title, useTranslate } from "react-admin"
import { observer, inject } from "mobx-react"
import styled from "styled-components"
import PieChart from "./pieChart"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: left;
  margin-top: 50px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
  margin-top: 20px;
`

const Child = styled.div`
  width: 300px;
  margin: 10px;
`
const Content = styled.div`
  font-size: 24px;
  text-align: center;
`

const Dashboard = ({ userStore, ...props }) => {
  const translate = useTranslate()
  useEffect(() => {
    async function fetchData() {
      await userStore.getAllUsers()
    }
    if(userStore.org){
      fetchData()
    }
  }, [userStore, userStore.org])
  if(!userStore.org){
    return null;
  }
  return (
    <Container>
      <Card>
        <Title title='customRoot.dashboard.title' />
        <CardContent>{translate("customRoot.dashboard.title")}</CardContent>
      </Card>
      {userStore.allUsers && (
        <Row>
          <Column>
            <Child>
              <Card>
                <CardContent>
                  <Content>
                    {translate("customRoot.dashboard.numberOfUsers")}: {userStore.numberOfUsers}
                  </Content>
                </CardContent>
              </Card>
            </Child>
            <Row>
              <Child>
                <Card>
                  <Content>
                    <CardContent>{translate("customRoot.dashboard.usersRegTitle")}</CardContent>
                  </Content>
                </Card>
                <PieChart data={userStore.pieChartRegisteredUsers} />
              </Child>
              <Child>
                <Card>
                  <Content>
                    <CardContent>{translate("customRoot.dashboard.paymentMethods")}</CardContent>
                  </Content>
                </Card>
                <PieChart data={userStore.pieChartPaymentMethodUsers} />
              </Child>
            </Row>
          </Column>
        </Row>
      )}
    </Container>
  )
}

export default inject("userStore")(observer(Dashboard))
