import React from 'react'
import {DeleteButton, Edit, SaveButton, Toolbar, useNotify, useRedirect, useTranslate} from 'react-admin'
import {inject, observer} from 'mobx-react'
import SourceAttachmentsForm from './sourceAttachmentsForm'
import {Button, withStyles} from '@material-ui/core'
import styled from "styled-components"

const StyledCancelButton = styled(Button)`
  margin-left: 40px
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: "center",
  }
}

const SourceAttachmentsToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/source_attachments');
  }
  return (
    <Toolbar {...props} >
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  )
})

const SourceAttachmentsEdit = ({ userStore, ...props }) => {

  const translate = useTranslate()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = ({ data }) => {
    notify(translate('customRoot.sourceAttachments.edited_success_message'), { type: 'success' })
    redirect('/source_attachments');
  }

  const onFailure = (error) => {
    notify(translate('customRoot.sourceAttachments.edited_failed_message'), { type: 'warning' })
    redirect('/source_attachments');
  }
  return (
    <Edit {...props} undoable={false} onSuccess={onSuccess} onFailure={onFailure} title={translate('customRoot.sourceAttachments.edit_title')}>
      <SourceAttachmentsForm redirect="list" toolbar={<SourceAttachmentsToolbar />} sourceId={props.id}/>
    </Edit>
  )
}
export default inject("userStore")(observer(SourceAttachmentsEdit))
