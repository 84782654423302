import { inject, observer } from "mobx-react"
import React from 'react'
import styled from "styled-components"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Button from "@material-ui/core/Button"
import { useTranslate, useNotify, MenuItemLink } from "react-admin"
import { TASK, FLOW } from 'clearing-types';
import { PeriodDialog } from '../common/period_dialog'
import strings from '../../tools/strings'
import FlowItem from './common/flowItem'
import ReportItem from './common/reportItem'
import SendIcon from '@mui/icons-material/Send';
import Typography from '@material-ui/core/Typography'
import ArchiveIcon from '@material-ui/icons/Archive';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Badge from '@mui/material/Badge';

const StyledTitle = styled(Typography)`
  font-weight: 900;
  padding:15px;
`
const FormContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`
const StyledButton = styled(Button)`
 margin-bottom: -7px;
 margin-top: -7px;
  margin-right: 7px;
  color: grey;
`

const SlikaProcesses = ({ userStore, tasksStore, onMenuClick, ...props }) => {
    const translate = useTranslate()
    const notify = useNotify()

    const openPeriodDialog = (taskName) => {
        const task = tasksStore.tasksList.find(task => task.name === taskName)
        tasksStore.setProcessSelected(task)
        tasksStore.setOpenPeriodDialog(true)
    }

    return (
        <FormContainer>
            <StyledTitle >{translate('customRoot.myMenu.slika_processes')}</StyledTitle>
            <StyledButton onClick={() => openPeriodDialog(TASK.PAYMENTS_FETCH)}>
                <ArchiveIcon />
                <ListItem ><ListItemText primary={translate(`customRoot.scheduleTasks.payments_fetch`)} /></ListItem>
            </StyledButton>
            <MenuItemLink to='/credit_process' primaryText={<Badge>{translate(`customRoot.scheduleFlows.credit_process`)}<ArrowBackIosIcon /></Badge>} leftIcon={<AttachMoneyIcon />} onClick={onMenuClick} />
            <MenuItemLink to='/charge_process' primaryText={<Badge>{translate(`customRoot.scheduleFlows.charge_process`)}<ArrowBackIosIcon /></Badge>} leftIcon={<AttachMoneyIcon />} onClick={onMenuClick} />
            <StyledButton onClick={() => openPeriodDialog(TASK.CHARGE_RECEIPTS)}>
                <SendIcon />
                <ListItem><ListItemText primary={translate(`customRoot.scheduleTasks.charge_receipts`)} /></ListItem>
            </StyledButton>
            <FlowItem flowName={FLOW.BANK_CHARGE_REJECTIONS_FLOW} flowIndex={2} />
            <ReportItem icon={<AssessmentIcon />} reportType={strings.reports.BANK_CHARGES_REJECTED} />
            <PeriodDialog
                open={tasksStore.isOpenPeriodDialog}
                setOpenDialog={() => tasksStore.setOpenPeriodDialog(!tasksStore.isOpenPeriodDialog)}
                setPeriod={(date) => tasksStore.setPeriod(date)}
                confirmDialog={() => tasksStore.sendRequest(notify)}
                dialogTitle={tasksStore.getDialogTitle(translate)}
                format={tasksStore.processSelected.periodFormat}
                defaultPeriod={tasksStore.processSelected.defaultPeriod}
            />
        </FormContainer>
    )
}

export default inject('userStore', 'tasksStore')(observer(SlikaProcesses))
