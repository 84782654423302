import React, {
    useRef,
    useEffect,
    useMemo,
} from 'react';
import { observer, inject } from 'mobx-react'
import classnames from 'classnames';
import {
    Card,
    Avatar,
    createTheme,
    makeStyles,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from 'react-router-dom';
import { useCheckAuth, useTranslate, useSafeSetState } from 'ra-core';
import DefaultLoginForm from './LoginForm';
import TwoFactor from './2FA'
import defaultTheme from '../../core/defaultTheme';
import Notification from '../../core/Notification';
import styled from "styled-components"
import {getEnvThemeColor} from "../../tools/tools";

const CookiesError = styled.div`
    color: red;
    font-size: 20px;
    margin: 20px;
`

const useStyles = makeStyles(
    theme => {
        const envThemeColor = getEnvThemeColor();
        return {
            main: {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                height: '1px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage:
                  `radial-gradient(circle at 50% 14em, ${envThemeColor.login.main} 0%, ${envThemeColor.login.secondary} 80%, ${envThemeColor.login.secondary} 100%)`,
            },
            card: {
                minWidth: 300,
                marginTop: '6em',
            },
            avatar: {
                margin: '1em',
                display: 'flex',
                justifyContent: 'center',
            },
            icon: {
                backgroundColor: theme.palette.secondary[500],
            },
        }
    },
    { name: 'RaLogin' }
);

const Login = ({ userStore, ...props }) => {
    let {
        theme,
        classes: classesOverride,
        className,
        children,
        staticContext,
        backgroundImage,
        ...rest
    } = props;
    children = userStore.memberId ? <TwoFactor /> : <DefaultLoginForm />
    const containerRef = useRef();
    const [cookieError, setCookieError] = useSafeSetState(false)
    const translate = useTranslate()
    const classes = useStyles(props);
    const muiTheme = useMemo(() => createTheme(theme), [theme]);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(err => {
                if (err && err.error === 'cookie') {
                    setCookieError(true)
                    return
                }
                userStore.setAuth(false)
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history, userStore, setCookieError]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest}
                ref={containerRef}
            >
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    {!cookieError && children}
                    {cookieError && (
                        <CookiesError>
                            {translate('ra.auth.cookiesError')}
                        </CookiesError>
                    )}
                </Card>
                <Notification />
            </div>
        </ThemeProvider>
    );
};
Login.defaultProps = {
    theme: defaultTheme,
    children: <DefaultLoginForm />,
};
export default inject('userStore')(observer(Login))
