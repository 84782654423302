import React from "react"
import { EditButton, useTranslate, useRedirect, Button } from "react-admin"
import styled from "styled-components"
import { observer, inject } from "mobx-react"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Add } from '@material-ui/icons'

const StyledTitle = styled.div`
    padding:15px;
    font-weight:bold;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FinancialBookItem = ({ financialBook }) => {
  const redirect = useRedirect();
  const code = financialBook.data.code
  const onEdit = () => {
    if (code) {
      redirect(`/financialBook/${code}`);
    }
  }
  let name = { he: "", en: "" }
  if (financialBook) {
    name = financialBook.data.name
  }
  return (
    <TableRow key={code}>
      <TableCell align="left">{name.he}</TableCell>
      <TableCell align="left">{name.en}</TableCell>
      <TableCell align="center"><EditButton onClick={onEdit} label="" /></TableCell>
    </TableRow>
  )
}

const FinancialBooksHeader = () => {
  const translate = useTranslate()
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.financial_book.name.he')}</b></TableCell>
        <TableCell dir='ltr' align="left"><b>{translate('customRoot.definitions.edit.financial_book.name.en')}</b></TableCell>
        <TableCell align="center"><b>{translate('ra.action.edit')}</b></TableCell>
      </TableRow>
    </TableHead>
  )
}

const FinancialBooksList = ({ list }) => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const onCreate = () => {
    redirect('/financialBook/create');
  }
  return (
    <>
      <StyledTitle>{translate('customRoot.definitions.edit.financial_book.financial_books')}</StyledTitle>
      <ButtonsContainer>
        <Button onClick={onCreate}><Add /></Button>
      </ButtonsContainer>
      <Table aria-label="simple table">
        <FinancialBooksHeader />
        <TableBody>
          {list.map(financialBook =>
          (
            <FinancialBookItem financialBook={financialBook} key={financialBook.data.code} />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default inject("corporationStore")(observer(FinancialBooksList))
