import { inject, observer } from "mobx-react"
import React from 'react'
import Button from "@material-ui/core/Button"
import { useTranslate, useNotify } from "react-admin"
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { makeStyles } from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import strings from '../../../tools/strings'
import { PeriodDialog } from '../../common/period_dialog'

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    grid: {
        display: 'flex',
        marginTop: '30px',
        marginBottom: '-25px'

    },
    button: {
        marginBottom: '5px',
        marginTop: '-22px',
    }
})
const FlowItem = ({ flowName, flowIndex, tasksStore, ...props }) => {
    const translate = useTranslate()
    const notify = useNotify()
    const classes = useStyles()
    const getFlow = () => {
        return tasksStore.flowsList.find(flow => flow.name === flowName)
    }
    const flow = getFlow()

    const isDisabledButton = () => {
        const processChecked = tasksStore.getProcessChecked()
        if (!processChecked) return
        if (processChecked.processType === strings.processType.flow) {
            return tasksStore.getProcessChecked().name === flowName
        }
        else if (processChecked.processType === strings.processType.task) {
            const taskChecked = flow.tasks.find(task => task.name === processChecked.name)
            return taskChecked !== undefined
        }
    }

    const handleRunBtnClicked = () => {
        tasksStore.setProcessSelected(flow)
        tasksStore.setOpenPeriodDialog(true)
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.grid}>
                <Grid item xs={2} md={6} >
                    <Button className={classes.button} onClick={handleRunBtnClicked} variant="contained" disabled={!isDisabledButton()}><PlayArrowIcon /></Button>
                    {!!flow && <Accordion key={flow.key}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label={translate(`customRoot.scheduleFlows.${flow.name}`)}
                                checked={flow.isChecked}
                                onChange={(event) => tasksStore.setFlowChange(flowIndex, event.target.checked)}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography color="textSecondary">
                                {flow.tasks.map((task, taskIndex) => {
                                    return <FormControlLabel
                                        control={<Checkbox />}
                                        label={translate(`customRoot.scheduleTasks.${task.name}`)}
                                        checked={task.isChecked}
                                        onChange={(event) => tasksStore.setTaskChange(flowIndex, taskIndex, event.target.checked)}
                                        key={task.key}
                                        disabled={flow.isChecked}
                                    />
                                })}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>}
                </Grid>
            </Grid>
            <PeriodDialog
                open={tasksStore.isOpenPeriodDialog}
                setOpenDialog={() => tasksStore.setOpenPeriodDialog(!tasksStore.isOpenPeriodDialog)}
                setPeriod={(date) => tasksStore.setPeriod(date)}
                confirmDialog={() => tasksStore.sendRequest(notify)}
                dialogTitle={tasksStore.getDialogTitle(translate)}
                format={tasksStore.getProcessChecked()?.periodFormat}
                defaultPeriod={tasksStore.getProcessChecked()?.defaultPeriod}
            />
        </div>
    )

}
export default inject('tasksStore')(observer(FlowItem))