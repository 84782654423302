import React from "react"
import { observer, inject } from "mobx-react"
import { Field, Form } from "react-final-form"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslate, useNotify, useSafeSetState } from "ra-core"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import strings from "tools/strings"
import { postMessage } from 'tools/tools'

const useStyles = makeStyles(
  theme => ({
    form: { padding: "0 1em 1em 1em" },
    input: { marginTop: "1em" },
    button: { width: "100%" },
    icon: { marginRight: theme.spacing(1) }
  }),
  { name: "RaLoginForm" }
)

const FormContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 20px;
`

const PhoneNumber = styled.div`
`

const PhoneSpan = styled.span`
  unicode-bidi:embed; 
  direction:ltr;
`

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />


const TwoFactor = ({ userStore, ...props }) => {
  const history = useHistory()

  const [loading, setLoading] = useSafeSetState(false)
  const [code, setCode] = useSafeSetState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles(props)
  const recipient = userStore.recipient
  const validate = values => {
    const errors = { smsCode: undefined }
    if (!values.smsCode) {
      errors.smsCode = translate("ra.validation.required")
    } else if (values.smsCode) setCode(values.smsCode)
    return errors
  }

  const submit = async () => {
    try {
      setLoading(true)
      const verifyRes = await userStore.verifyOtp(code)
      setLoading(false)
      if (!verifyRes.success) {
        throw new Error(verifyRes.error?.detail)
      }
      postMessage(strings.WINDOW_POST_MESSAGE_TYPE.SET_ACTIVE_USER, userStore.memberId)
      const initRes = await userStore.initApp()
      if (!initRes.success) {
        if (initRes.description) {
          throw new Error(initRes.description)
        }
        else {
          throw new Error(initRes.error?.detail)
        }
      }
      userStore.setAuth(true)
      history.push("/")
    } catch (err) {
      setLoading(false)
      if (err.message === strings.error.NO_PERMISSIONS) {
        notify(translate("ra.auth.no_permissions"), "warning")
      } else {
        notify(translate("ra.auth.sign_in_error"), "warning")
      }
    }
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={{ channel: "sms" }}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <FormContainer>
                <PhoneNumber>
                  {translate("ra.auth.twoFactorMessageMobile")}
                  <PhoneSpan>
                    {translate("ra.auth.twoFactorField", { field: recipient })}
                  </PhoneSpan>
                </PhoneNumber>
                <Field type='password' autoFocus id='smsCode' name='smsCode' component={Input} label={translate("ra.auth.smsCode")} disabled={loading} />
              </FormContainer>
            </div>
          </div>
          <CardActions>
            <Button variant='contained' type='button' color='primary' disabled={loading} className={classes.button} onClick={submit}>
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate("ra.auth.sign_in")}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
}

export default inject("userStore")(observer(TwoFactor))
