import React from "react"
import { Edit, SimpleForm, TextInput, BooleanInput, SaveButton, Toolbar, useRedirect, useTranslate } from "react-admin"
import { withStyles, Button } from '@material-ui/core'
import styled from "styled-components"
const StyledCancelButton = styled(Button)`
  margin-left: 40px;
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: "center",
  }
}
const OrgEditToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/organizations');
  }
  return (
    <Toolbar {...props} >
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
      <SaveButton />
    </Toolbar>
  );
})
const OrgEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <SimpleForm redirect="list" toolbar={<OrgEditToolbar />}>
        <TextInput source='name' label='customRoot.organizations.list.org_name' />
        <BooleanInput label="customRoot.organizations.list.active" source="status" />
      </SimpleForm>
    </Edit>
  )
}
export default OrgEdit
