import React, { useState } from "react"
import { observer, inject } from "mobx-react"
import { useTranslate, useNotify } from "react-admin"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import ReportsFactory from "../../reports/reportsFactory"
import { downloadCSV } from 'react-admin';
import styled from "styled-components"
import { ENDPOINTS, DOMAIN } from 'fetch/constants'
import strings from '../../../tools/strings'


const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 50px;
`

const ReportItem = ({ reportType, icon, userStore }) => {
    const [open, setOpen] = useState(false)
    const [showAllOrgsSetting, setShowAllOrgsSetting] = useState(false)
    const notify = useNotify()

    const translate = useTranslate()

    function arrayToCSV(data) {
        const csv = data.map(row => Object.values(row));
        csv.unshift(Object.keys(data[0]).map(key => translate(`customRoot.reports.columnTitle.${key}`)));
        return `"${csv.join('"\n"').replace(/,/g, '","')}"`;
    }

    const generateCSV = async (type, getDataFunc) => {
        const fileName = `${translate(`customRoot.reports.${type}`)}.csv`
        const data = await getDataFunc()

        if (data === false) {
            notify("customRoot.general.error", "warning")
            return
        }
        if (data?.length) {
            const csvData = arrayToCSV(data)
            downloadCSV(csvData, fileName)
            notify("customRoot.reports.success", "success")
        } else {
            notify("customRoot.reports.no_data", "success")
        }
    }
    const handleDownloadMasavFiles = async (reportType) => {
        let url
        if (reportType === strings.reports.DOWNLOAD_MASAV_CHARGES) {
            url = DOMAIN + ENDPOINTS.org.getMasavChargesFiles(
                userStore.org,
                userStore.masavChargesDate.getFullYear(),
                userStore.masavChargesDate.getMonth() + 1
            )
        }
        else if (reportType === strings.reports.DOWNLOAD_MASAV_INCOMES) {
            url = DOMAIN + ENDPOINTS.org.getMasavIncomesFiles(
                userStore.org,
                userStore.masavIncomesDate.getFullYear(),
                userStore.masavIncomesDate.getMonth() + 1
            )
        }
        const response = await userStore.getMasavFiles(url)
        if (response.status === 204) {
            notify("customRoot.reports.files_not_found", "warning")
        } else {
            notify("customRoot.reports.info_message", "success")
            var link = document.createElement('a')
            link.href = response.url
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
    const handleBanksOrdersPdfs = async () => {
        const response = await userStore.generateBanksOrdersPdfs()
        const { file, token, pullInterval, waitFor, success } = response
        if (!success) {
            notify("customRoot.general.error", "error")
            return
        }
        const url = `${process.env.REACT_APP_FILES_URL || `__RUNTIME_FILES__`}${token}`
        const timeout = Date.now() + waitFor * 1000
        const intervalId = setInterval(handleZipDownload, pullInterval * 1000, url, file)
        async function handleZipDownload(url, fileName) {
            const response = await fetch(url, {
                method: 'HEAD'
            })

            if (Date.now() > timeout || response.status === 401) {
                notify("customRoot.general.error", "error")
                clearInterval(intervalId)
                return
            }

            if (response.status === 204) {
                notify("customRoot.reports.no_data", "warning")
                clearInterval(intervalId)
                return
            }

            if (response.status === 200) {
                succeedZipDownload(url, intervalId, fileName)
            }
        }
    }

    async function succeedZipDownload(url, intervalId, fileName) {
        clearInterval(intervalId)

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/zip',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const blobUrl = window.URL.createObjectURL(
                    new Blob([blob]),
                )
                const link = document.createElement('a')
                link.href = blobUrl
                link.setAttribute(
                    'download',
                    fileName,
                )
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click()
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
    }
    const generateReport = async (reportType) => {
        switch (reportType) {
            case strings.reports.BANK_CHARGES_REJECTED:
                await generateCSV(reportType, userStore.getBankChargesRejected)
                setOpen(false)
                break
            case strings.reports.DOWNLOAD_MASAV_CHARGES:
            case strings.reports.DOWNLOAD_MASAV_INCOMES:
                await handleDownloadMasavFiles(reportType)
                setOpen(false)
                break
            case strings.reports.BANKS_ORDERS_PDFS:
                await handleBanksOrdersPdfs()
                notify("customRoot.reports.info_message", "success")
                setOpen(false)
                break
            default:
                setOpen(false)
                return
        }
    }

    const handleDate = (reportType) => {
        switch (reportType) {
            case strings.reports.BANK_CHARGES_REJECTED:
                return userStore.setBankChargesRejectedFilterDates
            case strings.reports.DOWNLOAD_MASAV_INCOMES:
                return userStore.setMasavIncomesDate
            case strings.reports.DOWNLOAD_MASAV_CHARGES:
                return userStore.setMasavChargesDate
            case strings.reports.BANKS_ORDERS_PDFS:
                return userStore.setBanksOrdersPdfsDate
            default:
                return () => { }
        }
    }
    return (
        <FormContainer>
            <Button onClick={() => setOpen(true)}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            {icon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={translate(`customRoot.reports.${reportType}`)} secondary={translate(`customRoot.reports.${reportType}_explanation`)} />
                </ListItem>
            </Button>
            <ReportsFactory showAllOrgsSetting={showAllOrgsSetting} open={open} setOpen={setOpen} type={reportType} generateReport={() => generateReport(reportType)} handleDateChangeFunc={handleDate(reportType)} />
        </FormContainer>
    )

}
export default inject("userStore")(observer(ReportItem))