import React from 'react';
import { observer, inject } from "mobx-react"
import { makeStyles } from "@material-ui/core/styles"
import ReplayIcon from '@material-ui/icons/Replay';
import Grid from "@material-ui/core/Grid"
import { useTranslate, useDataProvider } from "react-admin"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import PopAlertButton from '../utils/PopAlertButton'
import strings from '../../tools/strings'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  card: {
    margin: '5rem'
  },
  listBody: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}))

const ServiceWithButton = ({ serviceName }) => {
  const translate = useTranslate()
  const restProvider = useDataProvider()
  return (
    <List>
      <PopAlertButton action={() => {
        return restProvider(strings.RESTART_SERVICE, null, { serviceName })
      }}
        serviceName={serviceName}
        title={` ${translate('customRoot.services.alertTitle')} ${serviceName}`}
        content={translate('customRoot.services.alertDescription')}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              {<ReplayIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={serviceName} />
        </ListItem>
      </PopAlertButton>
    </List>
  )
}

const ServicesList = ({ userStore, ...props }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const killAvailable = strings.server !== strings.productionServer
  return (
    <Card className={classes.card}>
      <Title title={translate('customRoot.services.title')} />
      <CardContent>
        <div className={classes.title}>{translate('customRoot.services.title')}</div>
        {!killAvailable ? <div>{translate('customRoot.services.notAvailable')} </div>
          : <Grid container className={classes.listBody}>
            {
              _.map(strings.services, (service) => (
                <ServiceWithButton key={`${service}_restart_button`} serviceName={service} classes />
              ))
            }
          </Grid>
        }
      </CardContent>
    </Card>)
}
export default inject("userStore")(observer(ServicesList))