import React, { useState } from "react"
import { EditButton, useTranslate, useRedirect, Button } from "react-admin"
import styled from "styled-components"
import { FinancialEntityType } from 'clearing-types'
import { observer, inject } from "mobx-react"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TaskFactory from "../../slika_processes/common/taskFactory"
import strings from '../../../tools/strings'

const StyledTitle = styled.div`
    padding:15px;
    font-weight:bold;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const InstitutionsItem = ({ corporationStore, institution }) => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const code = institution.data.code
  const onEdit = () => {
    if (code) {
      redirect(`/institution/${code}`);
    }
  }
  const confirmDialog = () => {
    setOpen(true)
  }
  const addToRegistered = () => {
    setOpen(false)
    corporationStore.recreateBankAuthorizations(institution.data.institution_code)
  }
  const financialBooks = corporationStore.currentCorporation.relations.filter(r => r.sub_type ===  FinancialEntityType.FINANCIAL_BOOK)
  let isDisabled = true
  for(const financialBook of financialBooks){
      if(financialBook.relations.find(r => r.code === institution.data.code)){
        isDisabled = false
        break
      }
  }
  let name = { he: "", en: "" }
  let institution_code = ""
  let type = ""
  if (institution) {
    name = institution.data.name
    institution_code = institution.data.institution_code
    type = institution.sub_type === strings.INSTITUTION_DEBIT ? translate('customRoot.definitions.edit.institution.institution_debit') : institution.sub_type === strings.INSTITUTION_CREDIT ? translate('customRoot.definitions.edit.institution.institution_credit') : 'not valid!'
  }
  return (
    <TableRow key={institution_code}>
      <TableCell align="left">{name.he}</TableCell>
      <TableCell align="left">{name.en}</TableCell>
      <TableCell align="left">{institution_code}</TableCell>
      <TableCell align="left">{type}</TableCell>
      <TableCell align="left"><EditButton onClick={onEdit} label="" /></TableCell>
      {institution.sub_type !== strings.INSTITUTION_CREDIT && <TableCell align="left">
        <EditButton icon={<PlayArrowIcon />} disabled={isDisabled} onClick={confirmDialog} label="" />
      </TableCell>}
      <TaskFactory open={open} setOpen={setOpen} type={strings.tasks.ADD_TO_REGISTERED} executeTask={addToRegistered} />
    </TableRow>

  )
}

const InstitutionsHeader = () => {
  const translate = useTranslate()
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.institution.name.he')}</b></TableCell>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.institution.name.en')}</b></TableCell>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.institution.institution_code')}</b></TableCell>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.institution.type')}</b></TableCell>
        <TableCell align="center"><b>{translate('ra.action.edit')}</b></TableCell>
        <TableCell align="center"><b>{translate('ra.action.add_to_registered')}</b></TableCell>
      </TableRow>
    </TableHead>
  )
}

const InstitutionsList = ({corporationStore, list }) => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const onCreate = () => {
    redirect('/institution/create');
  }
  return (
    <>
      <StyledTitle>{translate('customRoot.definitions.edit.institution.institutions_credit_and_debit')}</StyledTitle>
      <ButtonsContainer>
        <Button onClick={onCreate} title={'Create institution'}><Add /></Button>
      </ButtonsContainer>
      <Table aria-label="simple table">
        <InstitutionsHeader />
        <TableBody>
          {list.map(institution =>  <InstitutionsItem corporationStore={corporationStore} institution={institution} key={institution.data.code} /> )}
        </TableBody>
      </Table>
    </>
  )
}

export default inject("corporationStore")(observer(InstitutionsList))
