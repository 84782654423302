import { expandN } from 'regex-to-strings'

const regexes = []

function register (regexString, source, community) {
  const regex = check(regexString, source, community, 1)
  const candidate = {
    community,
    source,
    regex
  }
  replace(source, community, candidate)
}

function unregister (source, community) {
  replace(source, community, null)
}

function replace (source, community, candidate) {
  const found = regexes.findIndex(candidate => candidate.source === source && candidate.community === community)
  if (candidate == null) {
    if (found !== -1) regexes.splice(found, 1)
  } else if (found !== -1) regexes[found] = candidate
  else regexes.push(candidate)
}

function reset () {
  regexes.length = 0
}

function toJson () {
  return regexes.map(candidate => {
    return {
      community: candidate.community,
      source: candidate.source,
      regex: candidate.regex.toString().slice(1, -1)
    }
  })
}

function fromJson (json) {
  reset()
  if (json == null) return
  json.forEach(candidate => register(candidate.regex, candidate.source, candidate.community))
}

function count () {
  return regexes.length
}

function check (regexString, source, community, samplesCount) {
  const regex = validateIsRegex(regexString)
  validateNonEvil(regexString)
  validateGroups(regexString)
  validateWithSamples(regex, source, community, samplesCount)
  return regex
}

function validateIsRegex (regexString) {
  if (regexString == null || regexString === '') throw new Error('Regex is empty')
  try {
    return new RegExp(regexString)
  } catch (e) {
    throw new Error(e.message)
  }
}

const groupRepetition = /(?<![^\\]\\)\)[+*{]/

function validateNonEvil (regexString) {
  if (regexString.match(groupRepetition)) throw new Error('Regex should not contain group repetition')
}

const allowedGroups = [
  'community',
  'communityname',
  'memberid',
  'entityid',
  'workerid',
  'source',
  'sourcename',
  'entity',
  'month',
  'monthname',
  'year',
  'name'
]

const namedGroups = /\(\?<([^>]+)>/g

function validateGroups (regexString) {
  const matches = Array.from(regexString.matchAll(namedGroups)).map(m => m[1])
  const unallowed = matches.filter(g => !allowedGroups.includes(g))
  if (unallowed.length) throw new Error('Regex contains unallowed groups: ' + unallowed.join(', '))
  const groups = {}
  matches.forEach(g => { groups[g] = true })
  checkGroups(groups)
}

function checkGroups (groups, sample) {
  const missing = []
  //if (!groups.community && !groups.communityName) missing.push('community or communityName')
  //if (!groups.source && !groups.sourceName) missing.push('source or sourceName')
  //if (!groups.entity) missing.push('entity')
  if (!groups.month && groups.month !== 0 && !groups.monthName) missing.push('month or monthName')
  if (!groups.year && groups.year !== 0) missing.push('year')
  if (missing.length) throw new Error('Regex is missing groups: ' + missing.join(', ') + (sample ? ` in '${sample}'` : ''))
}

function candidateToString (candidate) {
  return `${candidate.community}/${candidate.source}: ${candidate.regex.toString().replace(/\\\\/g, '\\')}`
}

function validateWithSamples (regex, source, community, samplesCount = 100) {
  const samples = generate(regex, samplesCount)
  if (samples.length === 0) throw new Error('Regex cannot match any input')
  const candidate = { regex, source, community }
  samples.forEach(sample => {
    const groups = parseOne(sample, candidate)
    checkGroups(groups, sample)
    const results = parse(sample)
    if (results.length > 1 || (results.length && (results[0].community !== community || results[0].source !== source))) throw new Error(`Regex matches more than one community/source using '${sample}':\n\t` + results.map(candidateToString).join('\n\t'))
  })
}

function generate (regex, samplesCount = 10) {
  const samples = expandN(regex, samplesCount)
  if (samples.length === 1 && samples[0] === '') return [] // workaround a bug in expandN
  return samples
}

function parseOne (name, { regex, source, community }) {
  const match = name.match(regex)
  if (!match) return null
  const groups = match.groups
  let year = parseInt(groups.year, 10)
  if (year < 100) {
    const now = new Date()
    year += now.getFullYear() - now.getFullYear() % 100
  }
  let month = parseMonth(groups.month)
  if (month == null) month = parseMonth(groups.monthName)
  return {
    community: community || groups.community || groups.communityName,
    source: source || groups.source || groups.sourceName,
    entity: groups.entity,
    month,
    year,
    name: groups.name,
    regex
  }
}

const months = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
  ינו: 1,
  פבר: 2,
  מרץ: 3,
  מרצ: 3,
  אפר: 4,
  מאי: 5,
  יונ: 6,
  יול: 7,
  אוג: 8,
  ספט: 9,
  אוק: 10,
  נוב: 11,
  דצמ: 12
}

function parseMonth (month) {
  if (month == null) return null
  month = month.trim()
  if (/^\d+$/.test(month)) return parseInt(month, 10)
  month = month.slice(0, 3).toLowerCase()
  return months[month]
}

function parse (name) {
  const results = []
  regexes.forEach(candidate => {
    const parsed = parseOne(name, candidate)
    if (parsed) results.push(parsed)
  })
  return results
}

export default { check, generate, register, unregister, reset, count, toJson, fromJson, parse }