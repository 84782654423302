import React, { useState } from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { TextInput, ArrayInput, SimpleFormIterator, useCreateController, useTranslate, NumberInput, BooleanInput, SelectInput, RadioButtonGroupInput, email, useRedirect } from "react-admin"
import Button from "@material-ui/core/Button"
import styled from "styled-components"
import { orgDefToArrayOfObjects } from "../../tools/tools"
import { makeStyles } from "@material-ui/core/styles"
import WarningDialog from "../utils/WarningDialog"
import { Restrictions } from 'clearing-types'
import strings from 'tools/strings'

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledDeleteButton = styled(Button)`
  background-color: red;
  margin-right: 50px;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const useStyles = makeStyles({
  width: {
    width: 400
  }
})

const SubSpan = styled.span`
  unicode-bidi:embed;  
`
const StyledCancleButton = styled(Button)`
  margin-right: 50px;
`
const validateEmail = [email()]
const FormBody = props => {
  const translate = useTranslate()
  const { type, record, initialValue } = props
  switch (type) {
    case "bank_order_results_time_frame_in_days":
      return (
        <BodyContainer>
          <NumberInput source='value' helperText='customRoot.definitions.edit.banks_orders_results_timeframe_in_days' />
        </BodyContainer>
      )
    case "chargees":
      return (
        <BodyContainer>
          <TextInput source={`displayName.${strings.definitionsLanguage.he}`} label='customRoot.definitions.edit.display_name.he' />
          <TextInput source={`displayName.${strings.definitionsLanguage.en}`} label='customRoot.definitions.edit.display_name.en' />
          <TextInput source={`name.${strings.definitionsLanguage.he}`} label='customRoot.definitions.edit.name.he' />
          <TextInput source={`name.${strings.definitionsLanguage.en}`} label='customRoot.definitions.edit.name.en' />
          <SelectInput
            source='type'
            defaultValue={type}
            label='customRoot.definitions.edit.org_type'
            choices={[
              { id: "kibbutz", name: "Kibbutz" },
              { id: "community", name: "Community" }
            ]}
          />
          <ArrayInput source='currencies' label='customRoot.definitions.edit.currencies'>
            <SimpleFormIterator>
              <TextInput label='customRoot.definitions.edit.currency' />
            </SimpleFormIterator>
          </ArrayInput>
        </BodyContainer>
      )
    case "charging_date":
      return (
        <BodyContainer>
          <NumberInput source='value' label='customRoot.definitions.edit.charging_date.day' step={1} helperText={"customRoot.definitions.edit.charging_dates_helper"} />
        </BodyContainer>
      )
    case "start_of_charging_cycle":
      return (
        <BodyContainer>
          <NumberInput source='value' label='customRoot.definitions.edit.start_of_charging_cycle.day' step={1} helperText={"customRoot.definitions.edit.charging_dates_helper"} />
        </BodyContainer>
      )
    case "charging_permission_receivers":
      return (
        <BodyContainer>
          <ArrayInput source='sendUpdatesOn.month' label='customRoot.definitions.edit.charging_permission_receivers.month'>
            <SimpleFormIterator>
              <NumberInput label='customRoot.definitions.edit.charging_permission_receivers.day' />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput source='sendUpdatesOn.lastDayOfMonth' label='customRoot.definitions.edit.start_of_charging_cycle.last_day_of_month' />
          <ArrayInput source='emails' label='customRoot.definitions.edit.charging_permission_receivers.emails'>
            <SimpleFormIterator>
              <TextInput dir='ltr' label='customRoot.definitions.edit.charging_permission_receivers.email' />
            </SimpleFormIterator>
          </ArrayInput>
        </BodyContainer>
      )
    case "charging_summary_receivers":
      return (
        <BodyContainer>
          <ArrayInput source='emails' label='customRoot.definitions.edit.charging_summary_receivers.emails'>
            <SimpleFormIterator>
              <TextInput dir='ltr' label='customRoot.definitions.edit.charging_permission_receivers.email' />
            </SimpleFormIterator>
          </ArrayInput>
        </BodyContainer>
      )
    case "contact_appeals_recipients":
      return (
        <BodyContainer>
          <ArrayInput source='value' label='customRoot.definitions.edit.contact_appeals_recipients.emails'>
            <SimpleFormIterator>
              <TextInput dir='ltr' label='customRoot.definitions.edit.charging_permission_receivers.email' />
            </SimpleFormIterator>
          </ArrayInput>
        </BodyContainer>
      )
    case "finance_system_name":
      return (
        <BodyContainer>
          <SelectInput source='record' defaultValue={initialValue.id} label='customRoot.definitions.edit.finance_system_name.title' choices={record.value} />
        </BodyContainer>
      )
    case "masav":
      return (
        <BodyContainer>
          <TextInput source='chargee' label='customRoot.definitions.edit.masav.chargee' />
          <TextInput source='chargeCode' label='customRoot.definitions.edit.masav.charge_code' />
          <TextInput source='mosad' label='customRoot.definitions.edit.masav.mosad' />
          <TextInput source='incomeCode' label='customRoot.definitions.edit.masav.income_code' />
        </BodyContainer>
      )
    case "org_name":
      return (
        <BodyContainer>
          <TextInput source={strings.definitionsLanguage.he} label='customRoot.definitions.edit.org_name.he' />
          <TextInput dir='ltr' source={strings.definitionsLanguage.en} label='customRoot.definitions.edit.org_name.en' />
        </BodyContainer>
      )
    case "contact_for_enquiries":
      return (
        <BodyContainer>
          <TextInput source={`template.${strings.definitionsLanguage.he}`} label='customRoot.definitions.edit.contact_for_enquiries' />
          <TextInput dir='ltr' source={`template.${strings.definitionsLanguage.en}`} label='customRoot.definitions.edit.contact_for_enquiries' />
        </BodyContainer>
      )
    case "finance_cloud_storage":
      return (
        <BodyContainer>
          <TextInput source='provider' label='customRoot.definitions.edit.finance_cloud_storage.provider' />
          <TextInput dir='ltr' source='username' label='customRoot.definitions.edit.finance_cloud_storage.username' />
          <TextInput source='password' label='customRoot.definitions.edit.finance_cloud_storage.password' />
          <TextInput dir='ltr' source='folderPath' label='customRoot.definitions.edit.finance_cloud_storage.folder_path' />
          <TextInput dir='ltr' source='url' label='customRoot.definitions.edit.finance_cloud_storage.url' />
          <TextInput dir='ltr' source='proxyToken' label='customRoot.definitions.edit.finance_cloud_storage.proxy_token' />
        </BodyContainer>
      )
    case "organization_address":
    case "default_noreply_email":
    case "import_file_headers_size":
    case "organization_email":
    case "organization_phone":
    case "organization_sms_sender":
    case "org_language":
      const keyDoesNotExists = translate(`customRoot.definitions.edit.${type}_helper`).startsWith('customRoot')
      return (
        <BodyContainer>
          <TextInput source='value' label={`customRoot.definitions.edit.${type}`} helperText={keyDoesNotExists ? '' : `customRoot.definitions.edit.${type}_helper`} />
        </BodyContainer>
      )
    case "receipt_at_status":
      return (
        <BodyContainer>
          <NumberInput source='value' label={`customRoot.definitions.edit.receipt_at_status`} helperText={`customRoot.definitions.edit.receipt_at_status_helper`} />
        </BodyContainer>
      )
    case "payments_fetching_dates":
      return (
        <BodyContainer>
          <ArrayInput source='value' label='customRoot.definitions.edit.payments_fetching_dates.title'>
            <SimpleFormIterator>
              <TextInput label='customRoot.definitions.edit.payments_fetching_dates.field' />
            </SimpleFormIterator>
          </ArrayInput>
        </BodyContainer>
      )
    case "printed_notifications_distributers":
      return (
        <BodyContainer>
          <ArrayInput source='emails' label='customRoot.definitions.edit.printed_notifications_distributers.emails'>
            <SimpleFormIterator>
              <TextInput dir='ltr' label='customRoot.definitions.edit.charging_permission_receivers.email' />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source='sendUpdatesOn.week' label='customRoot.definitions.edit.printed_notifications_distributers.send_updates_on'>
            <SimpleFormIterator>
              <NumberInput label='customRoot.definitions.edit.payments_fetching_dates.field' helperText={"customRoot.definitions.edit.printed_notifications_distributers_helper"} />
            </SimpleFormIterator>
          </ArrayInput>
        </BodyContainer>
      )
    case "zooz":
      return (
        <BodyContainer>
          <TextInput source='chargee' label='customRoot.definitions.edit.masav.chargee' />
          <TextInput source='mosad' label='customRoot.definitions.edit.masav.mosad' />
          <TextInput source='appId' label='customRoot.definitions.edit.zooz.app_id' />
          <TextInput source='publicKey' label='customRoot.definitions.edit.zooz.public_key' />
          <TextInput source='privateKey' label='customRoot.definitions.edit.zooz.private_key' />
        </BodyContainer>
      )
    case "banks_orders_requests_emails":
      return (
        <BodyContainer>
          <SubSpan>{translate('customRoot.definitions.edit.banks_orders_requests_emails.title')}</SubSpan>
          <ArrayInput source='value.emails' label='customRoot.definitions.edit.banks_orders_requests_emails.emails'>
            <SimpleFormIterator>
              <TextInput dir='ltr' label='customRoot.definitions.edit.banks_orders_requests_emails.emails' validate={validateEmail} />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source='value.subject' label='customRoot.definitions.edit.banks_orders_requests_emails.subject' />
          <TextInput dir='ltr' source='value.from' label='customRoot.definitions.edit.banks_orders_requests_emails.from' validate={validateEmail} />
        </BodyContainer>
      )
    case "run_charge_flow_emails":
      return (
        <BodyContainer>
          <SubSpan>{translate('customRoot.definitions.edit.run_charge_flow_emails.title')}</SubSpan>
          <ArrayInput source='value.emails' label='customRoot.definitions.edit.run_charge_flow_emails.emails'>
            <SimpleFormIterator>
              <TextInput dir='ltr' label='customRoot.definitions.edit.run_charge_flow_emails.emails' validate={validateEmail} />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source='value.subject' label='customRoot.definitions.edit.run_charge_flow_emails.subject' />
          <TextInput dir='ltr' source='value.from' label='customRoot.definitions.edit.run_charge_flow_emails.from' validate={validateEmail} />
        </BodyContainer>
      )
    case "banks_orders_requests_pending_timeframe_in_days":
      return (
        <BodyContainer>
          <NumberInput source='value' label='customRoot.definitions.edit.banks_orders_requests_pending_timeframe_in_days' helperText={"customRoot.definitions.edit.banks_orders_requests_pending_timeframe_in_days_helper"} />
        </BodyContainer>
      )
    case "registration_payment_method":
      return (
        <BodyContainer>
          <BooleanInput source='active' label='customRoot.definitions.edit.registration_payment_method_active' helperText={"customRoot.definitions.edit.registration_payment_method_helper"} />
        </BodyContainer>
      )
    case "run_tasks_restriction":
      return (
        <BodyContainer>
          <RadioButtonGroupInput
            source='value'
            choices={Object.values(Restrictions).map((restriction, index) => ({ value: index, id: index, label: `customRoot.definitions.edit.run_tasks_restriction.${restriction}` }))}
            optionText='label'
            optionValue='value'
            label='customRoot.definitions.edit.run_tasks_restriction.title'
          ></RadioButtonGroupInput>
        </BodyContainer>
      )
    case strings.definitionsNames.block_run_charge_flow:
      return (
        <BodyContainer>
          <BooleanInput defaultValue={record === 1} source='value' label='customRoot.definitions.edit.block_run_charge_flow.active' />
        </BodyContainer>
      )
    case "send_budget_pages":
      return (
        <BodyContainer>
          <BooleanInput source='active' label='customRoot.definitions.edit.send_budget_pages_active' />
        </BodyContainer>
      )
    case "auto_pull_financial_data":
      return (
        <BodyContainer>
          <BooleanInput source='active' label='customRoot.definitions.edit.auto_pull_financial_data_active' />
        </BodyContainer>
      )
    default:
      return <div />
  }
}
const OrgFormFactory = props => {
  const [open, setOpen] = useState(false);
  const classes = useStyles()
  const translate = useTranslate()
  const redirect = useRedirect();

  let { def, type, onSave, initialValue, onDelete } = props

  let { record, version } = useCreateController(props)
  def = orgDefToArrayOfObjects(def)
  record = def

  const handleClick = values => {
    let results = values
    const { record, value } = values
    if (record) {
      results = value
        .filter(val => val.id === record)
        .map(v => ({
          name: v.name,
          code: v.id
        }))[0]
    }
    onSave(results)
  }

  const handleDelete = (event) => {
    setOpen(false)
    onDelete()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const deleteWarning = () => {
    setOpen(true)
  }
  const handleCancel = () => {
    redirect('/');
  }

  return (
    <Form
      initialValues={record}
      onSubmit={handleClick}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <FormContainer>
          <StyledForm onSubmit={handleSubmit} className={classes.width}>
            <FormBody type={type} record={record} initialValue={initialValue} />
            <ButtonsContainer>
              <Button variant='contained' color='primary' type='submit' disabled={submitting}>
                {translate("customRoot.definitions.edit.submit_button")}
              </Button>
              <StyledDeleteButton variant='contained' color='secondary' onClick={deleteWarning}>
                {translate("customRoot.definitions.edit.delete_button")}
              </StyledDeleteButton>
              <StyledCancleButton variant='contained' onClick={handleCancel} color='submit'>
                {translate("customRoot.definitions.edit.cancel_button")}
              </StyledCancleButton>
            </ButtonsContainer>
          </StyledForm>
          <WarningDialog open={open} handleClose={handleClose} handleConfirm={handleDelete} content={"customRoot.definitions.deleteWarningContent"} title={"customRoot.definitions.deleteWarningTitle"} />
        </FormContainer>
      )}
    />
  )
}
const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
}

export default OrgFormFactory
