const veryLocalStorage = {
  set(key, value) {
    try {
      const toSave = typeof value === "object" ? JSON.stringify(value) : value
      localStorage.setItem(key, toSave)
    } catch {
      throw new Error()
    }
  },
  get(key) {
    const data = localStorage.getItem(key)
    try {
      const json = JSON.parse(data)
      return json
    } catch {
      return data
    }
  },
  remove(key) {
    try {
      localStorage.removeItem(key)
    } catch {
      console.error('--- veryLocalStorage.js -> remove -> ', key)
    }
  }
}

export default veryLocalStorage
