import React, { useState } from "react"
import { observer, inject } from "mobx-react"
import { TextInput, useRedirect, useTranslate, useCreateController, required, SelectInput, number } from "react-admin"
import styled from "styled-components"
import { Form } from "react-final-form"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import arrayMutators from "final-form-arrays"
import WarningDialog from "../../utils/WarningDialog"
import { corporationMembersTypes } from '../../../stores/corporationStore'
import { isValidIsraeliID } from '@kimaia/validation';
const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledDeleteButton = styled(Button)`
  margin-left: 50px;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const useStyles = makeStyles({
  width: {
    width: 400
  }
})


const CorporationSection = props => {
  const validateMembersType = [required()];
  const validateName = [required()];
  const validateBusinessNumber = [required(), number()];

  return (
    <BodyContainer>
      <SelectInput source={'data.members_type'} label='customRoot.definitions.edit.corporation_members_type'
        validate={validateMembersType} choices={corporationMembersTypes} />
      <TextInput source='data.name.he' label='customRoot.definitions.edit.corporation_name.he' validate={validateName} />
      <TextInput dir='ltr' source='data.name.en' label='customRoot.definitions.edit.corporation_name.en' validate={validateName} />
      <TextInput type='number' source='data.business_number' label='customRoot.definitions.edit.corporation_id'
        validate={validateBusinessNumber} />
    </BodyContainer>
  )
}

const CorporationCreate = ({ corporationStore, ...props }) => {
  const redirect = useRedirect();

  const [warningOpen, setWarningOpen] = useState(false);
  const [warningContent, setWarningContent] = useState("");
  const classes = useStyles()
  const translate = useTranslate()
  const { version } = useCreateController(props)
  const onSuccess = async () => {
    setWarningOpen(false)
    redirect('/corporations');
  }
  const onFailed = async (error) => {
    setWarningContent(error)
    setWarningOpen(true)
  }

  const checkCorporationDataIsNotEmpty = (corporation) => {
    return corporation && corporation.data && corporation.data.name && corporation.data.name.he && corporation.data.name.en && corporation.data.business_number
  }
  const onSave = async (res) => {
    return await corporationStore.createCorporation(res)
  }
  const handleClick = async (results) => {
    if (!isValidIsraeliID(results.data.business_number)) {
      setWarningContent("customRoot.definitions.invalidBusinessNumber")
      setWarningOpen(true)
      return
    }
    if (!checkCorporationDataIsNotEmpty(results)) {
      setWarningContent("customRoot.definitions.missingData")
      setWarningOpen(true)
      return
    }
    const res = await onSave(results)
    if (!res.success) {
      onFailed(res.error)
    }
    else {
      onSuccess()
    }
  }
  const handleDelete = (event) => {
    redirect('/corporations');
  }

  const handleCloseWarning = () => {
    setWarningOpen(false)
  }

  return (
    <Form
      onSubmit={handleClick}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={({ handleSubmit, submitting }) => (
        <FormContainer>
          <StyledForm onSubmit={handleSubmit} className={classes.width}>
            <CorporationSection />
            <ButtonsContainer>
              <StyledDeleteButton variant='contained' color='submit' onClick={handleDelete}>
                {translate("customRoot.definitions.edit.cancel_button")}
              </StyledDeleteButton>
              <Button variant='contained' color='primary' type='submit' disabled={submitting}>
                {translate("customRoot.definitions.edit.submit_button")}
              </Button>
            </ButtonsContainer>
            <WarningDialog open={warningOpen} handleClose={handleCloseWarning} handleConfirm={handleCloseWarning} content={warningContent} title={"customRoot.definitions.failureMessage"} />
          </StyledForm>
        </FormContainer>
      )} />
  )
}
export default inject("corporationStore")(observer(CorporationCreate))

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
}