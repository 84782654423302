import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useTranslate } from "react-admin"

const WarningDialog = ({ open, handleClose, handleConfirm, content, title }) => {
  const translate = useTranslate()
  return (
    <Dialog dir='rtl' open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{translate(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{translate(content)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {translate("customRoot.orders.modal.cancel")}
        </Button>
        <Button onClick={handleConfirm} color='primary'>
          {translate("customRoot.orders.modal.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default WarningDialog
