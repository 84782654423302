import React from "react"
import { List, Datagrid, TextField, BooleanField } from "react-admin"
import PostFilter from "./postFilter"
import styled from "styled-components"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`

const OrgList = props => {
  return (
    <FormContainer>
      <List {...props} filters={<PostFilter />} bulkActionButtons={false}>
        <Datagrid rowClick='edit'>
          <TextField source='id' label='customRoot.organizations.list.org_id' sortable={false} />
          <TextField source='name' label='customRoot.organizations.list.org_name' sortable={false} />
          <BooleanField source='status' label='customRoot.organizations.list.active' sortable={false} />
        </Datagrid>
      </List>
    </FormContainer>
  )
}

export default OrgList
