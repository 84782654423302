import React, { useState, useEffect } from "react"
import { useNotify, SelectInput, FormWithRedirect } from "react-admin"
import { useSelector } from 'react-redux'
import { observer, inject } from "mobx-react"
import OrgFormFactory from "./orgFormFactory"
import { makeStyles } from "@material-ui/core/styles"

import styled from "styled-components"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 50px;
`

const useStyles = makeStyles({
  width: {
    width: 400
  }
})



const GlobalDefinitionList = ({ userStore, permissions, ...props }) => {

  const classes = useStyles()
  const notify = useNotify()
  const [definitions, setDefinitions] = useState(null)
  const [singleDef, setSingleDef] = useState({})
  const viewVersion = useSelector(state => state.admin.ui.viewVersion)

  const fetchDef = async event => {
    try {
      const definition = userStore.globalDefList?.find(def => def?.id === event.target.value)
      if (definition?.def) {
        setSingleDef({ key: definition.def.key || "", record: { value: definition.def.value } })
      }
      else notify("customRoot.general.error", "warning")
    } catch (error) {
      notify("customRoot.general.error", "warning")
    }
  }
  const handleSave = async values => {
    const { key } = singleDef
    const value = values.value
    const updateDef = await userStore.updateGlobalDefinition(key, value)
    if (updateDef) {
      notify("customRoot.general.success", "success")
    } else if (!updateDef) {
      notify("customRoot.general.error", "warning")
    }
  }

  useEffect(() => {
    const keys = [
      "banks_orders_requests_emails",
      "run_charge_flow_emails",
      "bank_order_results_time_frame_in_days"
    ]
    userStore.getGlobalDefinitions({ keys })
  }, [userStore, viewVersion])

  useEffect(() => {
    setDefinitions(userStore.globalDefList)
    const definition = userStore.globalDefList?.find(el => el?.id === singleDef.key)
    if (definition?.def) {
      setSingleDef({ key: definition.def.key || "", record: { value: definition.def.value } })
    }
  }, [userStore, userStore.globalDefList])
  if (!userStore.org) {
    return null
  }
  return (
    <FormContainer>
      <FormWithRedirect {...props} render={() => <form>{definitions && <SelectInput className={classes.width} source='sys_id' label='customRoot.definitions.def' choices={definitions} onChange={fetchDef} />}</form>} />
      {singleDef && (singleDef.record || singleDef.record === 0) && <OrgFormFactory {...props} onSave={handleSave} def={singleDef.record} type={singleDef.key} initialValue={singleDef.initialValue} />}
    </FormContainer>
  )
}

export default inject("userStore")(observer(GlobalDefinitionList))
