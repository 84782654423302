import {types} from "mobx-state-tree"
import {requests} from "../fetch/requests";
import {userStore} from "./index";

const PosStore = types.model('PosStore', {})
  .views((self) => ({}))
  .actions(self => {
    const checkConnection = async (data) => {
      try {
        const { username, password, url } = data;

        const body = {
          username,
          password,
          path: url
        };

        const res = await requests.checkConnection(userStore, body);

        if (res.success) {
          return res.data;
        }
        else {
          throw new Error(res.error);
        }
      } catch (err) {
        return { success: false, error: err }
      }
    }

    return {
      checkConnection,
    }
  })

const posStore = PosStore.create({
})

export default posStore

