import React from "react"
import { ResponsivePie } from "@nivo/pie"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  margin-top: 10px;
`


const PieChartComponent = ({ data }) => {
  return (
    <Container>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ datum: 'data.color' }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        enableRadialLabels={false}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor='#333333'
        radialLabelsLinkColor={{ from: "color" }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor='#333333'
      />
    </Container>
  )
}
export default PieChartComponent
