import React from "react"
import { useNotify, List, Datagrid, TextField, FunctionField } from "react-admin"
import Button from '@material-ui/core/Button'
import { observer, inject } from "mobx-react"
import styled from "styled-components"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`

const BudgetRoleButton = inject('userStore')(observer(({ record, userStore }) => {
  const notify = useNotify()

  const onClick = async () => {
    const body = { period: [], is_manual_run: true };
    const runResult = await userStore.runTask({ scope: 'org', name: 'budget_role' }, body);

    if (runResult.success) notify("customRoot.requestResult.success", "success");
    else notify("customRoot.requestResult.error", "warning");
  }

  if (record.name !== "member_budget_<entity>") return null;

  return (
    <Button variant="contained" onClick={onClick} color='primary'>Enable To All Users</Button>
  )
}))

const RoleList = ({ userStore, ...props }) => {
  return (
    <FormContainer>
      {userStore.org && (
        <List {...props} bulkActionButtons={false} >
          <Datagrid>
            <TextField source='name' label='customRoot.roles.name' sortable={false} />
            <TextField source='description.he' label='customRoot.roles.description' sortable={false} />
            <FunctionField render={record => <BudgetRoleButton record={record}/> } /> 
          </Datagrid>
        </List>
      )}
    </FormContainer>
  )
}

export default inject("userStore")(observer(RoleList))
