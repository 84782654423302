import strings from "./strings";

export const orgDefToArrayOfObjects = data => {
  if (typeof data === "string" || typeof data === "number") {
    return {
      value: data
    }
  }
  if (Array.isArray(data)) {
    return {
      value: data
    }
  } else {
    return data
  }
}

export function postMessage(type, value) {
  var wn = document.getElementById('app-iframe').contentWindow;
  wn.postMessage({ type: type, value: value }, '*');
}

export const currentPeriod = () => [new Date().getFullYear(), new Date().getMonth() + 1]

export const previousPeriod = () => {
  const date = new Date()
  date.setMonth(date.getMonth() - 1)
  return date
}

export const getEnvThemeColor = () => {
  // const env = strings.appEnv;
  // let themePalette = strings.envColor.local;
  // if (env && Object.keys(strings.envColor).includes(env)) {
  //   themePalette = strings.envColor[env];
  // }
  return strings.envColor.prod;
}
