import React from "react"
import { observer, inject } from "mobx-react"
import { useTranslate } from "react-admin"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import DatePicker from "../../utils/datePicker"
import strings from '../../../tools/strings'
import { previousPeriod } from '../../../tools/tools'

const useStyles = makeStyles(() => ({
  danger: {
    color: "red",
    fontSize: 20
  },
  info: {
    color: "green",
    fontSize: 20
  }
}))

const FormBody = inject('userStore')(observer(({ userStore, type }) => {
  const translate = useTranslate()
  const classes = useStyles()

  switch (type) {
    case strings.tasks.MASAV_TRANSMITTED:
      return (
        <DatePickerForm title={translate("customRoot.tasks.masav_transmitted")} handleDateChangeFunc={userStore.setMasavTransmittedFilesDate} />
      )
    case strings.tasks.ADD_TO_REGISTERED:
      return (
        <div>
          <DialogTitle id='form-dialog-title'>{translate("customRoot.tasks.recreate_bank_authorizations")}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.info}>{translate("customRoot.tasks.recreate_bank_authorizations_general")}</DialogContentText>
            <DialogContentText className={classes.danger}>{translate("customRoot.tasks.danger")}</DialogContentText>
          </DialogContent>
        </div>
      )
    case strings.tasks.MASAV_INCOME_TRANSMITTED:
      return (
        <DatePickerForm title={translate("customRoot.tasks.masav_income_transmitted")} handleDateChangeFunc={userStore.setMasavIncomesTransmittedFilesDate} />
      )
    default:
      return <div />
  }
}))

const TaskFactory = ({ userStore, open, setOpen, type, executeTask }) => {
  const translate = useTranslate()

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    executeTask()
  }

  return (
    <div dir='rtl'>
      <Dialog disableEnforceFocus open={open} onClose={handleClose} aria-labelledby='form-dialog-title' dir='rtl'>
        <FormBody type={type} />
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate("customRoot.orders.modal.cancel")}
          </Button>
          <Button onClick={handleConfirm} color='primary'>
            {translate("customRoot.orders.modal.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const DatePickerForm = ({ title, handleDateChangeFunc }) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div>
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{translate("customRoot.tasks.choose_year_month")}</DialogContentText>
        <DatePicker handleDateChange={handleDateChangeFunc} date={previousPeriod()} />
        <DialogContentText className={classes.danger}>{translate("customRoot.tasks.danger")}</DialogContentText>
      </DialogContent>
    </div>
  )
}

export default inject("userStore")(observer(TaskFactory))
