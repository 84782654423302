import {types} from "mobx-state-tree"
import vls from "../tools/vls"
import strings from "../tools/strings"
import {commonRequests, METHOD} from 'clearing-types'
import {DOMAIN, ENDPOINTS} from 'fetch/constants'
import {requests} from 'fetch/requests'
import moment from 'moment'
import currency from 'currency.js';
import {postMessage} from '../tools/tools'
import fileSystemApi from 'fetch/fileSystemApi'

const Organization = types.model('Organization', {
  id: types.string,
  name: types.string,
  status: types.boolean,
})

const UsersStats = types.model('UsersStats', {
  registrationStatus: types.number,
  completedAt: types.maybeNull(types.Date, null),
  paymentMethod: types.maybeNull(types.string, null),
})

const PosSystem = types.model('PosSystem', {
  id: types.string,
  name: types.string,
  code: types.string,
  active: types.number,
  communicationType: types.number
})

const FinancialBookData = types.model('FinancialBookData', {
  code: types.string,
  currencies: types.array(types.string),
  name: types.frozen(),
})


const FinancialBook = types.model('FinancialBook', {
  data: FinancialBookData,
  subType: types.string,
  type: types.string,
  id: types.string,
  rev: types.string,
  name: types.string,
})

const UserStore = types
  .model("UserStore", {
    memberId: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),
    orgData: types.maybeNull(types.frozen()),
    org: types.optional(types.string, ""),
    entity: types.optional(types.string, ""),
    orgName: types.frozen(),
    isAuth: types.boolean,
    isAuthorized: types.boolean,
    error: types.maybeNull(types.frozen()),
    masavTransmittedFilesDate: types.Date,
    masavTransmittedFile: types.frozen(),
    banksOrdersPdfsDate: types.maybeNull(types.frozen()),
    bankChargesRejectedFilterDates: types.maybeNull(types.frozen()),
    chargesAmountsReportDate: types.maybeNull(types.Date),
    getPaymentsByMothDate: types.maybeNull(types.Date),
    defList: types.frozen(),
    globalDefList: types.frozen(),
    entitiesList: types.frozen(),
    bankOrdersEntitiesList: types.frozen(),
    singleDef: types.maybeNull(types.string),
    singleGlobalDef: types.maybeNull(types.string),
    registeredMembers: types.frozen(),
    processes: types.frozen(),
    allUsers: types.array(UsersStats),
    numberOfUsers: types.number,
    memberCommunicationsChannels: types.frozen(),
    organizations: types.array(Organization),
    disabledButtons: types.map(types.boolean),
    masavChargesDate: types.maybeNull(types.Date),
    masavIncomesDate: types.maybeNull(types.Date),
    posSystems: types.array(PosSystem),
    reportForAllOrg: types.boolean,
    financialBooks: types.array(FinancialBook),
    fsOrgId: types.maybe(types.number)
  })
  .actions(self => {
    async function loadApp() {
      self.setIsLoading(false)
      if (!self.memberId) {
        self.setAuth(false)
        return
      }
      const res = await self.initApp()
      if (!res.success && res.description === strings.error.NO_PERMISSIONS) {
        console.error("אין הרשאות גישה לאפליקציה")
        alert("אין הרשאות גישה לאפליקציה")//TODO add translation to error permissions
        return
      }
      let fsOrg = await self.getFsOrg(res.data.memberData.org)
      if (!fsOrg) {
        fsOrg = await self.addFSOrg(self.org)
      }
      self.setFSOrgId(fsOrg.id)
      self.setAuth(true)
    }
    const addFSOrg = async (org) => {
      const orgName = await self.getOrgName(org)
      const response = await fileSystemApi.request(METHOD.POST, fileSystemApi.endpoints.addCommunity, {
        name: orgName[strings.definitionsLanguage.en],
        exCommunityId: org,
        communityHebrew: orgName.he
      })
      return response.data
    }

    const getFsOrg = async (org) => {
      const response = await fileSystemApi.request(METHOD.GET, fileSystemApi.endpoints.getCommunityByExtId(org))
      return response.data
    }

    const setFsOrg = async (org) => {
      let fsOrg = await self.getFsOrg(org);
      if (!fsOrg) {
        fsOrg = await self.addFSOrg(org);
      }
      self.setFSOrgId(fsOrg.id);
    }

    const getOrgName = async (org) => {
      const body = { org, filter: ['org_name'] }
      const orgData = await requests.orgName(self, body)
      return orgData.data.orgName[0].value
    }
    function setMemberId(id) {
      if (id != null) {
        self.memberId = id
      }
    }
    function setOrg(org) {
      vls.set('org', org)
      self.org = org
    }
    function setIsLoading(isLoading) {
      self.isLoading = isLoading
    }
    function setError(error) {
      self.error = error
    }
    function setHistory(history) {
      self.history = history
    }
    function setOrgName(name) {
      self.orgName = name
    }
    function setAuth(isAuth) {
      self.isAuth = isAuth
      if (isAuth === false) {
        self.setMemberId("")
        postMessage(strings.WINDOW_POST_MESSAGE_TYPE.SET_ACTIVE_USER, '')
        if (!self.history) {
          window.location.href = '/#/login'
        }
        else {
          self.history.push('/#/login')
        }
        self.setLoading(false)
      }
    }
    function setOrgData(orgData) {
      self.orgData = orgData
    }
    function setLoading(isLoading) {
      self.isLoading = isLoading
    }
    function setPaymentData(data) {
      self.PaymentData = data.activePayment
    }

    function setUserDetails(data) {
      self.userDetails = data
    }

    function setDefList(data) {
      self.defList = data
    }

    function setSingleGlobalDef(def) {
      self.singleGlobalDef = def
    }

    function setGlobalDefList(data) {
      self.globalDefList = data
    }

    function setSingleDef(def) {
      self.singleDef = def
    }

    function setMasavTransmittedFilesDate(date) {
      self.masavTransmittedFilesDate = date
    }

    function setMasavIncomesTransmittedFilesDate(date) {
      self.masavIncomesTransmittedFilesDate = date
    }

    function setChargesAmountsReportDate(date) {
      self.chargesAmountsReportDate = date
    }

    function setMasavChargesDate(date) {
      self.masavChargesDate = date
    }

    function setMasavIncomesDate(date) {
      self.masavIncomesDate = date
    }

    function setGetPaymentsByMothDate(date) {
      self.getPaymentsByMothDate = date
    }

    function setEntitiesList(entities) {
      self.entitiesList = entities
    }

    function setBankOrdersEntitiesList(entities) {
      self.bankOrdersEntitiesList = entities
    }


    function setBanksOrdersPdfsDate(dates) {
      self.banksOrdersPdfsDate = dates
    }

    function setBankChargesRejectedFilterDates(dates) {
      self.bankChargesRejectedFilterDates = dates
    }


    function getBankChargesRejectedFilterDates() {
      return self.bankChargesRejectedFilterDates
    }



    function getBanksOrdersPdfsDate() {
      return self.banksOrdersPdfsDate
    }


    function setRoles(roles) {
      self.rolesList = roles
    }

    async function tryLogin(memberId) {
      const body = {
        member_id: memberId
      }
      const loginResult = await commonRequests.login(DOMAIN, self, body)
      if (!loginResult.success) {
        throw new Error(loginResult.error?.detail)
      }
      const channelsResult = await commonRequests.channels(DOMAIN, self)
      if (!channelsResult.success) {
        throw new Error(channelsResult.error?.detail)
      }
      userStore.setMemberCommunicationsChannels(channelsResult.channels)
      const otpBody = {
        channel: userStore.memberCommunicationChannel,
        lang: vls.get('i18n_lang')?.toLocaleLowerCase() || 'he',
      }
      const otpResult = await commonRequests.sendOTP(DOMAIN, self, otpBody)
      if (!otpResult.success) {
        userStore.setError(otpResult.error?.detail.toString())
        return
      }
      self.setMemberId(memberId)
    }

    async function verifyOtp(code) {
      const otpBody = {
        code: code
      }
      return await commonRequests.verifyOTP(DOMAIN, self, otpBody)
    }

    async function initApp() {
      return await commonRequests.initApp(DOMAIN, self, ENDPOINTS.user.initApp(self.memberId))
    }

    function setMemberCommunicationsChannels(memberCommunicationsChannels) {
      self.memberCommunicationsChannels = memberCommunicationsChannels
    }

    async function sendAdminLink(subject, template, members, org) {
      const body = {
        subject,
        template,
        members: [...members]
      }
      const lang = "he"
      await requests.sendMessage(self, org, lang, body)
    }

    async function getUserDetails() {
      const userDetails = await requests.getUserDetails(self, self.org, self.memberId)
      if (!userDetails.success) {
        throw new Error()
      }
      self.setUserDetails(userDetails.data)
      return true
    }

    async function updateOrgDefinition(key, code, value) {
      const data = {
        org: self.org,
        data: [
          {
            key,
            code,
            value
          }
        ]
      }
      const response = await requests.updateOrgDefinition(self, data)
      if (response.success) {
        return true
      } else if (!response.success) {
        return false
      }
    }

    async function updateGlobalDefinition(key, value) {
      const data = {
        data: [
          {
            key,
            value
          }
        ]
      }
      const response = await requests.updateGlobalDefinition(self, data)
      if (response.success) {
        return true
      } else if (!response.success) {
        return false
      }
    }

    async function getOrgDefinitions(key) {
      const query = {
        key: key
      }

      const response = await requests.getOrgDefinitions(self, self.org, query)
      if (response.success) {
        const defs = response.data
          .filter(d => d.code !== strings.definitionsDescriptionKey)
          .map(def => {
            const name = response.data.filter(d => d.code === strings.definitionsDescriptionKey && d.key === def.key)[0]?.value.alias.he
            if (!name) {
              return null
            }
            return {
              id: `${def.key} # ${def.code}`,
              name: `${name} - ${def.code} (${def.key})`,
              def: def
            }
          })
          .filter(el => el !== null)
        self.setDefList(defs)
        return true
      } else if (!response.success) {
        return false
      }
    }

    async function getGlobalDefinitions(defKeys) {

      const response = await requests.getGlobalDefinitions(self, defKeys)
      if (response.success) {
        const defsList = []

        for (const [key, value] of Object.entries(response.data)) {
          const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

          if (defKeys.keys.includes(snakeCaseKey)) {
            defsList.push({ id: snakeCaseKey, name: snakeCaseKey, def: { key: snakeCaseKey, value: value[0] } })
          }
        }
        self.setGlobalDefList(defsList)
        return true
      } else if (!response.success) {
        return false
      }
    }

    async function getSingleDef(def) {
      const query = {
        def: JSON.stringify(def)
      }
      const response = await requests.getOrgDefinition(self, self.org, query)
      if (response.success) {
        const { data } = response
        const { definition, initialValue } = data
        const def = { record: definition[0].value, key: definition[0].key, code: definition[0].code, initialValue }
        return def
      } else if (!response.success) {
        return false
      }
    }

    async function deleteOrgDefinition(code, key) {
      const data = {
        code,
        key,
      }
      const response = await requests.deleteOrgDefinition(self, data)
      if (response.success) return true
      else return false
    }


    async function generateBanksOrdersPdfs() {
      let fromDate, toDate
      if (self.banksOrdersPdfsDate?.length) {
        fromDate = self.banksOrdersPdfsDate[0].getTime()
        toDate = self.banksOrdersPdfsDate[1].getTime()
      }
      return await requests.generateBanksOrdersPdfs(self, self.org, fromDate, toDate)
    }


    async function getChargesAmounts() {
      const data = {
        org: self.org,
        period: [self.chargesAmountsReportDate.getFullYear(), self.chargesAmountsReportDate.getMonth() + 1]
      }
      const response = await requests.chargesAmounts(self, data)
      if (response.success) return response.data
      else return false

    }

    async function getMasavFiles(url) {
      return await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/zip',
        },
        credentials: 'include'
      })
    }

    async function getPaymentsMonth() {
      const org = self.reportForAllOrg ? "all" : self.org
      await requests.getPayments(self, org, self.getPaymentsByMothDate.getFullYear(), self.getPaymentsByMothDate.getMonth() + 1)
    }

    async function getBankChargesRejected() {
      let fromDate, toDate
      if (self.bankChargesRejectedFilterDates?.length) {
        fromDate = self.bankChargesRejectedFilterDates[0].getTime()
        toDate = self.bankChargesRejectedFilterDates[1].getTime()
      }
      const response = await requests.getBankChargesRejected(self, self.org, fromDate, toDate)

      if (response.success) return response.data.map(rejectData => ({
        mosad_code: rejectData.result.mosadCode,
        asmachta: rejectData.result.asmachta,
        date_of_mosad_charge_for_rejection: moment(rejectData.result.dateOfMosadChargeForRejection).format('DD/MM/yyyy'),
        value_of_mosad_charge_for_rejection: moment(rejectData.result.valueOfMosadChargeForRejection).format('DD/MM/yyyy'),
        mosad_bank_code: rejectData.result.mosadBankCode,
        mosad_branch_code: rejectData.result.mosadBranchCode,
        mosad_account_number: rejectData.result.mosadAccountNumber,
        customer_name: rejectData.result.customerName,
        charge_amount: +currency(rejectData.result.chargeAmount, { fromCents: true }),
        rejection_commission_amount: +currency(rejectData.result.rejectionCommissionAmount, { fromCents: true }),
        rejection_reason_code: rejectData.result.rejectionReasonCode,
        rejection_reason_text: rejectData.result.rejectionReasonText
      }))

      else return false
    }


    async function unregisterEntities() {
      const data = {
        entities: self.entitiesList
      }
      const response = await requests.setEntitiesRegistrationStatus(self, self.org, 0, data)
      if (response.success) return response.data
      else return false
    }

    async function registerEntities() {
      const data = {
        entities: self.entitiesList
      }
      const response = await requests.setEntitiesRegistrationStatus(self, self.org, 1, data)
      if (response.success) return response.data
      else return false
    }


    async function setBankOrderStatus(status) {
      const data = {
        data: {
          entities: self.bankOrdersEntitiesList,
          status: status
        }
      }
      const response = await requests.setBankOrderStatus(self, self.org, data)
      if (response.success) return response.data
      else return false
    }

    async function getMasavDocsToTransmit() {
      const data = {
        org: self.org,
        period: [self.masavTransmittedFilesDate.getFullYear(), self.masavTransmittedFilesDate.getMonth() + 1],
        status: 2

      }
      const response = await requests.getMasavDocsToTransmit(self, data)
      if (response.success) return response.data
      else return false
    }

    async function getMasavIncomesToTransmit() {
      const data = {
        org: self.org,
        period: [self.masavIncomesTransmittedFilesDate.getFullYear(), self.masavIncomesTransmittedFilesDate.getMonth() + 1],
        status: 2
      };

      const response = await requests.getMasavIncomesToTransmit(self, data)
      if (response.success)
        return response.data
      else
        return false
    }

    async function reloadMicroCopy() {
      const response = await requests.reloadMicroCopy(self)
      if (response.success === false) return false
      else return true
    }


    async function markMasavDocsAsTransmitted(docsIds) {
      const data = {
        org: self.org,
        docs: docsIds
      }
      const response = await requests.markMasavDocsAsTransmitted(self, data)
      return !!response.success
    }

    async function markMasavIncomesAsTransmitted(docsIds) {
      const data = {
        org: self.org,
        docs: docsIds
      }
      const response = await requests.markMasavIncomesAsTransmitted(self, data)
      return !!response.success
    }

    async function markMasavDocsPeriodAsTransmitted() {
      const filesDocs = await getMasavDocsToTransmit()
      if (!filesDocs) return false
      const filesDocsIds = []
      for (const fileDoc of filesDocs) {
        filesDocsIds.push(fileDoc.id)
      }
      return markMasavDocsAsTransmitted(filesDocsIds)
    }

    async function markMasavIncomesPeriodAsTransmitted() {
      const filesDocs = await getMasavIncomesToTransmit()
      if (!filesDocs) return false
      const filesDocsIds = []
      for (const fileDoc of filesDocs) {
        filesDocsIds.push(fileDoc.id)
      }
      return markMasavIncomesAsTransmitted(filesDocsIds)
    }

    async function getRoles() {
      const rolesRes = await requests.getRoles(self)
      self.setRoles(rolesRes.data)
    }

    async function getRegisterMembers() {
      const membersRes = await requests.getRegisterMembers(self, self.org, true)
      const membersData = membersRes.data.map(doc => doc.data)
      return self.setRegisterMembers(membersData)
    }

    function setRegisterMembers(data) {
      self.registeredMembers = data
    }

    function setProcesses(processes) {
      self.processes = processes
    }

    async function getAllUsers() {
      const usersRes = await requests.getAllUsers(self, self.org)
      const users = usersRes.data && usersRes.data.length ? usersRes.data.map(user => ({
        completedAt: user.completedAt ? new Date(user.completedAt * 1000) : null,
        registrationStatus: user.registrationStatus,
        paymentMethod: user.paymentMethod
      })) : []
      if (users.length === 0) {
        alert(strings.noUsers)
      }
      self.setAllUsers(users)
      self.setNumberOfUsers(users?.length || 0)
    }

    function setAllUsers(users) {
      self.allUsers = users
    }

    function setNumberOfUsers(number) {
      self.numberOfUsers = number
    }

    // function pieChartUsers() {
    //   const registered = self.allUsers.filter(user => user.registrationStatus === 1)
    //   const notRegistered = self.allUsers.filter(user => user.registrationStatus !== 1)
    //   const pieChartUsers = [
    //     {name: 'Registered', value: registered.length},
    //     {name: 'Not Registered', value: notRegistered.length}
    //   ]
    //   return pieChartUsers
    // }

    const runOrgFlow = async (period, flowId, isRunForAllOrgs) => {
      try {
        const data = {
          manual_run: true,
          period: period
        }
        if (!isRunForAllOrgs) {
          data['orgs'] = [self.org]
        }
        const response = await requests.runFlow(self, flowId, data)
        return { success: response.success }
      } catch (err) {
        return { success: false }
      }
    }

    const runTask = async (process, body) => {
      if (process.scope === strings.processScope.org) { return await requests.runOrgTask(self, process.name, body) }
      if (process.scope === strings.processScope.system) { return await requests.runSystemTask(self, process.name, body) }
    }

    const runFlow = async (process, body) => {
      if (process.scope === strings.processScope.org) { return await self.runOrgFlow(body.period, process.key, false) }
      if (process.scope === strings.processScope.system) { return await requests.runSystemFlow(self, process.name, body) }
    }

    const runProcess = async (period, process, isRunForAllOrgs) => {
      try {
        const body = { period, is_manual_run: true }
        if (process.processType === strings.processType.task) {
          return await runTask(process, body)
        } else if (process.processType === strings.processType.flow) {
          return await runFlow(process, body, isRunForAllOrgs)
        }
      } catch (err) {
        return { success: false }
      }
    }

    const getProcesses = async () => {
      const response = await requests.getProcesses(self)
      self.setProcesses(response.data.processes)
    }

    const setAuthorized = (isAuthorized) => {
      self.isAuthorized = isAuthorized
    }
    const setReportForAllOrg = (reportForAllOrg) => {
      self.reportForAllOrg = reportForAllOrg
    }

    const getOrganizationsList = async () => {
      try {
        const res = await requests.getAllOrganizations(self)
        if (!res.success) {
          throw new Error(res.error?.detail)
        }
        self.setOrgList(res.data)
        return res
      } catch (err) {
        return { success: false, error: err }
      }
    }

    const setOrgList = (organizations) => {
      self.organizations = organizations
    }

    const changeDisabledButtons = (service, value) => {
      self.disabledButtons.set(service, value)
    }

    const setPosSystems = (posSystems) => {
      self.posSystems = posSystems.map(posSystem => ({ ...posSystem, id: posSystem.code }))
    }

    const createMock = async (period, posData, system) => {
      try {
        const body = {
          period: JSON.stringify(period),
          posData: posData,
          system: system
        }
        const res = await requests.createMock(self, self.org, body)
        if (res.success) {
          return { success: true }
        }
        else {
          throw new Error(res.error)
        }
      } catch (err) {
        return { success: false, error: err }
      }
    }
    const getAllFinancialBooks = async () => {
      const res = await requests.getAllFinancialBooks(self, self.org)
      if (res.success) {
        self.setFinancialBooks(res.data)
      }
      else {
        throw new Error(res.error)
      }
    }
    const setFinancialBooks = (financialBooks) => {
      self.financialBooks = financialBooks.map(financialBook => ({ ...financialBook, id: financialBook.data.code, name: financialBook.data.name.he }))
    }
    const setFSOrgId = (fsOrgId) => {
      self.fsOrgId = fsOrgId
    }
    return {
      getUserDetails,
      setLoading,
      setAuth,
      setUserDetails,
      setHistory,
      setMemberId,
      setOrg,
      setOrgData,
      setError,
      setIsLoading,
      setOrgName,
      setPaymentData,
      sendAdminLink,
      updateOrgDefinition,
      updateGlobalDefinition,
      getOrgDefinitions,
      getGlobalDefinitions,
      setDefList,
      setGlobalDefList,
      getSingleDef,
      setMasavTransmittedFilesDate,
      setMasavIncomesTransmittedFilesDate,
      setBanksOrdersPdfsDate,
      setBankChargesRejectedFilterDates,
      setChargesAmountsReportDate,
      setMasavChargesDate,
      setMasavIncomesDate,
      setGetPaymentsByMothDate,
      markMasavDocsPeriodAsTransmitted,
      markMasavIncomesPeriodAsTransmitted,
      getRoles,
      setRoles,
      setProcesses,
      getAllUsers,
      setAllUsers,
      setNumberOfUsers,
      runProcess,
      getChargesAmounts,
      getMasavFiles,
      getPaymentsMonth,
      getBankChargesRejected,
      getProcesses,
      unregisterEntities,
      setEntitiesList,
      setBankOrdersEntitiesList,
      getRegisterMembers,
      setRegisterMembers,
      setBankOrderStatus,
      registerEntities,
      generateBanksOrdersPdfs,
      tryLogin,
      setMemberCommunicationsChannels,
      verifyOtp,
      initApp,
      setAuthorized,
      reloadMicroCopy,
      getOrganizationsList,
      setOrgList,
      deleteOrgDefinition,
      loadApp,
      changeDisabledButtons,
      runOrgFlow,
      setPosSystems,
      createMock,
      setReportForAllOrg,
      getAllFinancialBooks,
      setFinancialBooks,
      getOrgName,
      getFsOrg,
      addFSOrg,
      setFSOrgId,
      setFsOrg,
      runTask,
    }
  })
  .views((self) => ({
    get pieChartRegisteredUsers() {
      const registered = self.allUsers.filter(user => user.registrationStatus === 1)
      const notRegistered = self.allUsers.filter(user => user.registrationStatus !== 1)
      const pieChartUsers = [
        { id: 'Registered', label: 'Registered', value: registered.length, color: "#52D726" },
        { id: 'Not Registered', label: 'Not Registered', value: notRegistered.length, color: "#FF0000" }
      ]
      return pieChartUsers
    },
    get pieChartPaymentMethodUsers() {
      const creditCard = self.allUsers.filter(user => user.paymentMethod === 'credit_card')
      const banks = self.allUsers.filter(user => user.paymentMethod === 'bank_account')
      const paymentMethod = [
        { id: 'Bank', label: 'Bank', value: banks.length, color: "#007ED6" },
        { id: 'Credit Card', label: 'Credit Card', value: creditCard.length, color: "#7CDDDD" }
      ]
      return paymentMethod
    },
    get recipient() {
      const values = Object.values(self.memberCommunicationsChannels);
      if (values.length > 0) {
        return values[0];
      }
      return undefined;
    },
    get memberCommunicationChannel() {
      const keys = Object.keys(self.memberCommunicationsChannels);
      if (keys.length > 0) {
        return keys[0];
      }
      return undefined
    }
    // get lineChartUsers() {
    //   const users = self.allUsers.map(user => {
    //     let date
    //     if (user.completedAt) {
    //       date = `${user.completedAt.getDate()}/${user.completedAt.getMonth() -1}/${user.completedAt.getFullYear()}`
    //     }
    //     return {
    //       date,
    //       number: 1
    //     }
    //   })
    //   return users
    // }

  }))

const userStore = UserStore.create({
  memberId: "",
  firstName: "",
  lastName: "",
  org: vls.get('org') || "",
  entity: "",
  error: null,
  orgName: "",
  isAuth: true,
  isAuthorized: false,
  PaymentData: null,
  orgData: null,
  defList: [],
  globalDefList: [],
  singleDef: null,
  singleGlobalDef: null,
  masavTransmittedFilesDate: new Date(),
  banksOrdersPdfsDate: null,
  chargesAmountsReportDate: new Date(),
  masavChargesDate: new Date(),
  masavIncomesDate: new Date(),
  getPaymentsByMothDate: new Date(),
  allUsers: [],
  numberOfUsers: 0,
  memberCommunicationsChannels: [],
  organizations: [],
  disabledButtons: {},
  reportForAllOrg: false,
  fsOrgId: 0
})

export default userStore
