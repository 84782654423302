import React, { useEffect, useState } from 'react'
import {
  Edit,
  useTranslate,
  useRedirect,
  useNotify,
  SaveButton,
  Toolbar,
  DeleteButton,
} from 'react-admin'
import { observer, inject } from 'mobx-react'
import { requests } from 'fetch/requests'
import PosForm from './posForm'
import { withStyles, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import DatePicker from '../utils/datePicker'
import { currentPeriod } from '../../tools/tools'
import styled from "styled-components"

const StyledCancelButton = styled(Button)`
  margin-left: 40px;
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
  }
}

const PeriodDialog = ({ open, setPeriod, setOpenDialog, confirmDialog }) => {

  const translate = useTranslate()

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleConfirm = () => {
    handleClose()
    confirmDialog()
  }

  return (
    <div dir='rtl'>
      <Dialog disableEnforceFocus open={open} onClose={handleClose} dir='rtl'>
        <DialogContent>
          <DialogContentText>{translate("customRoot.tasks.choose_year_month")}</DialogContentText>
          <DatePicker handleDateChange={setPeriod} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate("customRoot.orders.modal.cancel")}
          </Button>
          <Button onClick={handleConfirm} color='primary'>
            {translate("customRoot.orders.modal.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const PosEditToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/pos');
  }
  return (<Toolbar {...props} >
    <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
      {translate("customRoot.definitions.edit.cancel_button")}
    </StyledCancelButton>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
  );
})

const PosEdit = ({ userStore, ...props }) => {

  let period = currentPeriod()

  const [openPeriodDialog, setOpenPeriodDialog] = useState(false)
  const [record, setRecord] = useState(false)

  const translate = useTranslate()

  const setPeriod = (date) => {
    period = [date.getFullYear(), date.getMonth() + 1]
  }

  useEffect(() => {
    async function fetchData() {
      if (!userStore.posSystems.length) {
        const result = await requests.getPosSystems(userStore)
        userStore.setPosSystems(result.data)
      }
    }
    fetchData();
  }, [userStore])

  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = ({ data }) => {
    notify(translate('customRoot.pos.edited_success_message'), { type: 'success' })
    redirect('/pos');
  }

  const onFailure = (error) => {
    notify(translate('customRoot.pos.edited_failed_message'), { type: 'warning' })
    redirect('/pos');
  }
  const onCreateMockBtnClick = (event, record) => {
    if (!record) return
    if (record.system !== '10') {
      notify(translate('customRoot.general.not_supported'), { type: 'warning' })
      return
    }
    setRecord(record)
    setOpenPeriodDialog(true)
  }

  const createMock = async () => {
    const system = userStore.posSystems.find(p => p.code === record.system)
    const res = await userStore.createMock(period, record, system?.name?.toLowerCase())
    if (res.success) {
      notify(translate('customRoot.general.create_success'), { type: 'success' })
    } else {
      notify(translate('customRoot.general.create_failed'), { type: 'warning' })
    }
  }

  return (
    <>
      <Edit {...props} undoable={false} onSuccess={onSuccess} onFailure={onFailure} title={translate('customRoot.pos.edit_title')}>
        <PosForm redirect="list" toolbar={<PosEditToolbar />} showCreateMockBtn={true} onCreateMockBtnClick={onCreateMockBtnClick} />
      </Edit>
      <PeriodDialog
        open={openPeriodDialog}
        setOpenDialog={setOpenPeriodDialog}
        setPeriod={setPeriod}
        confirmDialog={() => createMock(record)}
      />
    </>

  )
}
export default inject("userStore")(observer(PosEdit))
