import { types } from "mobx-state-tree"
import strings from "../tools/strings"
import userStore from './userStore';
import { currentPeriod } from '../tools/tools'
import { PERIOD_FORMAT, FLOW } from 'clearing-types';

const Folws = types.model('Folws', {
    key: types.frozen(),
    order: types.frozen(),
})
const SourceTask = types.model('SourceTask', {
    allowManualRun: types.frozen(),
    defaultPeriod: types.frozen(),
    flows: types.array(Folws),
    id: types.maybe(types.string),
    key: types.frozen(),
    loopbackModelName: types.maybe(types.string),
    name: types.maybe(types.string),
    periodFormat: types.maybe(types.string),
    processType: types.maybe(types.string),
    rev: types.maybe(types.string),
    scope: types.maybe(types.string),
    type: types.maybe(types.string),
    isChecked: types.optional(types.boolean, false)

})
const SourceFlow = types.model('SourceFlow', {
    allowManualRun: types.frozen(),
    defaultPeriod: types.frozen(),
    id: types.maybe(types.string),
    key: types.frozen(),
    loopbackModelName: types.maybe(types.string),
    name: types.maybe(types.string),
    periodFormat: types.maybe(types.string),
    processType: types.maybe(types.string),
    rev: types.maybe(types.string),
    scope: types.maybe(types.string),
    tasks: types.array(SourceTask),
    type: types.maybe(types.string),
    isChecked: types.optional(types.boolean, false)

})
const TasksStore = types.model('TasksStore', {
    tasks: types.array(types.frozen()),
    flows: types.array(SourceFlow),
    period: types.frozen(),
    isOpenPeriodDialog: types.boolean,
    processSelected: SourceFlow
})
    .views((self) => ({
        get periodData() {
            return JSON.parse(JSON.stringify(self.period))
        },
        get tasksList() {
            return JSON.parse(JSON.stringify(self.tasks))
        },
        get flowsList() {
            return JSON.parse(JSON.stringify(self.flows))
        },
    }))
    .actions(self => {
        async function afterCreate() {
            await userStore.getProcesses()
            if (userStore.processes) {
                self.prepareTasks()
                self.prepareFlowsTasks()
            }
        }

        const prepareTasks = async () => {
            userStore.processes.forEach(process => {
                if (process.processType !== strings.processType.task) {
                    return
                }
                if (!process.allowManualRun) {
                    return
                }
                const isFlowTask = process.flows && process.flows.length > 0
                if (isFlowTask) {
                    return
                }
                self.tasks.push({ ...process })
            })
        }

        const prepareFlowsTasks = async () => {
            const flowsTasksMap = self.createFlowsTasksMap()
            userStore.processes.forEach(process => {
                if (process.name === FLOW.BANKS_ORDERS_FLOW) {
                    return
                }
                if (process.processType === strings.processType.flow) {
                    process.tasks = flowsTasksMap[process.key]
                    process.isChecked = false
                    if (process.tasks) self.flows.push({ ...process })
                }
            })
        }

        const createFlowsTasksMap = () => {
            const flowsTasksMap = {}
            userStore.processes.forEach(process => {
                if (process.processType !== strings.processType.task) {
                    return
                }
                const isFlowTask = (process.flows && process.flows.length > 0)
                if (!isFlowTask) {
                    return
                }
                const flowTask = { ...process }
                flowTask.isChecked = false
                process.flows.forEach(flow => {
                    if (flowsTasksMap[flow.key] === undefined) {
                        flowsTasksMap[flow.key] = []
                    }
                    if (flowsTasksMap[flow.key][flow.order - 1]) {
                        throw new Error(`Duplicate tasks orders in flow. flow = ${flow.key}, duplicate tasks = ${flowTask.key}, ${flowsTasksMap[flow.key][flow.order - 1].key}`);
                    }
                    flowsTasksMap[flow.key][flow.order - 1] = { ...flowTask }
                })
            })
            for (let flowKey in flowsTasksMap) {
                const tasksArray = flowsTasksMap[flowKey]
                for (let task of tasksArray) {
                    if (!task) {
                        throw new Error(`Tasks orders in flow are not consecutive numbers, flow = ${flowKey}`)
                    }
                }
            }
            return flowsTasksMap
        }

        const getProcessChecked = () => {
            let processChecked
            for (let flow of self.flows) {
                if (flow.isChecked) {
                    processChecked = flow
                    break
                }
                const taskChecked = flow.tasks.find(task => task.isChecked)
                if (taskChecked !== undefined) {
                    processChecked = taskChecked
                    break
                }
            }
            return processChecked
        }

        const setPeriod = (date) => {
            if (self.getProcessChecked()?.periodFormat === PERIOD_FORMAT.DAY) {
                self.period = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
            } else {
                self.period = [date.getFullYear(), date.getMonth() + 1]
            }
        }

        const setOpenPeriodDialog = (isOpenPeriodDialog) => {
            self.isOpenPeriodDialog = isOpenPeriodDialog
        }

        const setUncheckAll = () => {
            self.flows.forEach((flow, index) => {
                flow.isChecked = false
                flow.tasks.forEach(task => {
                    task.isChecked = false
                })
            })
        }

        const setFlowChange = (index, checkState) => {
            if (checkState) self.setUncheckAll()
            self.flows[index].isChecked = checkState
            self.flows[index].tasks.forEach(task => {
                task.isChecked = checkState
            })
        }

        const setTaskChange = (flowIndex, taskIndex, checkState) => {
            if (checkState) self.setUncheckAll()
            self.flows[flowIndex].tasks[taskIndex].isChecked = checkState
        }
        const getDialogTitle = (translate) => {
                if (self.processSelected.processType === strings.processType.flow) {
                    return translate(`customRoot.scheduleFlows.${self.processSelected.name}`)
                }
                else if (self.processSelected.processType === strings.processType.task) {
                    return translate(`customRoot.scheduleTasks.${self.processSelected.name}`)
                }
        }
        const sendRequest = async (notify, getTasks) => {
            const runResult = await userStore.runProcess(self.periodData, self.processSelected)
            if (runResult.success) notify("customRoot.requestResult.success", "success")
            else notify("customRoot.requestResult.error", "warning")
        }
        const setProcessSelected = (processSelected) => {
            self.processSelected = processSelected
        }


        return {
            afterCreate,
            prepareTasks,
            prepareFlowsTasks,
            createFlowsTasksMap,
            setPeriod,
            setOpenPeriodDialog,
            getProcessChecked,
            setUncheckAll,
            setFlowChange,
            setTaskChange,
            getDialogTitle,
            sendRequest,
            setProcessSelected

        }
    })

const tasksStore = TasksStore.create({
    tasks: [],
    flows: [],
    period: currentPeriod(),
    isOpenPeriodDialog: false,
    processSelected: {}
})

export default tasksStore

