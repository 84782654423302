import React, { Component } from "react"
import { Admin, Resource } from "react-admin"
import { Provider as MobxProvider } from "mobx-react"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import SettingsIcon from "@material-ui/icons/Settings"
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GroupIcon from "@material-ui/icons/Group"
import { orgList, orgEdit, orgCreate } from "./components/org"
import { CorporationsList, CorporationEdit, CorporationCreate } from "./components/financial_structures/corporation"
import { InstitutionCreateAndEdit } from "./components/financial_structures/institutions"
import { FinancialBookCreateAndEdit } from "./components/financial_structures/financial_books"
import { FinancialCompanyCreateAndEdit } from "./components/financial_structures/financial_companies"
import { definitionList, globalDefinitionList } from "./components/definitions"
import { restProvider, layout, addUploadFeature, customRoutes, Resource as ResourceEnum } from "./core"
import { Dashboard } from "./components/main"
import polyglotI18nProvider from "ra-i18n-polyglot"
import { he, en } from "./i18n"
import { createTheme, StylesProvider, jssPreset } from "@material-ui/core/styles"
import { create } from "jss"
import rtl from "jss-rtl"
import authProvider from "./core/authProvider"
import { LoginPage } from "./components/login"
import { usersList, userEdit } from "./components/users"
import { financeSystemsList, financeSystemEdit, financeSystemCreate } from "./components/finance"
import { roleList } from "./components/roles"
import * as stores from "./stores"
import CreditCardGatewaysCreateAndEdit from "components/financial_structures/creditCardGateways/CreditCardGatewaysCreateAndEdit";
import { posList, posEdit, PosCreate } from './components/pos'
import { sourceAttachmentsList, sourceAttachmentsEdit, sourceAttachmentsCreate } from './components/sourceAttachments'
import { JobList, JobShow } from './components/job'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import AttachFile from '@material-ui/icons/AttachFile'
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { getEnvThemeColor } from "./tools/tools";

const uploadCapableDataProvider = addUploadFeature(restProvider)
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const messages = {
  he,
  en
}
const i18nProvider = polyglotI18nProvider(locale => (messages[locale] ? messages[locale] : messages.he), "he")

const theme = createTheme({
  direction: "rtl",
  palette: getEnvThemeColor(),
})
class App extends Component {
  render() {
    return (
      <MobxProvider {...stores}>
        <StylesProvider jss={jss}>
          <Admin theme={theme} i18nProvider={i18nProvider} loginPage={LoginPage} customRoutes={customRoutes} layout={layout} dashboard={Dashboard} dataProvider={uploadCapableDataProvider} authProvider={authProvider}>
            <Resource options={{ label: "customRoot.mainRes.organizations" }} name={ResourceEnum.ORGANIZATIONS} list={orgList} edit={orgEdit} create={orgCreate} icon={AccountTreeIcon} />
            <Resource options={{ label: "customRoot.mainRes.corporations" }} name={ResourceEnum.CORPORATIONS} list={CorporationsList} edit={CorporationEdit} create={CorporationCreate} icon={AccountBalanceIcon} />
            <Resource name={ResourceEnum.ROLES} list={roleList} icon={AccountBoxIcon} />
            <Resource name={ResourceEnum.GLOBAL_DEFINITIONS} list={globalDefinitionList} edit={globalDefinitionList} icon={SettingsIcon} />
            <Resource name={ResourceEnum.DEFINITIONS} list={definitionList} edit={definitionList} icon={SettingsIcon} />
            <Resource name={ResourceEnum.USERS} list={usersList} edit={userEdit} icon={GroupIcon} />
            <Resource name={ResourceEnum.SYSTEMS} list={financeSystemsList} edit={financeSystemEdit} create={financeSystemCreate} icon={AttachMoneyIcon} />
            <Resource name={ResourceEnum.POS} list={posList} edit={posEdit} create={PosCreate} icon={PointOfSaleIcon} />
            <Resource name={ResourceEnum.INSTITUTION} edit={InstitutionCreateAndEdit} create={InstitutionCreateAndEdit} />
            <Resource name={ResourceEnum.CREDIT_CARD_GATEWAYS} edit={CreditCardGatewaysCreateAndEdit} create={CreditCardGatewaysCreateAndEdit} />
            <Resource name={ResourceEnum.FINANCIAL_BOOK} edit={FinancialBookCreateAndEdit} create={FinancialBookCreateAndEdit} />
            <Resource name={ResourceEnum.FINANCIAL_COMPANY} edit={FinancialCompanyCreateAndEdit} create={FinancialCompanyCreateAndEdit} />
            <Resource name={ResourceEnum.SOURCE_ATTACHMENTS} list={sourceAttachmentsList} edit={sourceAttachmentsEdit} create={sourceAttachmentsCreate} icon={AttachFile} />
            <Resource name={ResourceEnum.JOB_MANAGER} list={JobList} icon={ViewModuleIcon} show={JobShow} />
          </Admin>
        </StylesProvider>
      </MobxProvider>
    )
  }
}

export default App
