import React, { useState, useEffect } from "react"

import { observer, inject } from "mobx-react"
import { useTranslate } from "react-admin"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import DatePicker from "../utils/datePicker"
import RangeDatePicker from "../utils/rangeDatePicker"
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { addDays } from 'date-fns';
import strings from '../../tools/strings'
import { previousPeriod } from '../../tools/tools'


const FormBody = inject('userStore')(observer(({ userStore, type, handleDateChangeFunc, showAllOrgsSetting }) => {
  const translate = useTranslate()

  const reportForAllOrgsChange = () => {
    userStore.setReportForAllOrg(!userStore.reportForAllOrg)
  }
  switch (type) {
    case strings.reports.BANKS_ORDERS_PDFS:
      return (
        <div>
          <RangeDatePickerForm
            type={type}
            handleDateChangeFunc={handleDateChangeFunc}
          />
        </div>

      )
    case strings.reports.CHARGES_AMOUNTS:
      return (
        <div>
          <DialogTitle id='form-dialog-title'>{translate(`customRoot.reports.${type}`)}</DialogTitle>
          <DialogContent>
            <DialogContentText>{translate("customRoot.reports.choose_date")}</DialogContentText>
            <DatePicker handleDateChange={handleDateChangeFunc} />
          </DialogContent>
        </div>
      )
    case strings.reports.DOWNLOAD_MASAV_CHARGES:
    case strings.reports.DOWNLOAD_MASAV_INCOMES:
      return (
        <div>
          <DialogTitle id='form-dialog-title'>{translate(`customRoot.reports.${type}`)}</DialogTitle>
          <DialogContent>
            <DialogContentText>{translate("customRoot.reports.choose_date")}</DialogContentText>
            <DatePicker handleDateChange={handleDateChangeFunc} date={previousPeriod()} />
          </DialogContent>
        </div>
      )

    case strings.reports.PAYMENTS_BY_MONTH:
      return (
        <div>
          <DialogTitle id='form-dialog-title'>{translate(`customRoot.reports.${type}`)}</DialogTitle>
          <DialogContent>
            <DialogContentText>{translate("customRoot.reports.choose_date")}</DialogContentText>
            <DatePicker handleDateChange={handleDateChangeFunc} />
            {showAllOrgsSetting ?
              <FormControlLabel
                control={<Checkbox
                  checked={userStore.reportForAllOrg}
                  onChange={reportForAllOrgsChange} />}
                label={translate("customRoot.isRunManualForAllOrgs")}
              />
              : null}
          </DialogContent>
        </div>
      )

    case strings.reports.BANK_CHARGES_REJECTED:
      return (
        <div>
          <RangeDatePickerForm
            type={type}
            handleDateChangeFunc={handleDateChangeFunc}
            disableAllDate={true}
          />

        </div>

      )

    default:
      return <div />
  }
}))

const ReportsFactory = ({ userStore, open, setOpen, type, generateReport, handleDateChangeFunc, showAllOrgsSetting }) => {
  const translate = useTranslate()

  useEffect(() => {
    handleDateChangeFunc(null)
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    generateReport()
  }

  return (
    <div dir='rtl'>
      <Dialog disableEnforceFocus open={open} onClose={handleClose} aria-labelledby='form-dialog-title' dir='rtl'>
        <FormBody type={type} handleDateChangeFunc={handleDateChangeFunc} showAllOrgsSetting={showAllOrgsSetting} />
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate("customRoot.orders.modal.cancel")}
          </Button>
          <Button onClick={handleConfirm} color='primary'>
            {translate("customRoot.orders.modal.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


const RangeDatePickerForm = ({ type, handleDateChangeFunc, disableAllDate }) => {

  const translate = useTranslate()
  const [isAllDates, setAllDates] = useState(disableAllDate ? false : true)

  const defaultStartDate = new Date()
  defaultStartDate.setHours(0, 0, 0, 0)
  const defaultEndDate = addDays(defaultStartDate, 1)

  handleDateChangeFunc([defaultStartDate, defaultEndDate])

  const checkboxDateChangeFunc = () => {
    if (!isAllDates) {
      handleDateChangeFunc(null)
    } else {
      handleDateChangeFunc([defaultStartDate, defaultEndDate])
    }
  }


  return (
    <div>
      <DialogTitle id='form-dialog-title'>{translate(`customRoot.reports.${type}`)}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={<Checkbox
            checked={isAllDates}
            disabled={disableAllDate}
            onChange={() => { setAllDates(!isAllDates); checkboxDateChangeFunc(); }} />}
          label={translate("customRoot.reports.isAllDates")}
        />
        <RangeDatePicker disabled={isAllDates} handleDateChange={handleDateChangeFunc}
          defaultStartDate={defaultStartDate} defaultEndDate={defaultEndDate} />
      </DialogContent>
    </div>
  )
}


export default inject("userStore")(observer(ReportsFactory))
