// in ./ResetViewsButton.js
import React from "react"
import { Button, useTranslate, useNotify } from "react-admin"
import { observer, inject } from "mobx-react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`

const SendLink = ({ userStore, ...props }) => {
  const { record } = props
  const translate = useTranslate()
  const notify = useNotify()

  const handleClick = async () => {
    try {
      const subject = translate("customRoot.users.link.subject")
      const template = translate("customRoot.users.link.template")
      await userStore.sendAdminLink(subject, template, [record.id], userStore.org)
      notify("customRoot.users.link.success", "success")
    } catch (error) {
      notify("customRoot.users.link.error", "warning")
    }
  }

  return (
    <Container>
      <Button variant='contained' color='primary' label={translate("customRoot.users.link.link")} onClick={handleClick} />
    </Container>
  )
}

export default inject("userStore")(observer(SendLink))
