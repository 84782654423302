// in ./ResetViewsButton.js
import React from "react"
import { Button, useTranslate } from "react-admin"
import { observer, inject } from "mobx-react"
import styled from "styled-components"
import strings from "../../tools/strings"

const { adminUrl, appUrl } = strings

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`

const CreateLink = ({ userStore, ...props }) => {
  const { type, record } = props
  const translate = useTranslate()

  const handleClick = async () => {
    if (type === "budget") window.open(`${appUrl}/budget/${record.id}`)
    else window.open(`${adminUrl}/auth/${record.member_id}`) //TODO why belong to member id
  }

  return (
    <Container>
      <Button
        variant='contained'
        color='primary'
        label={translate("customRoot.users.link.button", { link: type === "admin_org" ? "אדמין" : "תקציב" })}
        onClick={handleClick}
      />
    </Container>
  )
}

export default inject("userStore")(observer(CreateLink))
