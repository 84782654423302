import React, { useState } from "react"
import { observer, inject } from "mobx-react"
import Grid from "@material-ui/core/Grid"
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { he } from "date-fns/locale"
import { DatePicker as DatePickerUI, LocalizationProvider } from "@material-ui/pickers"
import styled from "styled-components"
import TextField from '@material-ui/core/TextField';


const DateContainer = styled.div`
  margin-left: 50px;
`


const DatePicker = ({ handleDateChange, disabled, format, date }) => {
  const [fromDate, setFromDate] = useState(date || new Date())
  handleDateChange(fromDate)
  return (

    <LocalizationProvider locale={he} dateAdapter={DateFnsUtils}>
      <Grid container justify='space-around'>
        <DateContainer>
          <DatePickerUI
            disabled={disabled}
            views={format === 'full' ? ["year", "month", "date"] : ["year", "month"]}
            value={fromDate}
            onChange={(date) => {
              const dateUTC = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
              setFromDate(dateUTC); handleDateChange(dateUTC)
            }}
            renderInput={(props) => <TextField {...props} helperText={null} variant="standard" />}
          />
        </DateContainer>
      </Grid>
    </LocalizationProvider>

    // <MuiPickersUtilsProvider locale={he} utils={DateFnsUtils}>
    //   <Grid container justify='space-around'>
    //     <DateContainer>
    //       <KeyboardDatePicker
    //         disableToolbar
    //         disabled={disabled}
    //         disableFuture
    //         variant='inline'
    //         format=  { format === 'full' ? 'dd/MM/yyyy' : 'MM/yyyy' }
    //         margin='normal'
    //         id='date'
    //         value={fromDate}
    //         onChange={date => { handleDateChange(date); setFromDate(date)}}
    //         autoOk
    //         views={["year", "month","date"]}
    //         KeyboardButtonProps={{
    //           "aria-label": "change date"
    //         }}
    //       />
    //     </DateContainer>
    //   </Grid>
    // </MuiPickersUtilsProvider>
  )
}

export default inject("userStore")(observer(DatePicker))
