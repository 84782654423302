import React, { useEffect } from "react"
import { inject, observer } from 'mobx-react'
import { Filter, SelectInput, DateInput } from "react-admin"

      
const JobFilter = ({ userStore, jobStore, ...props }) => {
  const organizations =  userStore.organizations.toJSON();
  const scopes = jobStore.scopes.toJSON();

  useEffect(() => {
    jobStore.getScopes().catch(console.error);
  }, [])

  return (
    <Filter {...props}>
      <SelectInput source="type" label="customRoot.jobs.list.type" choices={[
        { id: 'fetch_payments', name: 'Fetch Payments' },
        { id: 'fetch_pos_payments', name: 'Fetch Pos Payments' },
        { id: 'charge_receipts', name: 'Charge Receipts' }
      ]} />
      <SelectInput source="status" label="customRoot.jobs.list.status" choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'in_progress', name: 'In Progress' },
        { id: 'finished', name: 'Finished' },
        { id: 'failed', name: 'Failed' },
        { id: 'cancelled', name: 'Cancelled' },
        
      ]} />
      <SelectInput source="org" label='customRoot.jobs.list.input_data' choices={organizations.map(o => ({ id: o.id, name: o.id }))} />
      <SelectInput source="scope" label="customRoot.jobs.list.scope" choices={scopes.map(s => ({ id: s.id, name: s.name }))} />
      <DateInput source="date" label='customRoot.jobs.list.created_at' />
    </Filter>
  )
}

export default inject("userStore", "jobStore")(observer(JobFilter))
