import React from "react"
import { observer, inject } from "mobx-react"
import { useTranslate } from "react-admin"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import DatePicker from "../../utils/datePicker"
import { PERIOD_FORMAT, DefaultPeriod } from 'clearing-types';
import { previousPeriod } from '../../../tools/tools'

const useStyles = makeStyles(() => ({
  danger: {
    color: "red",
    fontSize: 20
  }
}))

const PeriodDialog = ({ open, setOpenDialog, dialogTitle, confirmDialog, setPeriod, format, defaultPeriod }) => {

  const translate = useTranslate()

  const classes = useStyles()

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleConfirm = () => {
    handleClose()
    confirmDialog()
  }

  const getDate = () => {
    switch (defaultPeriod) {
      case DefaultPeriod.CURRENT:
        return new Date()
      case DefaultPeriod.LAST:
        return previousPeriod()
    }
  }

  return (
    <div dir='rtl'>
      <Dialog disableEnforceFocus open={open} onClose={handleClose} aria-labelledby='form-dialog-title' dir='rtl'>
        <DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
        <DialogContent>
          {format === PERIOD_FORMAT.DAY ?
            <DialogContentText>{translate("customRoot.tasks.choose_date")}</DialogContentText> :
            <DialogContentText>{translate("customRoot.tasks.choose_year_month")}</DialogContentText>
          }
          <DatePicker handleDateChange={setPeriod} format={format === PERIOD_FORMAT.DAY ? 'full' : null} date={getDate()} />
          <DialogContentText className={classes.danger}>{translate("customRoot.tasks.danger")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate("customRoot.orders.modal.cancel")}
          </Button>
          <Button onClick={handleConfirm} color='primary'>
            {translate("customRoot.orders.modal.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject("userStore")(observer(PeriodDialog))
