import React from 'react'
import {
  Create,
  useTranslate,
  useRedirect,
  useNotify,
  SaveButton,
  Toolbar,
} from 'react-admin'
import { observer, inject } from 'mobx-react'
import styled from "styled-components"
import { withStyles, Button } from '@material-ui/core'
import SourceAttachmentsForm from './sourceAttachmentsForm'
const StyledCancelButton = styled(Button)`
  margin-left: 40px
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: "center",
  }
}
const SourceAttachmentsToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/source_attachments');
  }
  return (
    <Toolbar {...props} >
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
      <SaveButton />
    </Toolbar>
  )
})
const SourceAttachmentsCreate = ({ userStore,  ...props }) => {

  const translate = useTranslate()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = ({ data }) => {
    notify(translate('customRoot.sourceAttachments.created_success_message'), { type: 'success' })
    redirect('/source_attachments');
  }

  const onFailure = (error) => {
    notify(translate('customRoot.sourceAttachments.created_failed_message'), { type: 'warning' })
    redirect('/source_attachments');
  }

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} title={translate('customRoot.sourceAttachments.create_title')}>
      <SourceAttachmentsForm redirect="list" toolbar={<SourceAttachmentsToolbar />}/>
    </Create>
  )
}

export default inject('userStore')(observer(SourceAttachmentsCreate))
