import React, {  } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin"


const RoleCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source='alias' label='customRoot.organizations.list.org_name' />
      </SimpleForm>
    </Create>
  )
}
export default RoleCreate
