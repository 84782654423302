import React from "react"
import { EditButton, useTranslate, useRedirect, Button } from "react-admin"
import styled from "styled-components"
import { observer, inject } from "mobx-react"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Add } from '@material-ui/icons'

const StyledTitle = styled.div`
    padding:15px;
    font-weight:bold;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const InstitutionsItem = ({ institution }) => {
  const redirect = useRedirect();
  const code = institution.data.code
  const onEdit = () => {
    if (code) {
      redirect(`/credit_card_gateways/${code}`);
    }
  }
  let name = ''
  let business_number = ""
  // let type = ""
  // let private_key = ""
  let public_key = ""
  if (institution) {
    name = institution.data.name
    // private_key = institution.data.private_key
    public_key = institution.data.public_key
    business_number = institution.data.business_number
    // type = institution.sub_type === 'institution_debit' ? translate('customRoot.definitions.edit.institution.institution_debit') : institution.sub_type === 'institution_credit' ? translate('customRoot.definitions.edit.institution.institution_credit') : 'not valid!'
  }
  return (
    <TableRow key={business_number}>
      <TableCell align="left">{name.he}</TableCell>
      <TableCell align="left">{name.en}</TableCell>
      <TableCell align="left">{business_number}</TableCell>
      {/* <TableCell align="left">{private_key}</TableCell> */}
      <TableCell align="left">{public_key}</TableCell>
      <TableCell align="center"><EditButton onClick={onEdit} label="" /></TableCell>
    </TableRow>
  )
}

const InstitutionsHeader = () => {
  const translate = useTranslate()
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.credit_card_gateways.name.he')}</b></TableCell>
        <TableCell dir='ltr' align="left"><b>{translate('customRoot.definitions.edit.credit_card_gateways.name.en')}</b></TableCell>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.credit_card_gateways.business_number')}</b></TableCell>
        {/* <TableCell align="left"><b>{translate('customRoot.definitions.edit.credit_card_gateways.private_key')}</b></TableCell> */}
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.credit_card_gateways.public_key')}</b></TableCell>
        {/* <TableCell align="left"><b>{translate('customRoot.definitions.edit.credit_card_gateways.code')}</b></TableCell> */}
        <TableCell align="center"><b>{translate('ra.action.edit')}</b></TableCell>
      </TableRow>
    </TableHead>
  )
}

const InstitutionsList = ({ list }) => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const onCreate = () => {
    redirect('/credit_card_gateways/create');
  }
  return (
    <>
      <StyledTitle>{translate('customRoot.definitions.edit.credit_card_gateways.institutions')}</StyledTitle>
      <ButtonsContainer>
        <Button onClick={onCreate} title={'Create'}><Add /></Button>
      </ButtonsContainer>
      <Table aria-label="simple table">
        <InstitutionsHeader />
        <TableBody>
          {list.map(institution =>
          (
            <InstitutionsItem institution={institution} key={institution.data.code} />
          ))}
        </TableBody>
      </Table>

    </>
  )
}

export default inject("corporationStore")(observer(InstitutionsList))
