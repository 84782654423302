import React, { useEffect, useState } from "react"
import { observer, inject } from "mobx-react"
import { TextInput, useRedirect, useTranslate, useCreateController, SelectInput } from "react-admin"
import styled from "styled-components"
import { Form, useFormState } from "react-final-form"
import Button from "@material-ui/core/Button"
import { orgDefToArrayOfObjects } from "../../../tools/tools"
import { makeStyles } from "@material-ui/core/styles"
import WarningDialog from "../../utils/WarningDialog"
import arrayMutators from "final-form-arrays"
import { FinancialBooksList } from '../financial_books'
import { FinancialCompaniesList } from '../financial_companies'
import { corporationMembersTypes } from '../../../stores/corporationStore'
import InstitutionsList from '../institutions/InstitutionsList'
import CreditCardGatewaysList from "../creditCardGateways/CreditCardGatewaysList"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledDeleteButton = styled(Button)`
  background-color: red;
  margin-right: 50px;
`

const StyledCancelButton = styled(Button)`
  margin-right: 50px;
`
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const useStyles = makeStyles({
  width: {
    width: 600
  }
})

const CorporationInfo = ({ handleSave, deleteWarning, corporation }) => {
  const translate = useTranslate()
  const redirect = useRedirect();
  const handleCancel = () => {
    redirect('/corporations');
  }
  return (
    <>
      <SelectInput source={'data.members_type'} label='customRoot.definitions.edit.corporation_members_type' choices={corporationMembersTypes} />
      <TextInput source='data.name.he' label='customRoot.definitions.edit.corporation_name.he' />
      <TextInput dir='ltr' source='data.name.en' label='customRoot.definitions.edit.corporation_name.en' />
      <TextInput type='number' source='data.business_number' label='customRoot.definitions.edit.corporation_id' />
      <ButtonsContainer>
        <Button variant='contained' color='primary' type='submit' onClick={handleSave}>
          {translate("customRoot.definitions.edit.submit_button")}
        </Button>
        <StyledDeleteButton variant='contained' color='secondary' onClick={deleteWarning}>
          {translate("customRoot.definitions.edit.delete_button")}
        </StyledDeleteButton>
        <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
          {translate("customRoot.definitions.edit.cancel_button")}
        </StyledCancelButton>
      </ButtonsContainer>
    </>
  )
}
const CorporationStruct = ({ handleSave, deleteWarning, onDelete, version, corporation, creditCardGateways, institutions, financialBooks, financialCompanies }) => {
  return (
    <BodyContainer>
      {corporation &&
        <>
          <CorporationInfo handleSave={handleSave} deleteWarning={deleteWarning} corporation={corporation} />
          <FinancialCompaniesList list={financialCompanies} />
          <InstitutionsList list={institutions} />
          <CreditCardGatewaysList list={creditCardGateways} />
          <FinancialBooksList list={financialBooks} />
        </>
      }
    </BodyContainer>
  )
}
const WarningActions = { delete: "delete", skip: "skip" }

const CorporationEdit = ({ corporationStore, ...props }) => {
  const redirect = useRedirect();
  let { def, id: corporationCode } = props
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningContentTexts, setWarningContentTexts] = useState({ content: "", title: "" });
  const [warningAction, setWarningAction] = useState(WarningActions.skip);
  const classes = useStyles()
  let { version } = useCreateController(props)
  const [corporation, setCorporation] = useState(corporationStore.corporation)
  const [institutions, setInstitutions] = useState(corporationStore.currentInstitutions)
  const [creditCardGateways, setCreditCardGateways] = useState(corporationStore.currentCreditCardGateways)
  const [financialBooks, setFinancialBooks] = useState(corporationStore.financialBooks)
  const [financialCompanies, setFinancialCompanies] = useState(corporationStore.financialCompanies)
  useEffect(() => {
    (async function () {
      try {
        await corporationStore.getCorporationData(corporationCode)
        setFinancialBooks(corporationStore.currentFinancialBooks)
        setFinancialCompanies(corporationStore.financialCompanies)
        setInstitutions(corporationStore.currentInstitutions)
        setCreditCardGateways(corporationStore.currentCreditCardGateways)
        setCorporation(corporationStore.currentCorporation)
      } catch (e) {
        console.error(e);
      }
    })();
  }, [])

  def = orgDefToArrayOfObjects(def)

  const checkCorporationDataIsNotEmpty = (corporation) => {
    return corporation && corporation.data && corporation.data.name && corporation.data.name.he && corporation.data.name.en && corporation.data.business_number
  }
  const onSave = async (res) => {
    res.relations = []
    res.id = corporationCode
    return await corporationStore.updateCorporation(res)
  }
  const onDelete = async () => {
    return await corporationStore.deleteCurrentCorporation()
  }
  const onSuccess = async () => {
    setWarningOpen(false)
    redirect('/corporations');
  }
  const onFailed = async (error) => {
    setWarningContentTexts({ content: error, title: "customRoot.definitions.failureMessage" })
    setWarningOpen(true)
  }
  const handleClick = async (results) => {
    if (!checkCorporationDataIsNotEmpty(results)) {
      setWarningContentTexts({ content: "customRoot.definitions.missingData", title: "customRoot.definitions.failureMessage" })
      setWarningOpen(true)
      return
    }
    const res = await onSave(results)
    if (!res.success) {
      onFailed(res.error)
    }
    else {
      onSuccess()
    }
  }

  const handleWarningConfirm = async () => {
    if (warningAction === WarningActions.delete) {
      const res = await onDelete()
      if (!res.success) {
        onFailed(res.error)
      }
      else {
        onSuccess()
      }
      setWarningAction(WarningActions.skip)
    }
    else {
      setWarningOpen(false)
    }
  }

  const handleClose = () => {
    setWarningOpen(false)
  }

  const deleteWarning = () => {
    setWarningContentTexts({ content: "customRoot.definitions.deleteWarningContent", title: "customRoot.definitions.deleteWarningTitle" })
    setWarningAction(WarningActions.delete)
    setWarningOpen(true)
  }
  let name = { he: "", en: "" }
  let business_number = ""
  let members_type = ""
  if (corporation) {
    name = corporation.data.name
    business_number = corporation.data.business_number
    members_type = corporation.data.members_type
  }
  return (
    <Form
      onSubmit={handleClick}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      initialValues={{ data: { business_number, name, members_type } }}
      render={({ handleSubmit, submitting }) => (
        <FormContainer>
          <StyledForm onSubmit={handleSubmit} className={classes.width}>
            {corporation && <CorporationStruct handleSave={handleSubmit} deleteWarning={deleteWarning} onDelete={onDelete} version={version} corporation={corporation} creditCardGateways={creditCardGateways} institutions={institutions} financialBooks={financialBooks} financialCompanies={financialCompanies} />}
          </StyledForm>
          <WarningDialog open={warningOpen} handleClose={handleClose} handleConfirm={handleWarningConfirm} content={warningContentTexts.content} title={warningContentTexts.title} />
        </FormContainer>
      )} />
  )
}
export default inject("corporationStore")(observer(CorporationEdit))

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
}