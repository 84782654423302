import { ENDPOINTS, DOMAIN } from './constants'
import { snakeCase, camelCase } from "change-case"
import { keyConvert } from "@supersimplethings/fetcher"
import { METHOD, callHttpRequest } from 'clearing-types'

const options = {
    stripRegexp: /[^-A-Z0-9]+/gi
}

const toSnakeCase = keyConvert(input => snakeCase(input, options))
const toCamelCase = keyConvert(input => camelCase(input, options))

export const requests = {
    getMember: (store, memberId) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.user.get(memberId), null, res => resolve(toCamelCase(res)))
        })
    },
    sendMessage: (store, org, lang, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.user.sendMessage(org, lang), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getUserDetails: (store, org, memberId) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.user.userDetails(org, memberId), null, res => resolve(toCamelCase(res)))
        })
    },
    updateOrgDefinition: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.PUT, ENDPOINTS.org.data, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getOrgDefinitions: (store, org, query) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.getOrgDefinitions(org, query), null, res => resolve(toCamelCase(res)))
        })
    },
    getOrgDefinition: (store, org, query) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.getSingleDef(org, query), null, res => resolve(toCamelCase(res)))
        })
    },
    generateBanksOrdersPdfs: (store, org, fromDate, toDate) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.generateBanksOrdersPdfs(org, fromDate, toDate), null, res => resolve(toCamelCase(res)))
        })
    },
    chargesAmounts: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.chargesAmounts, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getPayments: (store, org, year, month) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.paymentsByMonth(org, year, month), null, res => resolve(toCamelCase(res)))
        })
    },
    getBankChargesRejected: (store, org, from, to) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.getBankChargesRejected(org, from, to), null, res => resolve(toCamelCase(res)))
        })
    },
    setEntitiesRegistrationStatus: (store, org, status, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.setEntitiesRegistrationStatus(org, status), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    updateGlobalDefinition: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.PUT, ENDPOINTS.org.globalDefinitions, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getGlobalDefinitions: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.globalDefinitions, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    setBankOrderStatus: (store, org, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.setBankOrderStatus(org), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getRoles: (store) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.roles, null, res => resolve(toCamelCase(res)))
        })
    },
    getMasavDocsToTransmit: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.masavCharges, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getMasavIncomesToTransmit: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.masavIncomes, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    markMasavDocsAsTransmitted: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.masavTransmitted, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    markMasavIncomesAsTransmitted: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.masavIncomesTransmitted, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getRegisterMembers: (store, org, fullDetails) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.registeredEntities(org, fullDetails), null, res => resolve(toCamelCase(res)))
        })
    },
    getAllUsers: (store, org) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.allUsers(org), null, res => resolve(toCamelCase(res)))
        })
    },
    runFlow: (store, flowId, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.schedule.runFlow(flowId), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    runOrgTask: (store, task, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.schedule.runOrgTask(task, store.org), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    runSystemTask: (store, task, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.schedule.runSystemTask(task), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    runSystemFlow: (store, flow, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.schedule.runSystemFlow(flow), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getProcesses: (store) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.schedule.getProcesses, null, res => resolve(toCamelCase(res)))
        })
    },
    getTagsAndFilters: (store, org, lang) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.user.tagsAndFilters(org, lang), null, res => resolve(res))
        })
    },
    getAllOrganizations: (store) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.getAll, null, res => resolve(res))
        })
    },
    anonymous: (store, method, url, body, isUpload = false) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, method, url, body, res => resolve(res), isUpload)
        })
    },
    reloadMicroCopy: (store) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.microcopy.reload, null, res => resolve(res))
        })
    },
    deleteOrgDefinition: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.DELETE, ENDPOINTS.org.delSingleDef(store.org), toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    getOrgInstitutions: (store) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, `/organizations/api/v1/${store.org}/financial_structures/institutions`, null, res => resolve(res))
        })
    },
    getPosSystems: (store) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.finance.getPosSystems, null, res => resolve(toCamelCase(res)))
        })
    },
    createMock: (store, org, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.finance.createMock(org), body, res => resolve(res))
        })
    },
    getAllFinancialBooks: (store, org) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.GET, ENDPOINTS.org.getAllFinancialBooks(org), null, res => resolve(toCamelCase(res)))
        })
    },
    orgName: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.org.data, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
    checkConnection: (store, body) => {
        return new Promise((resolve, reject) => {
            callHttpRequest(DOMAIN, store, METHOD.POST, ENDPOINTS.finance.checkConnection, toSnakeCase(body), res => resolve(toCamelCase(res)))
        })
    },
}
