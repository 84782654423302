import React, { forwardRef } from 'react'
import { Snackbar as SnackbarUI } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import styled from 'styled-components'

const AUTO_HIDE_DURATION = 6000
const ELEVATION = 6

const StyledMuiAlert = styled(MuiAlert)`
  flex-direction: row-reverse;
`

const Alert = forwardRef((props, ref) => {
    return <StyledMuiAlert elevation={ELEVATION} ref={ref} variant='filled' {...props} />
})

const Snackbar = ({open, handleClose, severity, message}) => {
    return (
        <SnackbarUI open={open} autoHideDuration={AUTO_HIDE_DURATION} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </SnackbarUI>
    )

}

export default Snackbar