import { Resource } from './restProvider'

const addUploadFeature = requestHandler => (type, resource, params) => {
  if (resource !== Resource.UPLOAD) {
    return requestHandler(type, resource, params)
  }
  if (!params || !params.itemsFile) return Promise.reject("לא נבחר קובץ")
  const myFile = params.itemsFile
  if (!myFile.rawFile instanceof File || !myFile) {
    return Promise.reject("קובץ לא תקין")
  }
  const form = new FormData()
  form.append("file", myFile.rawFile)
  for (const key in params) {
    if (key !== 'itemsFile') {
      form.append(key, params[key])
    }
  }
  return requestHandler(type, resource, { id: 1, data: form })
}

export default addUploadFeature
