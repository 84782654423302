// in src/MyLayout.js
import React from 'react';
import { Layout } from 'react-admin';
import Menu from './Menu';
import MyAppBar from './AppBar'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        overflow: 'auto',
        minWidth: 'inherit'
    },
});

const MyLayout = (props) => {
    const classes = useStyles();
    return (
        <Layout className={classes.root} {...props} menu={Menu} appBar={MyAppBar} />
    )
}

export default MyLayout;