import React from 'react'
import { observer, inject } from 'mobx-react'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, useNotify, useSafeSetState } from 'ra-core'


const useStyles = makeStyles(
  theme => ({
    form: { padding: '0 1em 1em 1em' },
    input: { marginTop: '1em' },
    button: { width: '100%' },
    icon: { marginRight: theme.spacing(1) }
  }),
  { name: 'RaLoginForm' }
)

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />

const LoginForm = ({ userStore, ...props }) => {
  const [loading, setLoading] = useSafeSetState(false)
  const [memberId, setMemberId] = useSafeSetState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles(props)

  const validate = values => {
    const errors = { memberId: undefined }
    if (!values.memberId) {
      errors.memberId = translate('ra.validation.required')
    } else if (values.memberId) setMemberId(values.memberId)

    return errors
  }

  const submit = async () => {
    try {
      setLoading(true)
      await userStore.tryLogin(memberId)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      notify('ra.auth.sign_in_error', 'warning')
    }
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field autoFocus id='memberId' name='memberId' component={Input} label={translate('ra.auth.memberId')} disabled={loading} />
            </div>
          </div>
          <CardActions>
            <Button variant='contained' type='button' color='primary' disabled={loading} className={classes.button} onClick={submit}>
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
}

export default inject('userStore')(observer(LoginForm))
