

import { inject, observer } from "mobx-react"
import React from 'react'
import styled from "styled-components"
import { useTranslate, MenuItemLink, useRedirect } from "react-admin"
import { FLOW } from 'clearing-types';
import strings from '../../../tools/strings'
import FlowItem from '../common/flowItem'
import TaskItem from '../common/taskItem'
import ReportItem from '../common/reportItem'
import Typography from '@material-ui/core/Typography'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Button from "@material-ui/core/Button"
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Badge from '@mui/material/Badge';

const StyledTitle = styled(Typography)`
  font-weight: 900;
  padding:15px;
`
const FormContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`
const StyledCancleButton = styled(Button)`
  margin-top: 20px;
  margin-right: 370px;
`

const CreditProcess = ({ userStore, tasksStore, onMenuClick, ...props }) => {
    const translate = useTranslate()
    const redirect = useRedirect();

    const handleCancel = () => {
        redirect('/slika_processes');
    }
    return (
        <FormContainer>
            <StyledTitle >{translate('customRoot.scheduleFlows.credit_process')}</StyledTitle>
            <MenuItemLink primaryText={<Badge>{translate(`customRoot.myMenu.upload_incomes`)}<ArrowBackIosIcon /></Badge>} to='/upload_incomes' leftIcon={<CloudUploadIcon />} onClick={onMenuClick} />
            <FlowItem flowName={FLOW.CREDIT_FLOW} flowIndex={3} />
            <ReportItem icon={<ArchiveIcon />} reportType={strings.reports.DOWNLOAD_MASAV_INCOMES} />
            <TaskItem taskType={strings.tasks.MASAV_INCOME_TRANSMITTED} icon={<PlaylistAddCheckIcon />} />
            <StyledCancleButton variant='contained' onClick={handleCancel} color='submit'>
                {translate("customRoot.definitions.edit.cancel_back_button")}
            </StyledCancleButton>
        </FormContainer>
    )
}

export default inject('userStore', 'tasksStore')(observer(CreditProcess))

