import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { FileField, FileInput, SimpleForm, useDataProvider, Loading, useTranslate, useRedirect, Toolbar, SaveButton } from 'react-admin'
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { Resource } from 'core/restProvider'
import { Snackbar } from 'components/common'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import { he } from 'date-fns/locale'
import { DatePicker, LocalizationProvider } from '@material-ui/pickers'
import { withStyles, Button } from '@material-ui/core'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 25%;
  margin: auto;
  color: #bdbdbd;
  outline: none;
  cursor: grab;
  transition: border 0.24s ease-in-out;
`

const StyledLoader = styled(Loading)`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 20em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const StyledContainer = styled.div`
    margin: 5%;
    display: flex;
`

const StyledInput = styled.div`
    margin: 4rem;
    width: 100%
`
const StyledSimpleForm = styled(SimpleForm)`
    & .MuiToolbar-root button {
        width: 14%;
        margin: 3rem;
    };
    flex: 2;
    background-color: #f5f5f5;

`
const StyledCancelButton = styled(Button)`
  margin-left: 10px;
`
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: "center",
    }
}
const PostEditToolbar = withStyles(toolbarStyles)(props => {
    const redirect = useRedirect();
    const translate = useTranslate()
    const handleCancel = () => {
        redirect('/credit_process');
    }
    return (
        <Toolbar {...props}>
            <SaveButton />
            <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
                {translate("customRoot.definitions.edit.cancel_back_button")}
            </StyledCancelButton>
        </Toolbar>
    );
})

const UploadIncomes = ({ userStore }) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const [loading, setLoading] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [messageSnackbar, setMessageSnackbar] = useState({})
    const [date, setDate] = useState(new Date())
    const [financialBookSelected, setFinancialBookSelected] = useState('')

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }

    const handleSave = async values => {
        try {
            setLoading(true)
            const payload = {
                org: userStore.org,
                chargee_id: financialBookSelected,
                period: JSON.stringify([date.getFullYear(), date.getMonth() + 1]),
                itemsFile: values['file']
            }
            const res = await dataProvider.CREATE(Resource.UPLOAD, payload)
            setLoading(false)
            setOpenSnackbar(true)
            if (res.data.success) {
                setMessageSnackbar({ message: translate('ra.upload_success_message'), severity: 'success' })
            }
            else {
                setMessageSnackbar({ message: translate('ra.upload_failed_message'), severity: 'error' })
            }
        } catch (error) {
            setLoading(false)
            setMessageSnackbar({ message: translate('ra.upload_failed_message'), severity: 'error' })
        }
    }

    useEffect(() => {
        const handleFinancialBooks = async () => {
            await userStore.getAllFinancialBooks()
            if (!userStore.financialBooks.length) {
                setOpenSnackbar(true)
                setMessageSnackbar({ message: translate('ra.missing_financial_book'), severity: "info" })
                return
            }
            setFinancialBookSelected(userStore.financialBooks[0].data.code)
        }
        handleFinancialBooks()
    }, [])

    return (
        <StyledContainer>
            <StyledSimpleForm save={handleSave} toolbar={<PostEditToolbar />}>
                {loading && <StyledLoader loadingPrimary='ra.page.loading' loadingSecondary='ra.page.loading' />}
                <Container>
                    <StyledInput>
                        <InputLabel id='orgs-select-label'>{translate('ra.select_financial_book')}</InputLabel>
                        <Select
                            labelId='orgs-select-label'
                            value={financialBookSelected}
                            defaultValue={financialBookSelected}
                            onChange={(e) => setFinancialBookSelected(e.target.value)}
                            fullWidth>
                            {userStore.financialBooks.map(financialBook => (
                                <MenuItem value={financialBook.data.code}>
                                    {financialBook.data.name.he}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledInput>
                    <StyledInput>
                        <LocalizationProvider locale={he} dateAdapter={DateFnsUtils}>
                            <DatePicker
                                views={['year', 'month']}
                                value={date}
                                onChange={setDate}
                                renderInput={
                                    (props) => <TextField {...props} helperText={null} variant="standard" fullWidth />
                                }
                            />
                        </LocalizationProvider>
                    </StyledInput>
                    <FileInput label='ra.input.upload_label' source='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'>
                        <FileField source='src' title='title' />
                    </FileInput>
                </Container>
            </StyledSimpleForm>
            <Snackbar open={openSnackbar} handleClose={closeSnackbar} severity={messageSnackbar.severity} message={messageSnackbar.message}></Snackbar>
        </StyledContainer>
    )
}
export default inject("userStore")(observer(UploadIncomes))