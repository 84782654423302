import React, { useEffect } from 'react'
import {
  Create,
  useTranslate,
  useRedirect,
  useNotify,
  SaveButton,
  Toolbar,
} from 'react-admin'
import { observer, inject } from 'mobx-react'
import { requests } from 'fetch/requests'
import PosForm from './posForm'
import { withStyles, Button } from '@material-ui/core'
import styled from "styled-components"

const StyledCancelButton = styled(Button)`
  margin-left: 40px;
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
  }
}
const PosCreateToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/pos');
  }
  return (
    <Toolbar {...props} >
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
      <SaveButton />
    </Toolbar>
  );
})
const PosCreate = ({ userStore, ...props }) => {
  const translate = useTranslate()

  useEffect(() => {
    async function fetchData() {
      if (!userStore.posSystems.length) {
        const result = await requests.getPosSystems(userStore)
        userStore.setPosSystems(result.data)
      }
    }
    fetchData();
  }, [userStore])

  const redirect = useRedirect()
  const notify = useNotify()
  const onSuccess = ({ data }) => {
    notify(translate('customRoot.pos.created_success_message'), { type: 'success' })
    redirect('/pos');
  }

  const onFailure = (error) => {
    notify(translate('customRoot.pos.created_failed_message'), { type: 'warning' })
    redirect('/pos');
  }

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} title={translate('customRoot.pos.create_title')}>
      <PosForm redirect="list" toolbar={<PosCreateToolbar />} />
    </Create>
  )
}

export default inject("userStore")(observer(PosCreate))
