import { inject, observer } from "mobx-react"
import React from "react"
import { List, Datagrid, TextField, BooleanField } from "react-admin"
import styled from "styled-components"


const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`


const FinanceSystemsList = ({ userStore, ...props }) => {

  if (!userStore.org) {
    return null
  }
  return (
    <FormContainer>
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick='edit'>
          <TextField source='code' label='customRoot.financeSystems.list.sys_id' sortable={false} />
          <TextField source='name' label='customRoot.financeSystems.list.system_name' sortable={false} />
          <BooleanField source='status' label='customRoot.financeSystems.list.active' sortable={false} />
        </Datagrid>
      </List>
    </FormContainer>
  )
}

export default inject("userStore")(observer(FinanceSystemsList))
