import { types, flow } from "mobx-state-tree"
import strings from "../tools/strings"
import { Resource, restProvider } from '../core'
import { GET_ONE, UPDATE, DELETE, CREATE } from 'react-admin'

export const corporationMembersTypes = [
  { id: "kibbutz", name: "Kibbutz" },
  { id: "community", name: "Community" }
]

const CorporationStore = types.model('CorporationStore', {
  financialData: types.array(types.frozen()),
  corporations: types.frozen(),
  currentCorporation: types.frozen(),
  currentInstitutions: types.array(types.frozen()),
  currentFinancialBooks: types.array(types.frozen()),
  financialCompanies: types.array(types.frozen()),// all financial companies
  currentSystems: types.array(types.frozen()),
  currentCreditCardGateways: types.array(types.frozen()),
})
  .actions(self => {
    const getCorporationData = async (corporationCode) => {
      try {
        const res = await restProvider(GET_ONE, Resource.CORPORATIONS, { id: corporationCode })
        const financialCompaniesResults = await Promise.all([self.getFinancialCompaniesData(), self.getSystemsData()])
        const financialCompaniesResult = financialCompaniesResults[0]
        const financialSystems = financialCompaniesResults[1]
        if (!financialCompaniesResult.error) {
          self.setFinancialCompanies(financialCompaniesResult || [])
        }
        if (!financialSystems.error) {
          self.setCurrentSystems(financialSystems || [])
        }
        if (!res.data) {
          throw new Error(res.error?.detail)
        }
        await self.setCorporationData(res.data)
      } catch (err) {
        return { success: false, error: err }
      }
    }
    const getFinancialBookData = async (id) => {
      try {
        const localFinancialBook = getFinancialBook(id)
        const res = await restProvider(GET_ONE, Resource.FINANCIAL_BOOK, { id: localFinancialBook.data.code })
        if (!res.data) {
          throw new Error(res.error?.detail)
        }
        return res.data
      } catch (err) {
        return { success: false, error: err }
      }
    }
    const getFinancialCompanyData = async (id) => {
      try {
        const res = await restProvider(GET_ONE, Resource.FINANCIAL_COMPANY, { id: id })
        if (!res.data) {
          throw new Error(res.error?.detail)
        }
        return res.data
      } catch (err) {
        return { success: false, error: err }
      }
    }
    const getFinancialCompaniesData = async () => {
      try {
        const res = await restProvider(strings.CUSTOM_EXPORT, Resource.FINANCIAL_COMPANY)
        if (!res.data) {
          throw new Error(res.error?.detail)
        }
        return res.data
      } catch (err) {
        return { success: false, error: err }
      }
    }
    const getSystemsData = async () => {
      try {
        const res = await restProvider(strings.CUSTOM_EXPORT, Resource.SYSTEMS)
        if (!res.data) {
          throw new Error(res.error?.detail)
        }
        return res.data
      } catch (err) {
        return { success: false, error: err }
      }
    }

    const createFinancialBook = async (financialBook) => {
      // financialBook.relations.push(
      //   {
      //     sub_type: 'corporation',
      //     code: self.currentCorporation.data.code
      //   }
      // )
      const res = await restProvider(CREATE, Resource.FINANCIAL_BOOK, financialBook)
      // if (!res.success) {
      //   return res
      // }
      return res
      // else {
      //   // const relation = await addRelationToCurrentCorporation(res.data.data.code)
      //   if (financialBook.institution_credit) {
      //     const institution_credit = self.currentInstitutions.find(rec => rec.data.institution_code === financialBook.institution_credit)
      //     if (institution_credit) {
      //       console.log('institution_credit', res.data.code)
      //       addRelation(res.data.data.code, institution_credit.data.code) //TODO remove before all FinancialBook old Relations
      //     }
      //   }
      //   if (financialBook.institution_debit) {
      //     const institution_debit = self.currentInstitutions.find(rec => rec.data.institution_code === financialBook.institution_debit)
      //     if (institution_debit) {
      //       console.log('institution_debit', res.data.code)
      //       addRelation(res.data.data.code, institution_debit.data.code)  //TODO remove before all FinancialBook old Relations
      //     }
      //   }
      //   return null

      // }
    }

    const recreateBankAuthorizations = (institution) => {
      const res = restProvider(strings.CUSTOM_EXPORT, Resource.INSTITUTION, institution)
      return res
    }

    const createFinancialCompany = async (financialBook) => {
      const res = await restProvider(CREATE, Resource.FINANCIAL_COMPANY, financialBook)
      return res
      // if (!res.success) {
      //   return res
      // }
      // else {
      //   return await addRelationToCurrentCorporation(res.data.data.code)
      // }
    }

    const createInstitution = async (institution) => {
      institution.relations = [
        {
          sub_type: 'corporation',
          code: self.currentCorporation.data.code
        }
      ]
      const res = await restProvider(CREATE, Resource.INSTITUTION, institution)
      return res
      // if (!res.success) {
      //   return res
      // }
      // else {
      //   return await addRelationToCurrentCorporation(res.data.data.code)
      // }
    }
    const updateInstitution = async (institution) => {
      return await restProvider(UPDATE, Resource.INSTITUTION, institution)
    }
    const createCreditCardGateways = async (institution) => {
      const res = await restProvider(CREATE, Resource.CREDIT_CARD_GATEWAYS, institution)
      return res
      // if (!res.success) {
      //   return res
      // }
      // else {
      //   return await addRelationToCurrentCorporation(res.data.data.code)
      // }
    }
    const updateCreditCardGateways = async (institution) => {
      return await restProvider(UPDATE, Resource.CREDIT_CARD_GATEWAYS, institution)
    }

    const updateFinancialBook = async (financialBook) => {
      let res = await restProvider(UPDATE, Resource.FINANCIAL_BOOK, financialBook)
      // if (financialBook.institution_credit) {
      //   const institution_credit = self.currentInstitutions.find(rec => rec.data.institution_code === financialBook.institution_credit)
      //   if (institution_credit) {
      //     addRelation(financialBook.data.code, institution_credit.data.code) //TODO remove before all FinancialBook old Relations
      //   }
      // }
      // if (financialBook.institution_debit) {
      //   const institution_debit = self.currentInstitutions.find(rec => rec.data.institution_code === financialBook.institution_debit)
      //   if (institution_debit) {
      //     addRelation(financialBook.data.code, institution_debit.data.code)  //TODO remove before all FinancialBook old Relations
      //   }
      // }
      return res
    }

    const updateFinancialCompany = async (financialCompany) => {
      return await restProvider(UPDATE, Resource.FINANCIAL_COMPANY, financialCompany)
    }

    const addRelation = async (firstCode, secondCode) => {
      const data = [firstCode, secondCode]
      return await restProvider(strings.CUSTOM_EXPORT, Resource.FINANCIAL_RELATIONS, data)
    }
    const addRelationToCurrentCorporation = async (code) => {
      return addRelation(code, self.currentCorporation.data.code)
    }
    const createCorporation = async (corporation) => {
      const body = {
        data: {
          business_number: corporation.data.business_number,
          name: corporation.data.name,
          members_type: corporation.data.members_type
        },
        relations: []
      }
      return await restProvider(CREATE, Resource.CORPORATIONS, body)
    }
    const deleteCurrentCorporation = async () => {
      const body = {
        id: self.currentCorporation.data.code,
      }
      let res = await restProvider(DELETE, Resource.CORPORATIONS, body)
      return res
    }
    const deleteInstitution = async (id, sub_type) => {
      const body = {
        id,
        sub_type
      }
      let res = await restProvider(DELETE, Resource.INSTITUTION, body)
      return res
    }
    const deleteCreditCardGateways = async (code) => {
      const body = {
        code
      }
      let res = await restProvider(DELETE, Resource.CREDIT_CARD_GATEWAYS, body)
      return res
    }

    const deleteFinancialBook = async (id) => {
      let res = await restProvider(DELETE, Resource.FINANCIAL_BOOK, { id })
      return res
    }

    const deleteFinancialCompany = async (id) => {
      let res = await restProvider(DELETE, Resource.FINANCIAL_COMPANY, { id })
      return res
    }

    const updateCorporation = async (corporation) => {
      const body = {
        data: {
          members_type: corporation.data.members_type,
          business_number: corporation.data.business_number,
          name: corporation.data.name
        },
        relations: [],
        id: corporation.id,
      }
      return await restProvider(UPDATE, Resource.CORPORATIONS, body)
    }
    const setCorporationData = async (corporationData) => {
      self.setCurrentCorporation(await convertRefToObjects(corporationData))
      self.setCurrentInstitutions(self.currentCorporation.relations.filter(rec => rec.sub_type === "institution_credit" || rec.sub_type === "institution_debit"))
      self.setCurrentCreditCardGateways(self.currentCorporation.relations.filter(rec => rec.sub_type === "credit_card_gateway"))
      self.setCurrentFinancialBooks(self.currentCorporation.relations.filter(rec => rec.sub_type === "financial_book").sort((el1, el2) => (el1.data.code < el2.data.code) ? -1 : 1))
    }

    const convertRefToObjects = async (corporationData) => {
      const res = await restProvider(strings.CUSTOM_EXPORT, Resource.GET_RELATIONS, { code: corporationData.data.code })
      // console.log('convertRefToObjects', res)
      corporationData.relations = res.data
      return corporationData
    }

    const getInstitution = (id) => {
      return self.currentInstitutions.find(rec => rec.data.code === id)
    }
    const getCurrentCreditCardGateways = (id) => {
      return self.currentCreditCardGateways.find(rec => rec.data.code === id)
    }

    const getFinancialBook = (id) => {
      return self.currentFinancialBooks.find(rec => rec.data.code === id)
    }
    const setFinancialCompanies = (financialCompanies) => {
      self.financialCompanies = financialCompanies
    }
    const setCurrentSystems = (currentSystems) => {
      self.currentSystems = currentSystems
    }
    const setCurrentInstitutions = (currentInstitutions) => {
      self.currentInstitutions = currentInstitutions
    }
    const setCurrentCreditCardGateways = (currentCreditCardGateways) => {
      self.currentCreditCardGateways = currentCreditCardGateways
    }
    const setCurrentFinancialBooks = (currentFinancialBooks) => {
      self.currentFinancialBooks = currentFinancialBooks
    }
    const setCurrentCorporation = (currentCorporation) => {
      self.currentCorporation = currentCorporation
    }
    return {
      updateInstitution,
      createInstitution,
      createCreditCardGateways,
      updateCreditCardGateways,
      deleteCreditCardGateways,
      deleteCurrentCorporation,
      getSystemsData,
      updateCorporation,
      createCorporation,
      getCorporationData,
      setCorporationData,
      getInstitution,
      getCurrentCreditCardGateways,
      createFinancialBook,
      updateFinancialBook,
      deleteInstitution,
      deleteFinancialBook,
      recreateBankAuthorizations,
      getFinancialBookData,
      getFinancialCompanyData,
      getFinancialCompaniesData,
      createFinancialCompany,
      updateFinancialCompany,
      deleteFinancialCompany,
      addRelationToCurrentCorporation,
      setFinancialCompanies,
      setCurrentCorporation,
      setCurrentCreditCardGateways,
      setCurrentFinancialBooks,
      setCurrentInstitutions,
      setCurrentSystems
    }
  })
  .views((self) => ({
  }))

const corporationStore = CorporationStore.create({
  corporations: null,
  currentCorporation: null,
  currentInstitutions: [],
  currentFinancialBooks: [],
  financialCompanies: [],
  currentSystems: [],
  currentCreditCardGateways: [],
})

export default corporationStore

