import React from "react"
import { Filter, TextInput } from "react-admin"


const PostFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label='customRoot.organizations.list.org_name' source='org_name' />
    </Filter>
  )
}

export default PostFilter
