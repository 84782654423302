import React from "react"
import { EditButton, useTranslate, useRedirect, Button } from "react-admin"
import styled from "styled-components"
import { observer, inject } from "mobx-react"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Add } from '@material-ui/icons'

const StyledTitle = styled.div`
    padding:15px;
    font-weight:bold;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FinancialCompanyItem = inject('corporationStore')(observer(({ financialCompany, corporationStore }) => {
  const redirect = useRedirect();
  const code = financialCompany.code
  const onEdit = () => {
    if (code) {
      redirect(`/financialCompany/${code}`);
    }
  }
  let financial_system = ""
  let company_id = ""
  if (financialCompany) {
    const found = corporationStore.currentSystems?.find(el => el.code === financialCompany.financial_system)
    if (found) {
      financial_system = found.name
    }
    else {
      financial_system = financialCompany.financial_system
    }
    company_id = financialCompany.company_id
  }
  return (
    <TableRow key={code}>
      <TableCell align="left">{financial_system}</TableCell>
      <TableCell align="left">{company_id}</TableCell>
      <TableCell align="center"><EditButton onClick={onEdit} label="" /></TableCell>
    </TableRow>
  )
}))

const FinancialCompaniesHeader = () => {
  const translate = useTranslate()
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.financialCompanies.financial_system')}</b></TableCell>
        <TableCell align="left"><b>{translate('customRoot.definitions.edit.financialCompanies.company_id')}</b></TableCell>
        <TableCell align="center"><b>{translate('ra.action.edit')}</b></TableCell>
      </TableRow>
    </TableHead>
  )
}

const FinancialCompaniesList = ({ list }) => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const onCreate = () => {
    redirect('/financialCompany/create');
  }
  return (
    <>
      <StyledTitle>{translate('customRoot.definitions.edit.financialCompanies.title')}</StyledTitle>
      <ButtonsContainer>
        <Button onClick={onCreate}><Add /></Button>
      </ButtonsContainer>
      <Table aria-label="simple table">
        <FinancialCompaniesHeader />
        <TableBody>
          {list.map(financialCompany => console.log('financialCompany.code', financialCompany.code) || (
            <FinancialCompanyItem financialCompany={financialCompany} key={financialCompany.code} />
          ))}
        </TableBody>
      </Table>

    </>
  )
}

export default inject("corporationStore")(observer(FinancialCompaniesList))
