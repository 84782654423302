import React, { useState, useEffect } from "react"
import { observer, inject } from "mobx-react"
import { 
  Edit, 
  SimpleForm, 
  useEditController, 
  FormDataConsumer, 
  TextInput, 
  SaveButton, 
  Toolbar, 
  required, 
  minLength, 
  number, 
  regex, 
  email, 
  SelectArrayInput, 
  useRedirect, 
  useTranslate,
  BooleanInput,
  useUpdate, 
  useRecordContext 
} from "react-admin"
import { isValidIsraeliID, isValidPhone } from '@kimaia/validation'
import styled from "styled-components"
import { withStyles, Button } from '@material-ui/core'

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledCancelButton = styled(Button)`
  margin-left: 40px;
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: "center",
  }
}

const PostEditToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleCancel = () => {
    redirect('/users');
  }

  return (
    <Toolbar {...props}>
      <SaveButton />
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
    </Toolbar>
  );
})

const validateIsraeliId = val => {
  if (!isValidIsraeliID(val)) return 'ra.validation.israeliID'
  return undefined
}
const validateIsraeliMobilePhone = val => {
  if (!isValidPhone(val)) return 'ra.validation.israeliPhone'
  return undefined
}

const validateFirstName = [required(), regex(/^[\u0590-\u05FFa-zA-Z '.\-()]+$/, 'ra.validation.alphabetic'), minLength(2)];
const validateLastName = [required(), regex(/^[\u0590-\u05FFa-zA-Z '.\-()]+$/, 'ra.validation.alphabetic'), minLength(2)];
const validateEmail = [email()]
const validateMobile = [required(), number(), minLength(9), validateIsraeliMobilePhone];
const validateId = [required(), validateIsraeliId]

const UserEdit = ({ userStore, ...props }) => {
  const { record, isLoading } = useEditController(props);
  const enabledBudgetRole = record && !isLoading && record?.existing_roles && record.existing_roles.includes(`member_budget_${record.entity}`);

  const transform = data => {
    const  { has_budget_role, ...rest } = data;
    const budgetRole = `member_budget_${rest.entity}`;

    if (has_budget_role) {
      return !data.existing_roles.includes(budgetRole) 
        ? {...rest, hasBudgetRole: true, budgetRole } 
        : rest;
    } else if (!has_budget_role) {
      return data.existing_roles.includes(budgetRole) 
        ? {...rest, hasBudgetRole: false, budgetRole } 
        : rest;
    }

    return rest;
  }

  return (
    <Edit undoable={false} {...props} transform={transform}>
      <SimpleForm redirect='list' toolbar={<PostEditToolbar />}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <FormContainer>
                <BodyContainer>
                  {formData.available_roles_choices?.length ? <SelectArrayInput source='updated_roles' label='customRoot.users.edit.roles_header' choices={record.available_roles_choices} /> : null}
                  <TextInput source='teudat_zehut' label='customRoot.users.list.teudat_zehut' validate={validateId} />
                  <TextInput source='first_name' label='customRoot.users.list.first_name' validate={validateFirstName} />
                  <TextInput source='last_name' label='customRoot.users.list.last_name' validate={validateLastName} />
                  <TextInput dir="ltr" source='mobile' label='customRoot.users.list.phone' validate={validateMobile} />
                  <TextInput dir="ltr" source='email' label='customRoot.users.list.email' validate={validateEmail} />
                  <BooleanInput source="has_budget_role" label="customRoot.users.list.has_budget_role" defaultValue={enabledBudgetRole} />
                </BodyContainer>
              </FormContainer>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
export default inject("userStore")(observer(UserEdit))
