// from https://material-ui.com/components/dialogs/

import React, {useState}from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslate } from "react-admin"
import { observer, inject } from "mobx-react"

function AlertDialog({userStore, serviceName, ...props}) {
  const disabled = userStore.disabledButtons.get(serviceName)
  const changeDisabledButtons = userStore.changeDisabledButtons
  const [open, setOpen] = useState(false);
  const translate = useTranslate()
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const disableButtonTime = 1000 * 60 * 2
  const enableButtonsWithTimeout = ()=>{
    setTimeout(()=>changeDisabledButtons(serviceName, false), disableButtonTime)
  }

  return (
    <div>
      <Button onClick={handleClickOpen} disabled = {disabled}>
        {props.children}
      </Button>
      <Dialog
        dir="rtl"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('customRoot.general.decline')}
          </Button>
          <Button
            disabled = {disabled}
            onClick={async()=>{
              changeDisabledButtons(serviceName, true)
              try{
                const killAction = await props.action()
                if(killAction?.success){
                  alert(translate('customRoot.services.killSuccess'))
                }else{
                  alert(translate('customRoot.services.killfailure'))
                }
              }catch(e){
                alert(translate('customRoot.services.killfailure'))
              }
              enableButtonsWithTimeout()
              handleClose()
              }
            } 
            color="secondary" autoFocus>
            {translate('customRoot.general.approve')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default inject("userStore")(observer(AlertDialog))
