import  React,  { useEffect }  from 'react';
import TextField from '@material-ui/core/TextField';
import {
  DateRangePicker,
  LocalizationProvider,
  DateRangeDelimiter,
  
} from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"; 
import { useTranslate } from "react-admin"



export default function RangeDatePicker({ handleDateChange, disabled, defaultStartDate, defaultEndDate}) {


  const [value, setValue] = React.useState([defaultStartDate || null ,defaultEndDate || null]);

  useEffect(() => {
    setValue([defaultStartDate, defaultEndDate ])
  }, [defaultStartDate, defaultEndDate ])

  const translate = useTranslate()


  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <DateRangePicker
        startText=""
        endText=""
        disabled = {disabled}
        value={value}
        onChange={(newValue) => {
          const newValueUTC = [newValue[0] ? new Date(newValue[0].getTime() - newValue[0].getTimezoneOffset()*60000) : null,
          newValue[1] ? new Date(newValue[1].getTime() - newValue[1].getTimezoneOffset()*60000): null]
          setValue(newValueUTC);
          handleDateChange(newValueUTC)          
        }}
        inputFormat = "dd/MM/yyyy"
        renderInput={(startProps, endProps) => (
          <React.Fragment>
          <DateRangeDelimiter> {translate("customRoot.reports.date_from")} </DateRangeDelimiter>
            <TextField {...startProps} helperText={null}  variant="standard" />
            <DateRangeDelimiter> {translate("customRoot.reports.date_to")} </DateRangeDelimiter>
            <TextField {...endProps} helperText={null}  variant="standard" />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}