import { types } from "mobx-state-tree"
import { Resource, restProvider } from '../core'
import { GET_ONE } from 'react-admin'

const JobStore = types
  .model('JobStore', {
    scopes: types.array(types.frozen()),
  })
  .actions(self => {
    const getScopes = async () => {
      try {
        const res = await restProvider(GET_ONE, Resource.JOB_MANAGER, { })
        
        if (!res.data) {
          throw new Error(res.error?.detail)
        }

        await self.setScopes(res.data)
      } catch (err) {
        return { success: false, error: err }
      }
    }
   
    const setScopes = (scopes) => {
      self.scopes = scopes
    }

    return {
      getScopes,
      setScopes,
    }
  })
  .views((self) => ({
    ////
  }))

const jobStore = JobStore.create({
  scopes: [],
})

export default jobStore

