// in src/Menu.js
import React from "react"
import { useSelector } from "react-redux"
import { useMediaQuery } from "@material-ui/core"
import { MenuItemLink, getResources, useTranslate } from "react-admin"
import AssessmentIcon from '@material-ui/icons/Assessment';
import DefaultIcon from "@material-ui/icons/ViewList"
import styled from "styled-components"
import PanToolIcon from '@material-ui/icons/PanTool';
import { useLocation } from 'react-router-dom'
import ComputerIcon from '@material-ui/icons/Computer';
import strings from '../tools/strings'
import { inject, observer } from "mobx-react";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const MenuContainer = styled.div`
  margin-top: 50px;
`
const Menu = ({ userStore, onMenuClick, logout }) => {
  const translate = useTranslate()
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const [openMenuGrow, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const prevOpen = React.useRef(openMenuGrow)
  React.useEffect(() => {
    if (prevOpen.current === true && openMenuGrow === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = openMenuGrow;
  }, [openMenuGrow])
  const path = useLocation().pathname

  if (!userStore.org) {
    return null
  }
  return (
    <MenuContainer>
      {resources.filter(r => r.hasList).map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`customRoot.myMenu.${resource.name}`)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {/* <MenuItemLink to='/definitions' primaryText={translate(`customRoot.myMenu.definitions`)} leftIcon={<SettingsIcon />} onClick={onMenuClick} /> */}
      <MenuItemLink to='/reports' primaryText={translate(`customRoot.myMenu.reports`)} leftIcon={<AssessmentIcon />} onClick={onMenuClick} />
      {strings.server !== strings.productionServer && <MenuItemLink to='/services' primaryText={translate(`customRoot.myMenu.services`)} leftIcon={<ComputerIcon />} onClick={onMenuClick} />}
      <MenuItemLink to='/slika_processes' primaryText={translate(`customRoot.myMenu.slika_processes`)} leftIcon={<AttachMoneyIcon />} onClick={onMenuClick} />
      <MenuItemLink to='/charge_permissions' primaryText={translate(`customRoot.myMenu.charge_permissions`)} leftIcon={<PanToolIcon />} onClick={onMenuClick} />


      {isXSmall && logout}
    </MenuContainer>
  )
}

export default inject("userStore")(observer(Menu))

