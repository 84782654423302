import React from "react"
import { observer, inject } from "mobx-react"
import { Create, SimpleForm, TextInput, useRedirect, useTranslate, Toolbar, SaveButton } from "react-admin"
import { withStyles, Button } from '@material-ui/core'
import styled from "styled-components"
import strings from "../../tools/strings";

const StyledCancelButton = styled(Button)`
  margin-left: 40px
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: "center",
  }
}
const OrgCreateToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/organizations');
  }
  return (
    <Toolbar {...props} >
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
      <SaveButton />
    </Toolbar>
  );
})
const validateOrganizationCreation = (values) => {
  const errors = {};
  if (!values[strings.definitionsLanguage.he]) {
    errors[strings.definitionsLanguage.he] = 'ra.validation.required';
  }
  if (!values[strings.definitionsLanguage.en]) {
    errors[strings.definitionsLanguage.en] = 'ra.validation.required';
  }
  return errors;
};

const OrgCreate = ({ userStore, ...props }) => {
  const redirect = useRedirect();
  const onSuccess = async ({ data }) => {
    await userStore.getOrganizationsList()
    redirect('/organizations');
  }
  return (
    <Create {...props} onSuccess={onSuccess} >
      <SimpleForm redirect='list' toolbar={<OrgCreateToolbar />} validate={validateOrganizationCreation} >
        <TextInput source={strings.definitionsLanguage.he} label='customRoot.definitions.edit.org_name.he' />
        <TextInput dir='ltr' source={strings.definitionsLanguage.en} label='customRoot.definitions.edit.org_name.en' />
      </SimpleForm>
    </Create>
  )
}
export default inject("userStore")(observer(OrgCreate))
