import { blue } from "@material-ui/core/colors"

export default {
    organizations: '/organizations/api/v1/organizations',
    job_manager: '/organizations/api/v1/job-list',
    job_manager_scopes: '/organizations/api/v1/job-list-scopes',
    corporations: {
        prefix: `/organizations/api/v1/`,
        middle: `/financial_structures/corporations/`,
        postfix: `/relation`
    },
    get_relations: {
        prefix: `/organizations/api/v1/`,
        middle: `/financial_structures/corporations/`,
        postfix: `/relations`
    },
    institution: {
        prefix: `/organizations/api/v1/`,
        postfix: `/financial_structures/institutions/`
    },
    credit_card_gateways: {
        prefix: `/organizations/api/v1/`,
        postfix: `/financial_structures/credit_card_gateways/`
    },
    financialBook: {
        prefix: `/organizations/api/v1/`,
        postfix: `/financial_structures/financial_books/`
    },
    financialRelations: {
        prefix: `/organizations/api/v1/`,
        postfix: `/financial_structures/financial_relations`
    },
    financialCompany: {
        prefix: `/organizations/api/v1/`,
        postfix: `/financial_structures/financial_companies/`
    },
    upload: {
        prefix: `/organizations/api/v1`,
        postfix: `/incomes/upload`
    },
    definitions: '/organizations/api/v1/definitions',
    roles: '/mekomi/api/v1/roles',
    systems: '/finance/api/v1/systems',
    pos: '/organizations/api/v1/pos',
    finance_gateways: '/organizations/api/v1/finance_gateways',
    creditCard_gateways: '/credit_cards/api/v1/gateways',
    updateDefinitions: '/organizations/api/v1/definitions',
    definitionsDescriptionKey: '000',
    getUserDetails: (org, memberId) => `/users/api/v1/members/details/${org}/${memberId}`,
    users: '/users/api/v1/users',
    exportFinanceUrl: '/users/api/v1/members/export',
    exportUploadFormatUrl: '/users/api/v1/members/export_for_upload',
    exportFinanceOrgUrl: '/users/api/v1/org/export',
    exportOrgUploadFormatUrl: '/users/api/v1/org/export_for_upload',
    adminUrl: process.env.REACT_APP_ADMIN_URL || '__RUNTIME_API_BASE_URL_ADMIN_URL__',
    appUrl: process.env.REACT_APP_URL || '__RUNTIME_API_BASE_URL_APP_URL__',
    server: process.env.REACT_APP_SERVER || '__RUNTIME_SERVER__',
    appEnv: process.env.REACT_APP_ENV || '__RUNTIME_APP_ENV__',
    productionServer: 'master',
    downloadError: 'download error',
    usersExportFileName: 'users',
    hebrewSupportPrefix: "\uFEFF",
    processType: {
        flow: "flow",
        task: "task"
    },
    processScope: {
        org: 'org',
        system: 'system'
    },
    usersFinanceExportFileName: 'users_finance',
    usersFinanceExportAllFileName: 'users_finance_all',
    usersExportUploadFormatFileName: 'users_upload_format',
    usersExportAllUploadFormatFileName: 'users_upload_format_all',
    CUSTOM_EXPORT: 'CUSTOM_EXPORT',
    exportFormats: {
        uploadFormat: 'uploadFormat',
        financeFormat: 'financeFormat'
    },
    noUsers: 'no users received',
    rolesStrings: {
        ADMIN_ORG_ROLE_PREFIX: `admin_`,
        MEMBER_ROLE_PREFIX: `member_`,
        BUDGET_ROLE_PREFIX: `member_budget_`,
        ADMIN_TREASURER_ROLE_PREFIX: `admin_treasurer_`,
        ADMIN_SYS_ROLE: 'admin_sys',
        ADMIN_SERVICE_ORGANIZATIONS: 'admin_service_organizations'
    },
    NO_STATUS: 'all',
    error: {
        NO_PERMISSIONS: 'Forbidden'
    },
    RESTART_SERVICE: 'RESTART_SERVICE',
    KILL_SERVICES_SUFFIX: '/kill',
    services: {
        USERS: 'users',
        ORGANIZATIONS: 'organizations',
        UPLOAD: 'upload',
        BANKS: 'banks',
        CREDIT_CARDS: 'credit_cards',
        PDF: 'pdf',
        SCHEDULER: 'scheduler',
        MESSAGES: 'messages',
        USERS_ACL: 'users_acl',
        MICROCOPY: 'microcopy',
        MEKOMI: 'mekomi',
        FINANCE: 'finance'
    },
    WINDOW_POST_MESSAGE_TYPE: {
        SET_ACTIVE_USER: 'set_active_user',
        GET_ACTIVE_USER: 'get_active_user'
    },
    definitionKey: {
        banks_orders_requests_flow_type: 'banks_orders_requests_flow_type'
    },
    FLOW_TYPE: {
        MASAV: 1,
        PROXY: 2
    },
    BankOrderStatus: {
        '-1': 'FAILED',
        0 :  'CANCELED',
        1: 'PENDING' ,
        2: 'HAS_ATTACHMENT',
        3: 'HAS_CHARGEE_DOC',
        4: 'HAS_CHARGEE_ATTACHMENT',
        5: 'ENTITY_ATTACHMENT_DOWNLOADED',
        6: 'BANK_ATTACHMENT_DOWNLOADED',
        7: 'EXPORTED',
        8: 'APPROVED',
        9: 'DECLINED',
        10: 'NOT_SUPPORTED'
    },
    DEBIT: 'מחייב',
    CREDIT: 'מזכה',
    ISRAEL_PREFIX: '+972',
    ISRAEL_INTERNATIONAL_NUMBER: '972',
    GENERAL_PHONE_PREFIX: '0',
    CSV_PREFIX: "\t",
    INSTITUTION_CREDIT: 'institution_credit',
    INSTITUTION_DEBIT: 'institution_debit',
    definitionsNames: {
        block_run_charge_flow: 'block_run_charge_flow'
    },
    reports: {
        CHARGES_AMOUNTS: "charges_amounts",
        PAYMENTS_BY_MONTH: "payments_by_month",
        BANKS_ORDERS_PDFS: "banks_orders_pdfs",
        BANK_CHARGES_REJECTED: "bank_charges_rejected",
        DOWNLOAD_MASAV_CHARGES: "download_masav_charges",
        DOWNLOAD_MASAV_INCOMES: "download_masav_incomes",
    },
    tasks: {
        deleteFinance: 'delete_finance',
        masavTransmitted: 'masav_transmitted',
        metaDataKey: "metaData",
        MASAV_TRANSMITTED: 'masav_transmitted',
        ADD_TO_REGISTERED: "add_to_registered",
        MASAV_INCOME_TRANSMITTED: 'masav_income_transmitted'
    },
    envColor: {
        local: {
            primary: {
                main: '#5b874b'
            },
            secondary: {
                main: '#5b874b'
            },
            login: {
                main: '#234915',
                secondary: '#081c00'
            }
        },
        dev: {
            primary: {
                main: '#90429e'
            },
            secondary: {
                main: '#90429e'
            },
            login: {
                main: '#6d1b7b',
                secondary: '#220029'
            }
        },
        stage: {
            primary: {
                main: '#ff9800'
            },
            secondary: {
                main: '#ff9800'
            },
            login: {
                main: '#b26a00',
                secondary: '#382200'
            }
        },
        prod: {
            primary: blue,
            secondary: blue,
            login: {
                main: '#313264',
                secondary: '#00023b'
            }
        },
    },
    availablePosCheckStorageConnection: {
        kolbolit: '10',
        aviv_kupot: '13'
    },
    definitionsLanguage: {
        he: 'he',
        en: 'en-us'
    }
}
