import React, { } from "react"
import { Create, SimpleForm, TextInput, useRedirect, useTranslate, Toolbar, SaveButton } from "react-admin"
import { withStyles, Button } from '@material-ui/core'
import styled from "styled-components"

const StyledCancelButton = styled(Button)`
  margin-left: 40px
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: "center",
  }
}
const FinanceSystemCreateToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/systems');
  }
  return (
    <Toolbar {...props} >
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
      <SaveButton />
    </Toolbar>
  );
})
const FinanceSystemCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list" toolbar={<FinanceSystemCreateToolbar />}>
        <TextInput source='data.name' label='customRoot.financeSystems.list.system_name' />
      </SimpleForm>
    </Create>
  )
}
export default FinanceSystemCreate
