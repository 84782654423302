import React from "react"
import { Edit, SimpleForm, TextInput, BooleanInput, SaveButton, Toolbar, useRedirect, useTranslate } from "react-admin"
import { withStyles, Button } from '@material-ui/core'
import styled from "styled-components"
const StyledCancelButton = styled(Button)`
  margin-left: 40px;
`
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(props => {
  const redirect = useRedirect();
  const translate = useTranslate()
  const handleCancel = () => {
    redirect('/systems');
  }
  return (
    <Toolbar {...props}>
      <StyledCancelButton variant='contained' onClick={handleCancel} color='submit'>
        {translate("customRoot.definitions.edit.cancel_button")}
      </StyledCancelButton>
      <SaveButton />
    </Toolbar>
  );
});


const transform = data => {
  return {  data:{
      ...data,
      name: data.name,
      active: data.status ? 1 : 0
    }
  }
}


const FinanceSystemEdit = props => {
  return (
    <Edit {...props} undoable={false} transform={transform} >
      <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
        <TextInput source='name' label='customRoot.financeSystems.list.system_name' />
        <BooleanInput label="customRoot.financeSystems.list.active" source="status" />
      </SimpleForm>
    </Edit>
  )
}
export default FinanceSystemEdit
