import userStore from '../stores/userStore'
import { commonRequests } from 'clearing-types'
import { DOMAIN } from '../fetch/constants'

export default {
  logout: async () => {
    try {
      userStore.setAuth(false)
      const res = await commonRequests.logout(DOMAIN, userStore)
      if (res.success) {
        return Promise.resolve()
      }
      else {
        return Promise.reject()
      }
    } catch (err) {
      return Promise.reject()
    }
  },
  checkAuth: () => {
    return userStore.isAuth ? Promise.resolve() : Promise.reject()
  },

  getPermissions: () => {
    return userStore.isAuthorized ? Promise.resolve() : Promise.reject()
  }
}
