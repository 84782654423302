import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import strings from 'tools/strings'
//import addEventsErrorsListeners from 'common/utils/errorHandling'

//addEventsErrorsListeners()


const createIframe = () => {
    var ifrm = document.createElement("iframe")
    ifrm.setAttribute("src", strings.appUrl + "/cross-domain-local_storage.html")
    ifrm.setAttribute("id", "app-iframe")
    ifrm.style.width = "1px"
    ifrm.style.height = "1px"
    ifrm.style.position = "absolute"
    ifrm.style.top = "-1000px"
    document.body.appendChild(ifrm)
}

async function handleMessage(e) {
    if (!e.data) return
    if (e.data.type === strings.WINDOW_POST_MESSAGE_TYPE.GET_ACTIVE_USER) {
        const url = new URL(window.location.href)
        const path = url.pathname
        if (path.includes('login')) {
            return
        }
        const stores = await import('stores')
        if (e.data.value) {
            stores.userStore.setMemberId(e.data.value)
        }
        stores.userStore.loadApp()
    }
}

window.addEventListener("message", handleMessage, false)
createIframe()

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
