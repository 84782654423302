import React, { useState, useEffect, Fragment } from "react"
import {
  useListContext,
  List,
  Datagrid,
  TextField,
  TopToolbar,
  BooleanField,
  downloadCSV,
  SelectArrayInput,
  Filter,
  TextInput,
  SelectInput,
  useLocale,
  useTranslate,
  useDataProvider,
  FunctionField
} from "react-admin"
import _ from 'lodash'

import { observer, inject } from "mobx-react"
import CreateLink from "./createLink"
import SendLink from "./sendLink"
import styled from "styled-components"
import DownloadIcon from "@material-ui/icons/GetApp"
import { IconButton } from "@material-ui/core"
import { YoutubeSearchedFor } from '@material-ui/icons'
import jsonExport from 'jsonexport/dist';
import strings from '../../tools/strings'
import BulkBankOrdersStatusButton from "./bulkBankOrdersStatusActions"
import BulkUnregisterButton from "./bulkUnregisterAction"
import { requests } from "fetch/requests"
import { FILTER_OUT_ARRAY, FILTER_LOGIC } from 'clearing-types'

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`
const IconSearch = styled(YoutubeSearchedFor)`
  color: 'primary';
`

const PostRowClick = props => {
  const { is_admin } = props.record
  return (
    <Fragment>
      {is_admin && <CreateLink type='admin_org' {...props} />}
      <CreateLink type='budget' {...props} />
      {is_admin && <SendLink {...props} />}
    </Fragment>
  )
}

const formatRecords = (record) => {
  if (!_.isEmpty(record.Mobile)) {
    const Mobile = formatMobileField(record.Mobile)
    const IDNumber = strings.CSV_PREFIX + record.IDNumber
    return { ...record, Mobile, IDNumber }
  }
  if (!_.isEmpty(record.mobile)) {
    const mobile = formatMobileField(record.mobile)
    return { ...record, mobile }
  }
  return record
}

const formatMobileField = (mobile) => {
  if (mobile.startsWith(strings.ISRAEL_INTERNATIONAL_NUMBER)) {
    return strings.CSV_PREFIX + strings.GENERAL_PHONE_PREFIX + mobile.replace(strings.ISRAEL_INTERNATIONAL_NUMBER, '')
  }
  if (mobile.startsWith(strings.ISRAEL_PREFIX)) {
    return strings.CSV_PREFIX + strings.GENERAL_PHONE_PREFIX + mobile.replace(strings.ISRAEL_PREFIX, '')
  }
  return mobile
}

const getExporter = fileName => async records => {
  const formatedRecords = _.map(records, record => {
    return formatRecords(record)
  })
  jsonExport(formatedRecords, {}, (err, csv) => {
    if (err) {
      console.log(strings.downloadError, err)
    }
    const { usersExportFileName, hebrewSupportPrefix } = strings
    downloadCSV(hebrewSupportPrefix + csv, fileName || usersExportFileName)
  })
}

const UsersList = ({ userStore, permissions, ...props }) => {
  const [userFilters, setUserFilters] = useState([])
  const [tags, setTags] = useState([])
  const [institutions, setInstitutions] = useState([])
  const local = useLocale()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await requests.getTagsAndFilters(userStore, userStore.org, local)
        const data = res.data
        const filters = _.filter(data, e => e.type === "filter" && !FILTER_OUT_ARRAY.includes(e.code))
        const filterChoices = _.map(filters, v => {
          return { id: v.code, name: v.value }
        })
        const tagElements = _.filter(data, e => e.type === "tag")
        const tagChoices = _.map(tagElements, v => {
          return { id: v.code, name: v.value }
        })
        setUserFilters(filterChoices)
        setTags(tagChoices)
        const institutionsRes = await requests.getOrgInstitutions(userStore)
        const institutionsData = institutionsRes?.data?.map(institution => {
          return { code: institution.data.code, name: institution.data.name.he }
        })
        setInstitutions(institutionsData)
      } catch (e) {
        console.log(strings.downloadError, e)
      }
    }
    fetchData()
  }, [])

  const tmpText = {}
  const UsersFilter = props => {
    const translate = useTranslate()
    const { selectedIds, ids } = useListContext()

    const parseSearch = input => {
      if (input && input.length > 1) {
        return input
      }
      tmpText.value = input
    }
    const parseFormat = value => {
      if (!value) {
        return tmpText.value
      }
      return value
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Filter {...props}>
          <TextInput
            label='customRoot.orders.list.search'
            source='searchTerm'
            alwaysOn
            autoFocus
            parse={input => parseSearch(input)}
            format={value => parseFormat(value)}
          />
          {!_.isEmpty(userFilters) && <SelectArrayInput style={{ minWidth: 150 }} alwaysOn record={{ filters: [] }} source='filters' label='customRoot.users.list.filters' choices={userFilters} />}
          {!_.isEmpty(tags) && <SelectArrayInput style={{ minWidth: 150 }} alwaysOn record={{ tags: [] }} source='tags' label='customRoot.users.list.tags' choices={tags} />}
          <SelectInput
            alwaysOn
            source='filterLogic'
            allowEmpty={false}
            choices={Object.values(FILTER_LOGIC).map(f => ({ id: f, name: translate(`customRoot.filters.${f}`) }))}
            label='customRoot.users.list.filterLogic'
          />
        </Filter>
        {ids?.length ? <span style={{ marginTop: '2rem', marginBottom: '-2rem', width: '4rem', zIndex: 100, fontSize: 'small' }}>
          {_.every(ids, (id) => _.includes(selectedIds, id)) ? translate('customRoot.users.list.cleanSelected')
            : translate('customRoot.users.list.selectAllPage')}</span>
          : <div />}

      </div>
    )
  }

  const ListActions = () => {
    const { resource, filterValues, setFilters } = useListContext()
    const translate = useTranslate()
    const restProvider = useDataProvider()
    const resetFilter = () => {
      setFilters({ filterLogic: FILTER_LOGIC.AND })
    }
    return (
      <TopToolbar>
        <IconButton
          size='small'
          color='primary'
          onClick={() => {
            resetFilter()
          }}
        >
          <IconSearch />
          {translate("ra.action.clean_search")}
        </IconButton>
        <div style={{ width: "1rem", height: "1rem" }} />
        <IconButton
          size='small'
          color='primary'
          onClick={async () => {
            try {
              const res = await restProvider(strings.CUSTOM_EXPORT, resource, { format: strings.exportFormats.financeFormat, filters: filterValues })
              const exporter = getExporter(strings.usersFinanceExportAllFileName)
              exporter(res.data)
            } catch (e) {
              console.log("error", e)
            }
          }}
        >
          <DownloadIcon />
          {translate("ra.action.exportFinance")}
        </IconButton>
        <div style={{ width: "1rem", height: "1rem" }} />
        <IconButton
          size='small'
          color='primary'
          onClick={async () => {
            try {
              const res = await restProvider(strings.CUSTOM_EXPORT, resource, { format: strings.exportFormats.uploadFormat, filters: filterValues })
              const exporter = getExporter(strings.usersExportAllUploadFormatFileName)
              exporter(res.data)
            } catch (e) {
              console.log("error", e)
            }
          }}
        >
          <DownloadIcon />
          {translate("ra.action.export")}
        </IconButton>
      </TopToolbar>
    )
  }
  const BulkActions = () => {
    const { resource, selectedIds, data, filterValues } = useListContext()
    const translate = useTranslate()
    const restProvider = useDataProvider()
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <IconButton
          size='small'
          color='primary'
          resource={resource}
          onClick={async () => {
            try {
              const res = await restProvider(strings.CUSTOM_EXPORT, resource, { ids: selectedIds, format: strings.exportFormats.financeFormat, filters: filterValues })
              const exporter = getExporter(strings.usersFinanceExportFileName)
              exporter(res.data)
            } catch (e) {
              console.log("error", e)
            }
          }}
        >
          <DownloadIcon />
          {translate("ra.action.exportFinanceChoosen")}
        </IconButton>
        <div style={{ width: "2rem", height: "1rem" }} />
        <IconButton
          size='small'
          color='primary'
          resource={resource}
          onClick={async () => {
            try {
              const res = await restProvider(strings.CUSTOM_EXPORT, resource, { ids: selectedIds, format: strings.exportFormats.uploadFormat, filters: filterValues })
              const exporter = getExporter(strings.usersExportUploadFormatFileName)
              exporter(res.data)
            } catch (e) {
              console.log("error", e)
            }
          }}
        >
          <DownloadIcon />
          {translate("ra.action.exportChoosen")}
        </IconButton>
        <div style={{ width: "2rem", height: "1rem" }} />
        <BulkUnregisterButton data={data} {...props} selectedIds={selectedIds} />
        <div style={{ width: "2rem", height: "1rem" }} />
        <BulkBankOrdersStatusButton data={data} {...props} selectedIds={selectedIds} />
      </div>
    )
  }
  return (
    <FormContainer>
      {userStore.org && (
        <List pagination={false} filterDefaultValues={{ filterLogic: FILTER_LOGIC.AND }} {...props} actions={<ListActions {...props} />} filter={{ org: userStore.org }} bulkActionButtons={<BulkActions {...props} />} exporter={getExporter()} filters={<UsersFilter />}>
          <Datagrid expand={<PostRowClick {...props} />} rowClick='edit'>
            <TextField source='entity' label='customRoot.users.list.entity' sortable={false} />
            <TextField source='teudat_zehut' label='customRoot.users.list.teudat_zehut' sortable={false} />
            <TextField source='last_name' label='customRoot.users.list.last_name' sortable={false} />
            <TextField source='first_name' label='customRoot.users.list.first_name' sortable={false} />
            <TextField source='email' label='customRoot.users.list.email' sortable={false} />
            <TextField dir="ltr" source='mobile' label='customRoot.users.list.phone' sortable={false} />
            <BooleanField source='registration_status' label='customRoot.users.list.registration_status' sortable={false} />
            <FunctionField label='customRoot.users.list.is_admin' sortable={false} render={(record) => {
              const rolesString = _.join(record.roles);
              return <TextField record={{ value: rolesString }} source='value' />
            }
            } />
            {
              institutions?.map(institution => {
                const isDebit = institution.code?.includes('debit')
                const labelPrefix = isDebit ? strings.DEBIT : strings.CREDIT
                return <FunctionField label={`${labelPrefix}: ${institution.name}`} render={(record) => {
                  const masavStatusObject = record.masav_status?.find(a => a.institution_code === institution.code)
                  const masavStatus = masavStatusObject?.status
                  const masavStatusDescription = masavStatus !== undefined ? strings.BankOrderStatus[masavStatus]?.toLowerCase() : undefined

                  return <TextField record={{ value: masavStatusDescription }} source='value' />
                }
                } />
              })
            }
          </Datagrid>
        </List>
      )}
    </FormContainer>
  )
}

export default inject("userStore")(observer(UsersList))
