import React from "react"
import { useNotify, List, Pagination, Datagrid, TextField, FunctionField } from "react-admin"
import * as moment from 'moment'
import JobFilter from "./jobFilter"
import styled from "styled-components"
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel';
import fileSystemApi from 'fetch/fileSystemApi'

const ListContainer = styled.div`
  margin-top: 20px;
`

const PostPagination = props => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

const JobStatus = ({ status }) => {
  
  const statusList = {
    cancelled: '#708090',
    pending: '#A9A9A9',
    in_progress: '#4682B4',
    finished: '#006400',
    failed: '#A52A2A' 
  } 

  return (
    <span style={{ color: statusList[status] }}>
        {`${status}`.replace('_', ' ').toUpperCase()}
    </span> 
  )
}

const StopButton = ({ record }) => {
  const notify = useNotify()

  const handleStop = async () => {
    try {
      const response = await fileSystemApi.request(
        'PUT', 
        fileSystemApi.endpoints.cancelTask(record.id), 
        { statusId: 5 }
      )

      notify("customRoot.requestResult.success", "success");
     
    } catch(e) {
      notify("customRoot.requestResult.error", "warning");
    }
  };

  return (
    record.status.name == 'pending' ? <Button startIcon={<CancelIcon />} onClick={handleStop} color="primary"></Button> : null
  )
}


const JobList = props => {
  return (
    <ListContainer>
    <List {...props} filters={<JobFilter />}  pagination={<PostPagination />} perPage={100} exporter={false}  bulkActionButtons={false}>
      <Datagrid rowClick='show'>
        <TextField source='id' label='customRoot.jobs.list.id' sortable={false} />
        <FunctionField label="customRoot.jobs.list.type" render={record => `${record.type.description}`} />
        <FunctionField label="customRoot.jobs.list.scope" render={record => `${record.scope.name}`} />
        <FunctionField label='customRoot.jobs.list.input_data' render={record => record.organization.code} sortable={false} />      
        <FunctionField label="customRoot.jobs.list.status" render={record => <JobStatus status={record.status.name} />} />
        <FunctionField label='customRoot.jobs.list.created_at' render={record => moment(record.createdAt).format('DD/MM/YYYY hh:mm:ss')} sortable={false} />
        <StopButton/>
      </Datagrid>
    </List>
    </ListContainer>
  )
}

export default JobList
