import React from "react"
import * as moment from 'moment'
import { Show, useShowController, SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import { Chip } from "@material-ui/core"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import EastIcon from '@mui/icons-material/West';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
}));


const Title = styled.h4`
  margin: 20px;
`
const Title2 = styled.h4`
  color: #808080;
  margin: 0px;
`

const ContentContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  `

const JobStatus = ({ status }) => { 
    const statusList = {
      pending: '#A9A9A9',
      in_progress: '#4682B4',
      finished: '#006400',
      failed: '#A52A2A' 
    } 
  
    return (
      <span style={{ color: statusList[status] }}>{`${status}`.replace('_', ' ').toUpperCase()}</span>  
    )
  }

const JobShow = (props) => {
  const { loaded, loading, record } = useShowController(props);
  const classes = useStyles();

  if (!loaded) return '';

  const hasError = (item) => {
    return item.text.includes("Error: ")
  }

  const history = record.history ?? [];
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ContentContainer>
          <Title>{record.type.name.replaceAll('_', ' ').toUpperCase()} JOB </Title>
          <Title2>Date: {moment(record.createdAt).format('MMM DD, YYYY hh:mm:ss')} / Org: {record.organization.code}/ Status: <JobStatus status={record.status.name}/></Title2>
         

          <List className={classes.root}>
            {history.map(item => {
                return (<><ListItem style={{ color: hasError(item) ? 'red' : '' }}>
                    <ListItemAvatar>
                      <Avatar style={{ background: hasError(item) ? 'red' : '' }}>
                        <EastIcon style={{ background: hasError(item) ? 'red' : '' }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.text} secondary={moment(item.createdAt).format('MMM DD, YYYY hh:mm:ss')} />
                  </ListItem>
                  <Divider variant="inset" component="li" /></>)
            })}
            </List>
        </ContentContainer>
      </SimpleShowLayout>
    </Show>
  )
};

export default JobShow;