

import React, { useState, Fragment } from "react"
import { useTranslate, useNotify, useUnselectAll, useRefresh } from "react-admin"

import { observer, inject } from "mobx-react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/core/styles"
import { IconButton } from "@material-ui/core"




const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  }
}));

const BulkBankOrdersStatusButton = ({ userStore,data, ...props }) =>  {
  
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const translate = useTranslate()
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

   const handleClick = () => setOpen(true);

  
    const executeAction = async (status) => {
      const   entities = props.selectedIds.map(id => data[id].entity)
      const uniqueEntities =  entities.filter((entity, index) => {
        return entities.indexOf(entity) === index;
    })
      userStore.setBankOrdersEntitiesList(uniqueEntities)
      const bankOrdersSelectedMembers = await userStore.setBankOrderStatus(status)
      unselectAll()
      refresh()   
      if (bankOrdersSelectedMembers) notify(`${bankOrdersSelectedMembers.data.updated} ${translate("customRoot.general.updated")}`, "success")
      else notify("customRoot.general.error", "warning")
    }
  
    return (
      <Fragment>
        <IconButton          
            color='primary'
            size='small'
            onClick={() => handleClick()}>  
            {translate("customRoot.users.list.actions.bank_order_status")}   
        </IconButton>
        <DialogForm open={open} setOpen={setOpen} executeAction={executeAction} />
      </Fragment>
    );
  };
  
  const DialogForm = ({ open, setOpen, executeAction }) => {
    const translate = useTranslate()
    const classes = useStyles();
  
    const handleClose = () => {
      setOpen(false)
    }
  
    const handleConfirm = () => {
      executeAction(status)
      setOpen(false)
    }

    const [status, setStatus] = React.useState(8);
 
    const handleChange = (event) => {
      setStatus(event.target.value); 
    };
   
    return (
      <div dir='rtl'>
        <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' dir='rtl'>
        <DialogTitle color="primary" id='form-dialog-title'>{translate("customRoot.users.list.actions.bank_order_status")}</DialogTitle>
              <DialogContent>
                <DialogContentText>{translate("customRoot.users.list.actions.choose_status")}</DialogContentText>
                <FormControl  className={classes.formControl}>
                  <Select
                    labelId="bank_order_status_label"
                    id="bank_order_status_select"
                    value={status}
                    onChange={handleChange}                  >
                    <MenuItem value={8}>{translate("customRoot.users.list.actions.approved_status")}</MenuItem>
                    <MenuItem value={9}>{translate("customRoot.users.list.actions.declined_status")}</MenuItem>
                    <MenuItem value={0}>{translate("customRoot.users.list.actions.canceled_status")}</MenuItem>
                  </Select>
              </FormControl>
              </DialogContent>        
            <DialogActions>
            <Button onClick={handleClose} color='primary'>
              {translate("customRoot.orders.modal.cancel")}
            </Button>
            <Button onClick={handleConfirm} color='primary'>
              {translate("customRoot.orders.modal.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  export default inject("userStore")(observer(BulkBankOrdersStatusButton))


