import React, { useEffect, useState } from "react"
import { observer, inject } from "mobx-react"
import { TextInput, useRedirect, SelectInput, useTranslate, required, minLength, maxLength } from "react-admin"
import styled from "styled-components"
import { Form } from "react-final-form"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import WarningDialog from "../../utils/WarningDialog"
import arrayMutators from "final-form-arrays"

const StyledTitle = styled.div`
    font-weight:bold;
    padding:15px 0 15px 0;
`
const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  margin-right: 50px;
`
const StyledOkButton = styled(Button)`
  background-color: green;
`
const StyledDeleteButton = styled(StyledButton)`
  background-color: red;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const useStyles = makeStyles({
  width: {
    width: 400
  }
})


const InstitutionSection = props => {
  const { handleSubmit, deleteWarning, institution, handleCancel, isUpdate } = props
  const translate = useTranslate()
  const validateAccountNumber = [required()];
  const validateBankCode = [required(), minLength(2), maxLength(2)];
  const validateBranchCode = [required(), minLength(3), maxLength(3)];
  const validateInstitutionCode = [required(), minLength(5), maxLength(5)];
  const validateTransactionCode = [required(), minLength(8), maxLength(8)];
  const validateRequired = [required()];
  const isEdit = Boolean(institution?.data?.code)
  return (
    <BodyContainer>
      <SelectInput
        disabled={isUpdate}
        source='sub_type'
        validate={validateRequired}
        label='customRoot.definitions.edit.institution.type'
        choices={[
          { id: "institution_credit", name: "customRoot.definitions.edit.institution.institution_credit" },
          { id: "institution_debit", name: "customRoot.definitions.edit.institution.institution_debit" }
        ]}
      />
      <TextInput source='data.name.he' validate={validateRequired} label='customRoot.definitions.edit.institution.name.he' />
      <TextInput dir='ltr' source='data.name.en' validate={validateRequired} label='customRoot.definitions.edit.institution.name.en' />
      <TextInput source='data.institution_code' validate={validateInstitutionCode} type='number' label='customRoot.definitions.edit.institution.institution_code' />
      <TextInput source='data.transaction_code' validate={validateTransactionCode} type='number' label='customRoot.definitions.edit.institution.transaction_code' />
      <TextInput source='data.bank_code' validate={validateBankCode} type='number' label='customRoot.definitions.edit.institution.bank_code' />
      <TextInput source='data.branch_code' validate={validateBranchCode} type='number' label='customRoot.definitions.edit.institution.branch_code' />
      <TextInput source='data.account_number' validate={validateAccountNumber} type='number' label='customRoot.definitions.edit.institution.account_number' />
      <ButtonsContainer>
        <StyledOkButton variant='contained' color='primary' type='submit' onClick={handleSubmit}>
          {translate("customRoot.definitions.edit.submit_button")}
        </StyledOkButton>
        {isEdit && (
          <StyledDeleteButton variant='contained' color='secondary' onClick={deleteWarning}>
            {translate("customRoot.definitions.edit.delete_button")}
          </StyledDeleteButton>
        )}
        <StyledButton variant='contained' color='primary' type='cancel' onClick={handleCancel}>
          {translate("customRoot.definitions.edit.cancel_button")}
        </StyledButton>
      </ButtonsContainer>
    </BodyContainer>
  )
}
const WarningActions = { delete: "delete", skip: "skip" }

const InstitutionCreateAndEdit = ({ corporationStore, ...props }) => {
  const { id, version } = props
  const [institution, setInstitution] = useState(null)
  const classes = useStyles()
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningContentTexts, setWarningContentTexts] = useState({ content: "", title: "" });
  const [warningAction, setWarningAction] = useState(WarningActions.skip);
  const redirect = useRedirect();
  const translate = useTranslate()
  const corporationName = corporationStore.currentCorporation?.data?.name?.he || ''

  useEffect(() => {
    if (corporationStore.currentCorporation) {
      if (id) {
        setInstitution(corporationStore.getInstitution(id))
      }
    } else {
      redirect('/corporations');
    }
  }, [])

  const onSave = async (res) => {
    res.relations = [
      {
        sub_type: 'corporation',
        code: corporationStore.currentCorporation.data.code
      }
    ]
    if (!id) {
      return await corporationStore.createInstitution(res)
    }
    res.id = institution.data.code
    // res.data.code = institution.data.code
    // res._rev = institution._rev
    // res.id = institution._id
    return await corporationStore.updateInstitution(res, id)
  }

  const onSuccess = async () => {
    setWarningOpen(false)
    redirect(`/corporations/${corporationStore.currentCorporation.data.code}`)
  }
  const handleCancel = () => {
    redirect(`/corporations/${corporationStore.currentCorporation.data.code}`)
  }
  const onFailed = async (error) => {
    setWarningContentTexts({ content: error, title: "customRoot.definitions.failureMessage" })
    setWarningOpen(true)
  }
  const onDelete = async () => {
    return await corporationStore.deleteInstitution(institution.data.code, institution.sub_type)
  }
  const handleCloseWarning = () => {
    setWarningOpen(false)
  }
  const deleteWarning = () => {
    setWarningContentTexts({ content: "customRoot.definitions.deleteWarningContent", title: "customRoot.definitions.deleteWarningTitle" })
    setWarningAction(WarningActions.delete)
    setWarningOpen(true)
  }
  const handleWarningConfirm = async () => {
    if (warningAction === WarningActions.delete) {
      const res = await onDelete()
      if (!res.success) {
        onFailed(res.error)
      }
      else {
        onSuccess()
      }
      setWarningAction(WarningActions.skip)
    } else {
      setWarningOpen(false)
    }
  }

  const checkInstitutionDataIsNotEmpty = (institution) => {
    return institution &&
      institution.data &&
      institution.data.name &&
      institution.data.name.he &&
      institution.data.name.en &&
      institution.data.account_number &&
      institution.data.bank_code && institution.data.bank_code.length === 2 &&
      institution.data.branch_code && institution.data.branch_code.length === 3 &&
      institution.data.institution_code && institution.data.institution_code.length === 5 &&
      institution.data.transaction_code && institution.data.transaction_code.length === 8
  }

  const handleClick = async (results) => {
    if (!checkInstitutionDataIsNotEmpty(results)) {
      setWarningContentTexts({ content: "customRoot.definitions.missingData", title: "customRoot.definitions.failureMessage" })
      setWarningOpen(true)
      return
    }
    const res = await onSave(results)
    if (!res.success) {
      onFailed(res.error)
    }
    else {
      onSuccess()
    }
  }
  return (
    <Form
      onSubmit={handleClick}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      initialValues={{
        sub_type: institution?.sub_type,
        data: {
          name: institution?.data?.name,
          institution_code: institution?.data.institution_code,
          transaction_code: institution?.data.transaction_code,
          bank_code: institution?.data.bank_code,
          branch_code: institution?.data.branch_code,
          account_number: institution?.data.account_number
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <FormContainer>
          <StyledTitle>{translate('customRoot.definitions.edit.named_corporation', { field: corporationName })}: {translate(id ? 'customRoot.definitions.edit.institution.title_update' : 'customRoot.definitions.edit.institution.title_create')}</StyledTitle>
          <StyledForm onSubmit={handleSubmit} className={classes.width}>
            <InstitutionSection isUpdate={Boolean(id)} institution={institution} handleSubmit={handleSubmit} deleteWarning={deleteWarning} handleCancel={handleCancel} />
          </StyledForm>
          <WarningDialog open={warningOpen} handleClose={handleCloseWarning} handleConfirm={handleWarningConfirm} content={warningContentTexts.content} title={warningContentTexts.title} />
        </FormContainer>
      )}></Form>
  )
}
export default inject("corporationStore")(observer(InstitutionCreateAndEdit))

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
}