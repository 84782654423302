import React, {useState, useEffect} from "react"
import { AppBar, useTranslate, useNotify, useUnselectAll } from "react-admin"
import { observer, inject } from "mobx-react"
import { useHistory } from 'react-router-dom';
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  },
  formControl: {
    background: 'white',
    width: '200px',
    margin: '8px',
  }
})

const MyAppBar = ({ userStore, permissions, ...props }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const [orgChoices, setOrgChoice] = useState([])
  const history = useHistory();
  const unselectAll = useUnselectAll()
  const getOrganizationsList = async () => {
    try{
      const orgList = await userStore.getOrganizationsList()
      if (!orgList.success) throw new Error()
      const {data} = orgList
      let choices = [<option aria-label='None' value='' key={0} />]
      const organizations = data.map(org => <option key={org.id}  value={org.id}>{`${org.name} - ${org.id}`}</option>)
      setOrgChoice(choices.concat(organizations))
    } catch(err){
      notify("customRoot.definitions.failureMessage", "error")
    }
  }
  useEffect(() => {
    getOrganizationsList()
  },[JSON.stringify(userStore.organizations)])
  
  return (
    <AppBar {...props}>
      <Typography variant='h6' color='inherit' className={classes.title} id='react-admin-title' />
      <FormControl variant='filled' className={classes.formControl}>
        <InputLabel htmlFor='select_org' shrink={true}>
          {translate("customRoot.definitions.org")}
        </InputLabel>
        <Select
          className={classes.width}
          native
          value={userStore.org}
          onChange={event => {
            userStore.setOrg(event.target.value)
            history.push('/');
            unselectAll('users')
          }}
          inputProps={{
            name: "select_org",
            id: "select_org"
          }}
        >
          {orgChoices}
        </Select>
      </FormControl>
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default inject("userStore")(observer(MyAppBar))