import React from 'react';
import { Route } from 'react-router-dom';
import { TwoFactor } from "../components/login"
import { reportsList } from "../components/reports"
import { servicesList} from '../components/services'
import { UploadIncomes } from 'components/slika_processes/credit_process/upload_incomes'
import { SlikaProcesses } from '../components/slika_processes'
import { ChargeProcess } from '../components/slika_processes/charge_process'
import { CreditProcess } from '../components/slika_processes/credit_process'
import { ChargePermissions } from '../components/charge_permissions'


export default [
    <Route exact path="/2fa" component={TwoFactor} noLayout />,
    <Route exact path="/reports" component={reportsList} />,
    <Route exact path="/services" component={servicesList} />,
    <Route exact path="/upload_incomes" component={UploadIncomes} />,
    <Route exact path="/slika_processes" component={SlikaProcesses} />,
    <Route exact path="/charge_process" component={ChargeProcess} />,
    <Route exact path="/credit_process" component={CreditProcess} />,
    <Route exact path="/charge_permissions" component={ChargePermissions} />,
]
