import { stringify } from 'query-string'
import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, GET_MANY, GET_MANY_REFERENCE, DELETE_MANY, UPDATE_MANY } from 'react-admin'
import { METHOD, ACTIVE_INACTIVE } from 'clearing-types'
import strings from '../tools/strings'
import userStore from '../stores/userStore'
import { requests } from 'fetch/requests'
import _ from 'lodash'
import fileSystemApi from 'fetch/fileSystemApi'

/**
 * @enum {string}
 */
export const Resource = {
  ROLES: 'roles',
  GLOBAL_DEFINITIONS: 'global_definitions',
  DEFINITIONS: 'definitions',
  USERS: 'users',
  SYSTEMS: 'systems',
  POS: 'pos',
  CREDIT_CARD_GATEWAYS: 'credit_card_gateways',
  INSTITUTION: 'institution',
  FINANCIAL_BOOK: 'financialBook',
  FINANCIAL_COMPANY: 'financialCompany',
  FINANCIAL_RELATIONS: 'financialRelations',
  GET_RELATIONS: 'get_relations',
  CORPORATIONS: 'corporations',
  ORGANIZATIONS: 'organizations',
  UPLOAD: 'upload',
  SOURCE_ATTACHMENTS: 'source_attachments',
  JOB_MANAGER: 'job_manager'
}
/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {Resource} resource Resource name, e.g. 'posts'
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */

export default async (type, resource, params) => {
  let url = ''
  let method = ''
  let body = undefined
  let useFileSystemApi = resource === Resource.SOURCE_ATTACHMENTS
  if (resource === Resource.FINANCE_GATEWAYS) {
    params.org = userStore.org
  }

  switch (type) {
    case strings.RESTART_SERVICE: {
      const serviceName = params.serviceName
      url = `/${serviceName}${strings.KILL_SERVICES_SUFFIX}`
      method = METHOD.GET
      break
    }
    case 'CUSTOM_EXPORT': {
      if (resource === Resource.USERS) {
        if (_.isEmpty(params.ids)) {
          url = getOrgExportUrl(params)
        } else {
          url = getMembersExportUrl(params)
          body = getMembersExportPayload(params)
        }
        method = METHOD.POST
      }
      if (resource === Resource.FINANCIAL_RELATIONS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}`
        body = params
        method = METHOD.POST
      }
      if (resource === Resource.INSTITUTION) {
        url = `${strings[resource].prefix}banks_orders/${userStore.org}/${params}`
        method = METHOD.POST
      }
      if (resource === Resource.GET_RELATIONS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].middle}${params.code}${strings[resource].postfix}`
        method = METHOD.GET
      }
      if (resource === Resource.SYSTEMS) {
        method = METHOD.GET
        url = `${strings[resource]}`
        // /finance/api/v1/systems
      }
      if (resource === Resource.FINANCIAL_COMPANY) {
        method = METHOD.GET
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}`
      }
      break
    }
    case GET_LIST: {
      method = METHOD.GET
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      if (resource === Resource.USERS && params?.filter?.status === strings.NO_STATUS) {
        delete (params.filter.status)
      }
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter)
      }
      let urlPath = `${strings[resource]}`

      if (resource === Resource.FINANCE_GATEWAYS) {
        urlPath = `${urlPath}/${userStore.org}`
      }
      if (resource === Resource.CORPORATIONS) {
        urlPath = `${strings[resource].prefix}${userStore.org}${strings[resource].middle}`
      }
      if (resource === Resource.POS) {
        urlPath = `${strings[resource]}/${userStore.org}`
      }
      if (resource === Resource.SOURCE_ATTACHMENTS) {
        urlPath = fileSystemApi.endpoints.getMappings(userStore.org)
      }
      if (resource === Resource.JOB_MANAGER) {
        urlPath = `${strings[resource]}`
      }
      if (params.id) url = `${urlPath}/${params.id}`
      else url = `${urlPath}?${stringify(query)}`
      break
    }
    case GET_ONE:
      method = METHOD.GET
      if (resource === Resource.SYSTEMS) {
        break
      }
      if (params.org) {
        const query = {
          def: JSON.stringify(params.def)
        }
        url = `${strings[resource]}/${params.org}/${params.id}?${stringify(query)}`
      } else {
        if (resource === Resource.CORPORATIONS) {
          url = `${strings[resource].prefix}${userStore.org}${strings[resource].middle}${params.id}`
        }
        else if (resource === Resource.JOB_MANAGER) {
          url = 'id' in params ? `${strings[resource]}/${params.id}` : strings['job_manager_scopes']
        }
        else if (resource === Resource.FINANCIAL_BOOK || resource === Resource.FINANCIAL_COMPANY) {
          url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}${params.id}`
        }
        else if (resource === Resource.POS) {
          url = `${strings[resource]}/${userStore.org}/${params.id}`
        }
        else if (resource === Resource.SOURCE_ATTACHMENTS) {
          url = fileSystemApi.endpoints.getMapping(params.id)
        }
        else {
          url = `${strings[resource]}/${params.id}`
        }
      }
      break
    case CREATE:
      if (resource === Resource.UPLOAD) {
        method = METHOD.POST
        url = `${strings[resource].prefix}${strings[resource].postfix}`
        body = params.data
        break
      }
      if (resource === Resource.CORPORATIONS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].middle}`
        body = {
          data: params.data,
          relations: params.relations
        }
        method = METHOD.POST
        break
      }
      if (resource === Resource.FINANCIAL_BOOK || resource === Resource.FINANCIAL_COMPANY) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}`
        body = {
          data: params.data,
          relations: params.relations
        }
        method = METHOD.POST
        break
      }
      if (resource === Resource.INSTITUTION) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}${params.sub_type === "institution_credit" ? "credit" : "debit"}`
        body = { data: params.data, relations: params.relations }
        method = METHOD.POST
        // return;
        break
      }

      if (resource === Resource.CREDIT_CARD_GATEWAYS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}`
        body = { data: params.data, relations: params.relations }
        method = METHOD.POST
        // return;
        break
      }
      if (resource === Resource.POS) {
        url = `${strings[resource]}/${userStore.org}`
        body = params.data
        method = METHOD.POST
        // return;
        break
      }
      else if (resource === Resource.SOURCE_ATTACHMENTS) {
        url = fileSystemApi.endpoints.addMapping
        body = {
          source: {
            name: params.data.source,
            exSourceId: '',
            status: ACTIVE_INACTIVE[ACTIVE_INACTIVE.ACTIVE].toLowerCase(),
            sourceHebrew: params.data.sourceHebrew
          },
          communityId: userStore.org,
          emails: params.data.email || [],
          phones: params.data.mobile || [],
          extRegEx: params.data.extRegEx
        }
        method = METHOD.POST
        break
      }
      else {
        url = `${strings[resource]}${params.org ? `/${params.org}` : ''}`
      }
      method = METHOD.POST
      body = params.data
      break
    case UPDATE:
      method = METHOD.PUT
      if (resource === Resource.USERS) {
        url = `${strings[resource]}${params.org ? `/${params.org}` : ''}/${params.id}`
        const { updated_roles, existing_available_roles } = params.data
        params.data.roles_add = updated_roles ? updated_roles.filter((role) => !existing_available_roles.includes(role)) : []
        params.data.roles_remove = updated_roles ? existing_available_roles.filter((role) => !updated_roles.includes(role)) : []

        if ("hasBudgetRole" in params.data) {
          if (params.data.hasBudgetRole) {
            params.data.roles_add.push(params.data.budgetRole);  
          }
          
          if (!params.data.hasBudgetRole) {
            params.data.roles_remove.push(params.data.budgetRole);  
          } 
        }

        body = params.data
        break
      }
      else if (resource === Resource.CORPORATIONS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].middle}${params.id}`
        body = {
          data: {
            name: params.data.name, business_number: params.data.business_number, members_type: params.data.members_type
          },
          relations: params.relations
        }
        break
      }
      else if (resource === Resource.INSTITUTION) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}${params.sub_type === "institution_credit" ? "credit" : "debit"}/${params.id}`
        body = { data: params.data, relations: params.relations }
        break
      }
      else if (resource === Resource.CREDIT_CARD_GATEWAYS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}/${params.id}`
        body = { data: params.data, relations: params.relations }
        break
      }
      else if (resource === Resource.FINANCIAL_BOOK) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}${params.id}`
        body = { data: params.data, relations: params.relations }
      }
      else if (resource === Resource.FINANCIAL_COMPANY) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}${params.id}`
        body = { data: params.data, relations: params.relations }
      }
      else if (resource === Resource.POS) {
        url = `${strings[resource]}/${userStore.org}/${params.id}`
        body = _.omit(params.data, ['code', 'id', 'system_name'])
      }
      else if (resource === Resource.SOURCE_ATTACHMENTS) {
        url = fileSystemApi.endpoints.updateMapping(params.id)
        body = {
          //fileSourceMappingId: params.id,
          source: {
            name: params.data.source,
            exSourceId: '',
            status: params.data.status,
            sourceHebrew: params.data.sourceHebrew
          },
          communityId: userStore.org,
          emails: params.data.email || [],
          phones: params.data.mobile || [],
          extRegEx: params.data.extRegEx
        }
      }
      else {
        url = `${strings[resource]}${params.org ? `/${params.org}` : ''}/${params.id}`
        body = params.data
      }
      break
    case UPDATE_MANY:
      const query = {
        filter: JSON.stringify({ id: params.ids })
      }
      url = `/${resource}?${stringify(query)}`
      method = METHOD.PATCH
      body = params.data
      break
    case DELETE:
      method = METHOD.DELETE
      if (resource === Resource.CORPORATIONS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].middle}${params.id}`
        // /organizations/api/v1/{org}/financial_structures/corporations/{corporation_code}
      }
      else if (resource === Resource.FINANCIAL_BOOK || resource === Resource.FINANCIAL_COMPANY) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}${params.id}`
        // /organizations/api/v1/{org}/financial_structures/financial_books/{code}
        // /organizations/api/v1/{org}/financial_structures/financial_companies/{financialCompanyCode}
        break
      }
      else if (resource === Resource.INSTITUTION) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}${params.sub_type === "institution_credit" ? "credit" : "debit"}/${params.id}`
        // /organizations/api/v1/{org}/financial_structures/institutions/{institutionType}/{institution_code}
        break
      }
      else if (resource === Resource.CREDIT_CARD_GATEWAYS) {
        url = `${strings[resource].prefix}${userStore.org}${strings[resource].postfix}/${params.code}`
        // /organizations/api/v1/{org}/financial_structures/credit_card_gateways/{code}
        break
      }
      else if (resource === Resource.POS) {
        url = `${strings[resource]}/${userStore.org}/${params.id}`
      }
      else if (resource === Resource.SOURCE_ATTACHMENTS) {
        url = fileSystemApi.endpoints.deleteMapping(params.id)
      }
      else {
        url = `${strings[resource]}${params.org ? `/${params.org}` : ''}/${params.id}`
      }
      break
    case DELETE_MANY:
      const query_many = {
        filter: JSON.stringify({ id: params.ids })
      }
      url = `/${resource}?${stringify(query_many)}`
      method = METHOD.DELETE
      break
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      }
      method = METHOD.GET
      url = `/${resource}?${stringify(query)}`
      break
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id
        })
      }
      url = `/${resource}?${stringify(query)}`
      method = METHOD.GET
      break
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  try {
    let response
    if (useFileSystemApi) {
      response = await fileSystemApi.request(method, url, body)
    }
    else {
      response = await requests.anonymous(userStore, method, url, body, resource === Resource.UPLOAD)
    }

    /**
     * deal with id's
     */
    if (response.success) {
      switch (resource) {
        case Resource.CORPORATIONS:
          if (response.data) {
            if (type === GET_ONE) {
              response.data.id = response.data.data.code
            }
            else if (type === GET_LIST) {
              response.data.forEach(el => { el.id = el.data.code })
            }
          }
          break;
        case Resource.USERS:
          if (response.data) {
            if (type === GET_ONE) {
              const availableRolesStatusTrue = response.data.available_roles?.filter(e => e.status)
              const availableRolesIds = availableRolesStatusTrue?.map(e => e.code)
              const existingAvailableRoles = response.data.existing_roles?.filter(e => availableRolesIds?.includes(e))
              const availableRolesChoices = availableRolesStatusTrue?.map(e => { return { id: e.code, name: e.name } })
              response.data.available_roles_choices = availableRolesChoices
              response.data.existing_available_roles = existingAvailableRoles
              response.data.updated_roles = existingAvailableRoles
            }
          }
          break;
        default:
          break;
      }
    }

    const responseJson = await convertHTTPResponseToRest({ json: response }, type, resource, params)
    return responseJson
  } catch (error) {
    console.log('responseJson error url = ', url)
    alert('response failed')
    if (type === GET_LIST) {
      return { data: [], total: 0 }
    }
    return {}
  }
}

/**
 * 
 * @param {*} response 
 * @param {*} type 
 * @param {Resource} resource 
 * @param {*} params 
 * @returns 
 */
const convertHTTPResponseToRest = async (response, type, resource, params) => {
  const { json } = response
  switch (type) {
    case GET_LIST:
      if (resource === Resource.SYSTEMS) {
        return {
          data: json.data.map((resource) => ({ status: resource.active === 1 ? true : false, id: resource.code, ...resource })).sort((a, b) => Number(a.code) - Number(b.code)),
          total: json.total
        }
      }
      if (resource === Resource.POS) {
        const res = await requests.getPosSystems(userStore)
        userStore.setPosSystems(res.data)
        return {
          data: _.sortBy(json.data.map((resource) => ({ id: resource.code, system_name: userStore.posSystems.find(s => s.code === resource.system)?.name, ...resource })), item => item.name.he),
          total: json.data.length
        }
      }
      if (resource === Resource.SOURCE_ATTACHMENTS) {
        return {
          data: json.data || [],
          total: json.data.length
        }
      }
      if (resource === Resource.CORPORATIONS) {
        return {
          data: json.data.map((resource) => ({ id: resource._id, ...resource })),
          total: json.data.length
        }
      }

      if (resource === Resource.JOB_MANAGER) {
        return {
          data: json.data,
          total: json.total
        }
      }

      else return json


    case UPDATE:
      if (resource === Resource.CORPORATIONS) {
        return {
          success: json.success,
          data: { ...json, id: json.data._id }
        }
      }
      else if (resource === Resource.POS) {
        return {
          success: json.success,
          data: { ...json, id: params.id }
        }

      }
      else if (resource === Resource.SOURCE_ATTACHMENTS) {
        const payload = JSON.parse(json.config.data)
        return {
          success: json.success,
          data: {
            id: json.data.fileSourceMappingId,
            source: payload.source.name,
            sourceHebrew: payload.source.sourceHebrew,
          }
        }
      }
      else {
        return json
      }
    case DELETE:
      return {
        success: json.success,
        data: {}
      }
    case GET_ONE:
      if (resource === Resource.SYSTEMS) {
        return { id: params.id, data: [] }
      }
      else if (resource === Resource.CORPORATIONS) {
        return {
          data: { ...json.data, id: json.data._id }
        }
      }
      else if (resource === Resource.POS) {
        return {
          data: { id: params.id, system_name: userStore.posSystems.find(s => s.code === json.data.system)?.name, ...json.data }
        }
      }
      else if (resource === Resource.SOURCE_ATTACHMENTS) {
        return {
          data: { id: params.id, ...json.data }
        }
      }
      else return json
    case CREATE:
      if (resource === Resource.SYSTEMS) {
        return { data: { ...json.data, id: json.data.code, status: json.data.active === 1 ? true : false } }
      } else if (resource === Resource.FINANCE_GATEWAYS) {
        return { data: { ...json.data, id: `${json.data.server}_${json.data.name}_${json.data.chargee}`.toLowerCase() } }
      } else if (resource === Resource.POS) {
        return { data: { ...json.data, id: json.data.code } }
      } else if (resource === Resource.SOURCE_ATTACHMENTS) {
        const payload = JSON.parse(json.config.data)
        return {
          data: {
            id: json.data.fileSourceMappingId,
            source: payload.source.name,
            sourceHebrew: payload.source.sourceHebrew,
          }
        }
      }
      else if (resource === Resource.UPLOAD) {
        return { data: { ...json } }
      }
      else return json
    default:
      return json
  }
}

function getOrgExportUrl(params) {
  const org = userStore.org
  let urlBase
  if (params.format === strings.exportFormats.uploadFormat) {
    urlBase = `${strings.exportOrgUploadFormatUrl}`
  } else {
    urlBase = `${strings.exportFinanceOrgUrl}`
  }
  const query = {
    filter: JSON.stringify(params.filters)
  }
  return `${urlBase}/${org}?${stringify(query)}`
}

function getMembersExportUrl(params) {
  if (params.format === strings.exportFormats.uploadFormat) {
    return `${strings.exportUploadFormatUrl}`
  } else {
    return `${strings.exportFinanceUrl}`
  }
}

function getMembersExportPayload(params) {
  if (params.format === strings.exportFormats.uploadFormat) {
    return { memberIds: params.ids, org: userStore.org }
  } else {
    return { data: params.ids }
  }
}
