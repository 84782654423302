import React, { useEffect, useState } from "react"
import { observer, inject } from "mobx-react"
import { TextInput, useRedirect, SelectInput, useTranslate, required, number } from "react-admin"
import styled from "styled-components"
import { Form } from "react-final-form"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import WarningDialog from "../../utils/WarningDialog"
import arrayMutators from "final-form-arrays"

const StyledTitle = styled.div`
    font-weight:bold;
    padding:15px 0 15px 0;
`
const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  margin-right: 50px;
`
const StyledOkButton = styled(Button)`
  background-color: green;
`
const StyledDeleteButton = styled(StyledButton)`
  background-color: red;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const useStyles = makeStyles({
  width: {
    width: 400
  }
})


const FinancialCompanySection = props => {
  const { handleSubmit, deleteWarning, financialCompany, handleCancel, systems } = props
  const translate = useTranslate()
  const validateRequired = [required()];
  const validateNumber = [number()];
  const isEdit = Boolean(financialCompany?.data?.code)
  return (
    <BodyContainer>
      <SelectInput
        source='data.financial_system'
        validate={validateRequired}
        label='customRoot.definitions.edit.financialCompanies.financial_system'
        choices={systems.map(el => ({ id: el.code, name: el.name }))}
      />
      {/* <TextInput
        validate={validateRequired}
        source='data.financial_system'
        label='customRoot.definitions.edit.financialCompanies.financial_system'
        defaultValue={financialCompany?.data.financial_system}
      /> */}
      <TextInput
        source='data.company_id'
        label='customRoot.definitions.edit.financialCompanies.company_id'
      />
      <StyledTitle>{translate('customRoot.definitions.edit.financialCompanies.payments')}</StyledTitle>
      <TextInput
        dir='ltr'
        validate={validateRequired}
        source='data.credentials.payments.username'
        label='customRoot.definitions.edit.financialCompanies.username'
      />
      <TextInput
        validate={validateRequired}
        source='data.credentials.payments.password'
        label='customRoot.definitions.edit.financialCompanies.password'
      />
      <TextInput
        dir='ltr'
        validate={validateRequired}
        source='data.credentials.payments.endpoint'
        label='customRoot.definitions.edit.financialCompanies.endpoint'
      />
      <StyledTitle>{translate('customRoot.definitions.edit.financialCompanies.receipts')}</StyledTitle>
      <TextInput
        dir='ltr'
        validate={validateRequired}
        source='data.credentials.receipts.username'
        label='customRoot.definitions.edit.financialCompanies.username'
      />
      <TextInput
        validate={validateRequired}
        source='data.credentials.receipts.password'
        label='customRoot.definitions.edit.financialCompanies.password'
      />
      <TextInput dir='ltr' source='data.credentials.receipts.endpoint'
        validate={validateRequired}
        label='customRoot.definitions.edit.financialCompanies.endpoint'
      />
      <ButtonsContainer>
        <StyledOkButton variant='contained' color='primary' type='submit' onClick={handleSubmit}>
          {translate("customRoot.definitions.edit.submit_button")}
        </StyledOkButton>
        {isEdit && (
          <StyledDeleteButton variant='contained' color='secondary' onClick={deleteWarning}>
            {translate("customRoot.definitions.edit.delete_button")}
          </StyledDeleteButton>
        )}
        <StyledButton variant='contained' color='primary' type='cancel' onClick={handleCancel}>
          {translate("customRoot.definitions.edit.cancel_button")}
        </StyledButton>
      </ButtonsContainer>
    </BodyContainer>
  )
}
const WarningActions = { delete: "delete", skip: "skip" }

const FinancialCompanyCreateAndEdit = ({ corporationStore, ...props }) => {
  const { id, version } = props
  const [financialCompany, setFinancialCompany] = useState(null)
  const classes = useStyles()
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningContentTexts, setWarningContentTexts] = useState({ content: "", title: "" });
  const [warningAction, setWarningAction] = useState(WarningActions.skip);
  const redirect = useRedirect();
  const translate = useTranslate()
  const corporationName = corporationStore.currentCorporation?.data?.name?.he || ''


  useEffect(() => {
    async function getCurrentFinancialCompany() {
      setFinancialCompany(await corporationStore.getFinancialCompanyData(id))
    }
    if (corporationStore.currentCorporation) {
      if (id) {
        getCurrentFinancialCompany()
      }
    } else {
      redirect('/corporations');
    }
  }, [])

  const onSave = async (res) => {
    res.relations = []
    if (!id) {
      return await corporationStore.createFinancialCompany(res)
    }
    res.id = financialCompany.data.code
    // res.data.code = financialCompany.data.code
    // res._rev = financialCompany._rev
    // res.id = financialCompany._id
    return await corporationStore.updateFinancialCompany(res, id)
  }

  const onSuccess = async () => {
    setWarningOpen(false)
    redirect(`/corporations/${corporationStore.currentCorporation.data.code}`)
  }
  const handleCancel = () => {
    redirect(`/corporations/${corporationStore.currentCorporation.data.code}`)
  }
  const onFailed = async (error) => {
    setWarningContentTexts({ content: error, title: "customRoot.definitions.failureMessage" })
    setWarningOpen(true)
  }
  const onDelete = async () => {
    return await corporationStore.deleteFinancialCompany(financialCompany.data.code)
  }
  const handleCloseWarning = () => {
    setWarningOpen(false)
  }
  const deleteWarning = () => {
    setWarningContentTexts({ content: "customRoot.definitions.deleteWarningContent", title: "customRoot.definitions.deleteWarningTitle" })
    setWarningAction(WarningActions.delete)
    setWarningOpen(true)
  }
  const handleWarningConfirm = async () => {
    if (warningAction === WarningActions.delete) {
      const res = await onDelete()
      if (!res.success) {
        onFailed(res.error)
      }
      else {
        onSuccess()
      }
      setWarningAction(WarningActions.skip)
    } else {
      setWarningOpen(false)
    }
  }

  const checkFinancialCompanyDataIsNotEmpty = (financialCompany) => {
    return financialCompany && financialCompany.data && financialCompany.data.financial_system && financialCompany.data.company_id && financialCompany.data.credentials
  }

  const handleClick = async (results) => {
    if (!checkFinancialCompanyDataIsNotEmpty(results)) {
      setWarningContentTexts({ content: "customRoot.definitions.missingData", title: "customRoot.definitions.failureMessage" })
      setWarningOpen(true)
      return
    }
    const res = await onSave(results)
    if (!res.success) {
      onFailed(res.error)
    }
    else {
      onSuccess()
    }
  }
  return (
    <Form
      onSubmit={handleClick}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      initialValues={{
        data: {
          financial_system: financialCompany?.data.financial_system,
          company_id: financialCompany?.data.company_id,
          credentials: {
            payments: {
              username: financialCompany?.data.credentials.payments.username,
              password: financialCompany?.data.credentials.payments.password,
              endpoint: financialCompany?.data.credentials.payments.endpoint
            },
            receipts: {
              username: financialCompany?.data.credentials.receipts.username,
              password: financialCompany?.data.credentials.receipts.password,
              endpoint: financialCompany?.data.credentials.receipts.endpoint
            }
          }
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <FormContainer>
          <StyledTitle>{translate('customRoot.definitions.edit.named_corporation', { field: corporationName })}: {translate(id ? 'customRoot.definitions.edit.financialCompanies.title_update' : 'customRoot.definitions.edit.financialCompanies.title_create')}</StyledTitle>
          <StyledForm onSubmit={handleSubmit} className={classes.width}>
            <FinancialCompanySection systems={corporationStore.currentSystems} financialCompany={financialCompany} handleSubmit={handleSubmit} deleteWarning={deleteWarning} handleCancel={handleCancel} />
          </StyledForm>
          <WarningDialog open={warningOpen} handleClose={handleCloseWarning} handleConfirm={handleWarningConfirm} content={warningContentTexts.content} title={warningContentTexts.title} />
        </FormContainer>
      )}></Form>
  )
}
export default inject("corporationStore")(observer(FinancialCompanyCreateAndEdit))

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
}