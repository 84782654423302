import React from 'react'
import {
  SimpleForm,
  TextInput,
  required,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  email,
  number,
  minLength
} from 'react-admin'
import { observer, inject } from 'mobx-react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { isValidPhone } from '@kimaia/validation'
import registry from 'tools/regex-registry'

const StyledTitle = styled(Typography)`
  font-weight: 600;
`
const StyledTextInput = styled(TextInput)`
  width: 50%
`

const StyledFtpDetailsContainer = styled.div`
  width: 50%
`

const StyledTextInputFtpDetails = styled(TextInput)`
  width: 100%
`

const validateIsraeliMobilePhone = value => {
  if (!isValidPhone(value)) {
    return 'ra.validation.israeliPhone'
  }
}

const validateEmail = [email()]
const validateMobile = [required(), number(), minLength(9), validateIsraeliMobilePhone]

const SourceAttachmentsForm = ({ userStore, ...props }) => {
  const translate = useTranslate()
  const checkRegex = (value) => {
    try {
      registry.check(value, props.sourceId, userStore.fsOrgId)
    } catch (err) {
      console.error(`Invalid regex = error = ${err}`)
      return 'ra.validation.invalidRegex'
    }
  }
  const validateRegex = [required(), checkRegex]
  return (
    <SimpleForm {...props}>
      <StyledTitle>
        {translate('customRoot.sourceAttachments.source_settings')}
      </StyledTitle>
      <StyledTextInput
        source='sourceHebrew'
        label='customRoot.sourceAttachments.name_he'
        validate={[required()]} />
      <StyledTextInput
        dir='ltr'
        source='source'
        label='customRoot.sourceAttachments.name_en'
        validate={[required()]} />
      <StyledTextInput
        dir='ltr'
        source='extRegEx'
        label='customRoot.sourceAttachments.regex'
        validate={validateRegex} />
      <ArrayInput source='email' label='customRoot.sourceAttachments.emails'>
        <SimpleFormIterator>
          <StyledTextInput
            dir='ltr'
            label='customRoot.sourceAttachments.email'
            validate={validateEmail} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source='mobile' label='customRoot.sourceAttachments.phones'>
        <SimpleFormIterator>
          <StyledTextInput
            dir='ltr'
            label='customRoot.sourceAttachments.phone'
            validate={validateMobile} />
        </SimpleFormIterator>
      </ArrayInput>
      {props.sourceId &&
        <StyledFtpDetailsContainer>
          <StyledTitle>{translate('customRoot.sourceAttachments.ftp_details')}</StyledTitle>
          <StyledTextInputFtpDetails
            dir='ltr'
            source='azureFtpDetails.ftpUserName'
            label='customRoot.sourceAttachments.user_name'
            disabled />
          <StyledTextInputFtpDetails
            dir='ltr'
            source='azureFtpDetails.ftpPassword'
            label='customRoot.sourceAttachments.password'
            disabled />
          <StyledTextInputFtpDetails
            dir='ltr'
            source='azureFtpDetails.host'
            label='customRoot.sourceAttachments.host'
            disabled />
        </StyledFtpDetailsContainer>
      }
    </SimpleForm>
  )
}
export default inject('userStore')(observer(SourceAttachmentsForm))
