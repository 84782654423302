import React, { useState } from "react"
import { observer, inject } from "mobx-react"
import { useTranslate, useNotify } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import strings from "../../tools/strings"
import ReportsFactory from "./reportsFactory"
import { downloadCSV } from 'react-admin';
import { ENDPOINTS } from 'fetch/constants'
import AssessmentIcon from '@mui/icons-material/Assessment';

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 50px;
`

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  grid: {
    justifyContent: "center",
    display: "flex"
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}))

const ReportItem = ({ type, handleReport, icon }) => {
  const translate = useTranslate()

  return (
    <List>
      <Button onClick={handleReport}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              {icon}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={translate(`customRoot.reports.${type}`)} secondary={translate(`customRoot.reports.${type}_explanation`)} />
        </ListItem>
      </Button>
    </List>

  )

}

const ReportsList = ({ userStore, ...props }) => {
  const [open, setOpen] = useState(false)
  const [showAllOrgsSetting, setShowAllOrgsSetting] = useState(false)
  const [reportType, setReportType] = useState(null)
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()

  const downloadPaymentReport = (data) => {
    if (data?.length) {
      const fileName = `${translate(`customRoot.reports.payments_by_month`)}.csv`
      const csvData = arrayToCSV(data)
      downloadCSV(csvData, fileName)
      notify("customRoot.reports.payments_by_month_success", "success")
    } else {
      notify("customRoot.reports.no_data", "success")
    }
  }

  const eventDownloadPaymentReport = () => {
    const api = process.env.REACT_APP_API || '__RUNTIME_API__'
    const evtSource = new EventSource(`${api}${ENDPOINTS.org.events}`, { withCredentials: true })
    evtSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.success) {
        downloadPaymentReport(data.data)
      } else {
        notify("customRoot.general.error", "warning")
      }
      evtSource.close()
    }
    evtSource.onerror = (e) => {
      evtSource.close()
      console.log("An error occurred while attempting to connect.", e);
      eventDownloadPaymentReport()
    }
  }

  const handleReport = type => {
    setReportType(type)
    if (type === strings.reports.PAYMENTS_BY_MONTH) {
      setShowAllOrgsSetting(true)
      eventDownloadPaymentReport()
    }
    setOpen(true)
  }

  function arrayToCSV(data) {
    const csv = data.map(row => Object.values(row));
    csv.unshift(Object.keys(data[0]).map(key => translate(`customRoot.reports.columnTitle.${key}`)));
    return `"${csv.join('"\n"').replace(/,/g, '","')}"`;
  }

  const generateCSV = async (type, getDataFunc) => {
    const fileName = `${translate(`customRoot.reports.${type}`)}.csv`
    const data = await getDataFunc()

    if (data === false) {
      notify("customRoot.general.error", "warning")
      return
    }
    if (data?.length) {
      const csvData = arrayToCSV(data)
      downloadCSV(csvData, fileName)
      notify("customRoot.reports.success", "success")
    } else {
      notify("customRoot.reports.no_data", "success")
    }
  }

  const generateReport = async (reportType) => {
    switch (reportType) {
      case strings.reports.CHARGES_AMOUNTS:
        await generateCSV(reportType, userStore.getChargesAmounts)
        setOpen(false)
        break

      case strings.reports.PAYMENTS_BY_MONTH:
        await userStore.getPaymentsMonth()
        notify("customRoot.reports.payments_by_month_info_message", "info")
        setOpen(false)
        break

      default:
        setOpen(false)
        return
    }
  }

  const handleDate = (reportType) => {
    switch (reportType) {
      case strings.reports.CHARGES_AMOUNTS:
        return userStore.setChargesAmountsReportDate
      case strings.reports.PAYMENTS_BY_MONTH:
        return userStore.setGetPaymentsByMothDate
      default:
        return () => { }
    }
  }
  return (
    <FormContainer>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} md={6}>
          <div>
            <ReportItem icon={<AssessmentIcon />} type={strings.reports.CHARGES_AMOUNTS} handleReport={() => handleReport(strings.reports.CHARGES_AMOUNTS)} />
            <ReportItem icon={<AssessmentIcon />} type={strings.reports.PAYMENTS_BY_MONTH} handleReport={() => handleReport(strings.reports.PAYMENTS_BY_MONTH)} />
          </div>
        </Grid>
      </Grid>
      <ReportsFactory showAllOrgsSetting={showAllOrgsSetting} open={open} setOpen={setOpen} type={reportType} generateReport={() => generateReport(reportType)} handleDateChangeFunc={handleDate(reportType)} />
    </FormContainer>
  )
}

export default inject("userStore")(observer(ReportsList))
