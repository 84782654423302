import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import styled from "styled-components";
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  List,
  Datagrid,
  TextField,
  useTranslate,
  useNotify,
} from 'react-admin';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { TASK } from 'clearing-types';

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`

const ListActions = ({ userStore, tasksStore }) => {
  const translate = useTranslate();
  const notify = useNotify();

  const getLoadFilesToMessageStorage = () => {
    const tasks = tasksStore.tasksList;
    return tasks.find(v => v.name === TASK.LOAD_FILES_TO_MESSAGE_STORAGE);
  }

  const sendRequest = async () => {
    const body = { period: [], is_manual_run: true };
    const runResult = await userStore.runTask(getLoadFilesToMessageStorage(), body);
    if (runResult.success) notify("customRoot.requestResult.success", "success");
    else notify("customRoot.requestResult.error", "warning");
  }

  return (
  <TopToolbar>
    <CreateButton/>
    <ExportButton/>
    <Button
      onClick={async () => { await sendRequest(); }}
      size='small'
      label={translate("ra.action.loadToStorage")}
    >
      <CloudUploadIcon fontSize="small" />
    </Button>
    <div style={{ width: "1rem", height: "1rem" }} />
  </TopToolbar>
)};

const SourceAttachmentsList = ({ userStore, tasksStore, ...props }) => {
  const org = userStore.org;

  useEffect(() => {
    const setFsOrg = async () => {
      if(org) {
        await userStore.setFsOrg(org);
      }
    }
    setFsOrg();
  }, [org]);

  if (!userStore.org) {
    return null
  }

  return (
    <FormContainer>
      <List {...props} actions={<ListActions userStore={userStore} tasksStore={tasksStore}/>} bulkActionButtons={false}>
        <Datagrid rowClick='edit'>
        <TextField source='sourceHebrew' label='customRoot.sourceAttachments.name_he' sortable={false} />
          <TextField source='source' label='customRoot.sourceAttachments.name_en' sortable={false} />
        </Datagrid>
      </List>
    </FormContainer>
  )
}

export default inject('userStore', 'tasksStore')(observer(SourceAttachmentsList))
