import React from "react"
import { List, Datagrid, TextField } from "react-admin"
import styled from "styled-components"
import { observer, inject } from "mobx-react"
import { useHistory } from "react-router"
import { Resource } from "core"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`
const CorporationsList = ({ corporationStore, ...props }) => {
  const history = useHistory()
  const handleFieldPress = (_id, _basePath, record) => {
    history.push(Resource.CORPORATIONS + `/${record.data.code}`)
  }
  return (
    <FormContainer>
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick={handleFieldPress}>
          <TextField source='data.business_number' label='customRoot.definitions.edit.corporation_id' sortable={false} />
          <TextField source='data.name.he' label='customRoot.definitions.edit.corporation_name.he' sortable={false} />
          <TextField source='data.name.en' label='customRoot.definitions.edit.corporation_name.en' sortable={false} />
          <TextField source='data.members_type' label='customRoot.definitions.edit.corporation_members_type' sortable={false} />
        </Datagrid>
      </List>
    </FormContainer>
  )
}
export default inject("corporationStore")(observer(CorporationsList))
