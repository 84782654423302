import React, { useState } from "react"
import { observer, inject } from "mobx-react"
import { useTranslate, useNotify } from "react-admin"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import TaskFactory from "./taskFactory"
import strings from '../../../tools/strings'

const TaskItem = ({ taskType, icon, userStore }) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const notify = useNotify()

  const executeTask = async () => {
    switch (taskType) {
      case strings.tasks.MASAV_TRANSMITTED:
        const markTransmitted = await userStore.markMasavDocsPeriodAsTransmitted()
        if (markTransmitted) notify("customRoot.general.success", "success")
        else notify("customRoot.general.error", "warning")
        setOpen(false)
        break
      case strings.tasks.MASAV_INCOME_TRANSMITTED:
        if (await userStore.markMasavIncomesPeriodAsTransmitted())
          notify("customRoot.general.success", "success")
        else
          notify("customRoot.general.error", "warning")
        setOpen(false)
        break
      default:
        return
    }
  }

  return (
    <List>
      <Button onClick={() => setOpen(true)}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              {icon}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={translate(`customRoot.tasks.${taskType}`)} secondary={translate(`customRoot.tasks.${taskType}_explanation`)} />
        </ListItem>
      </Button>
      <TaskFactory open={open} setOpen={setOpen} type={taskType} executeTask={executeTask} />
    </List>
  )
}

export default inject("userStore")(observer(TaskItem))