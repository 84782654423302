import React  from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  BooleanInput,
  FormDataConsumer
} from 'react-admin'
import {observer, inject} from 'mobx-react'
import { FormHelperText, Button, withStyles, Typography } from "@material-ui/core"
import { useTranslate, useNotify, useSafeSetState } from 'ra-core'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from "@material-ui/core/styles"
import strings from "../../tools/strings";


const ButtonField = ({record, onCreateMockBtnClick}) => {
  const translate = useTranslate()

  return (
    <Button onClick={(ev) => onCreateMockBtnClick(ev, record)}>
      {translate('customRoot.pos.create_mock')}
    </Button>
  )
}

const TextTypography = withStyles({
  root: {
    color: props => props.color,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }
})(Typography);

const StyledButton = withStyles({
  root: {
    width: '256px',
  }
})(Button);

const useStyles = makeStyles(
  theme => ({
    form: { padding: '0 1em 1em 1em' },
    input: { marginTop: '1em' },
    button: { width: '100%' },
    icon: { marginRight: theme.spacing(1) }
  }),
  { name: 'PosForm' }
)

const CustomTextHelper = ({ showHelperText, error }) => {
  const translate = useTranslate();

  return (
    <>
      { showHelperText && <FormHelperText error={error} hidden={!showHelperText} >
        <TextTypography color={!error ? "green" : 'red'}>
          <span>{translate('customRoot.pos.exists_path')}</span>
          {!error ? <CheckIcon color="success" /> : <CloseIcon sx={{ color: 'red' }}/>}
        </TextTypography>
      </FormHelperText> }
    </>
  )
}

const PosForm = ({userStore, posStore, ...props}) => {
  const translate = useTranslate();
  const [loading, setLoading] = useSafeSetState(false);
  const [connectionHelperTextShow, setConnectionHelperTextShow] = useSafeSetState(false);
  const [existsConnection, setExistsConnection] = useSafeSetState(false);
  const [existsPath, setExistsPath] = useSafeSetState(false);
  const [existsPathHelperTextShow, setExistsPathHelperTextShow] = useSafeSetState(false);
  const notify = useNotify();
  const classes = useStyles(props)

  const onCheckConnectionBtnClick = async (ev, record) => {
    try {
      setLoading(true);
      setConnectionHelperTextShow(false);
      setExistsPathHelperTextShow(false);
      const checkConnectionData = await posStore.checkConnection(record);
      const { existsConnection, existsPath } = checkConnectionData;
      setConnectionHelperTextShow(true);
      setExistsPathHelperTextShow(true);
      setExistsConnection(existsConnection)
      setExistsPath(existsPath)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("customRoot.general.error", "warning")
    }
  }

  const availablePosCheckStorageConnection = Object.values(strings.availablePosCheckStorageConnection);

  return (
    <SimpleForm {...props}>
      {props.showCreateMockBtn && <ButtonField onCreateMockBtnClick={props.onCreateMockBtnClick}/>}
      <TextInput  multiline={true} source='name.he' label='customRoot.pos.name_he' validate={[required()]}/>
      <TextInput  multiline={true} dir='ltr' source='name.en' label='customRoot.pos.name_en' validate={[required()]}/>
      <SelectInput label="customRoot.pos.system" source="system" choices={userStore.posSystems}
                   validate={[required()]}/>
      <TextInput
        dir='ltr'
        multiline={true}
        source='url'
        helperText={`Example: one/${userStore.org}/ftp/kolbolit/DINpov_{monthFileName}.csv`}
        label='URL'
        validate={[required()]}
      />
      <CustomTextHelper showHelperText={existsPathHelperTextShow} error={!existsPath} />
      <TextInput  multiline={true} source='username' label='customRoot.pos.username' defaultValue="" parse={value => value}/>
      <TextInput  multiline={true} source='password' label='customRoot.pos.password' validate={[required()]}/>
      <FormDataConsumer>
        {({formData}) => availablePosCheckStorageConnection.includes(formData.system) && (
          <StyledButton loading={loading} type='button' color='primary' variant='contained'
                         onClick={(ev) => onCheckConnectionBtnClick(ev, formData)}>
            {loading && <CircularProgress color='#000000' className={classes.icon} size={18} thickness={2} />}
            {!loading && translate('customRoot.pos.check_connection')}
          </StyledButton>
        )}
      </FormDataConsumer>
      <CustomTextHelper showHelperText={connectionHelperTextShow} error={!existsConnection} />
      <BooleanInput label="customRoot.pos.fetch_by_third_party" source="fetch_by_third_party"/>
      <TextInput multiline={true} source='external_org_id' label='customRoot.pos.external_org_id'/>
      <TextInput multiline={true} source='external_pos_id' label='customRoot.pos.external_pos_id'/>
    </SimpleForm>
  )
}
export default inject("userStore", "posStore")(observer(PosForm))
